<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el label="Документы" @click="$router.push({ name: 'documents_list', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="row">
		<div class="col">
			<div class="q-ma-md text-h5 row">
				<div class="col">
					{{ page_title }}
				</div>
				<div class="col" style="text-align: right;">
					<q-btn-dropdown color="primary" label="Управление" icon="settings">
						<q-list>
							<q-item clickable v-close-popup @click="onPrintButtonClick">
								<q-item-section>
									<q-item-label>Печать</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup @click="onEditDocument">
								<q-item-section>
									<q-item-label>Проводки и редактирование</q-item-label>
								</q-item-section>
							</q-item>

							<q-item v-if="is_active" clickable v-close-popup @click="onDeleteDocument">
								<q-item-section>
									<q-item-label>Удалить</q-item-label>
								</q-item-section>
							</q-item>
							<q-item v-else clickable v-close-popup @click="onRestoreDocument">
								<q-item-section>
									<q-item-label>Восстановить</q-item-label>
								</q-item-section>
							</q-item>
						</q-list>
					</q-btn-dropdown>
				</div>

			</div>
		</div>
	</div>

	<div class="doc landscape upd" id="print_content">
		<div class="container">
			<div>
				<div>{{ body.maker_name }}</div>
				<div>{{ body.maker_address }}</div>
				<div>{{ body.maker_phone}}</div>
			</div>
			<div>
				<table style="width: 180mm !important;">

					<tr>
						<td class="no-left-border aligned-cells" style="height: 15mm !important;" colspan="20"
							rowspan="2">
							<div>{{ body.maker_bank }}</div>
							<div>Банк получателя</div>
						</td>
						<td colspan="3">БИК</td>

						<td class="no-right-border" style="text-align: left;" colspan="13" rowspan="4">
							<div style="height: 7mm; padding-top: 1mm;">
								{{ body.maker_bank_bik }}
							</div>
							<div style="height: 7mm; padding-top: 1mm;">
								{{ body.maker_bank_account }}
							</div>
							<div style="height: 5mm; padding-top: 1mm;">
								{{ body.maker_bank_account_ext }}
							</div>
						</td>
					</tr>
					<tr>
						<td colspan="3">Сч. №</td>
					</tr>
					<tr>
						<td class="no-left-border" style="height: 7.5mm !important; text-align: left;" colspan="10">ИНН
							{{ body.maker_inn }}</td>
						<td colspan="10" style="text-align: left;">КПП {{ body.maker_kpp }}</td>
						<td colspan="3" rowspan="2">Сч. №</td>
					</tr>
					<tr>
						<td class="no-left-border aligned-cells" style="height: 15mm !important;" colspan="20"
							rowspan="1">
							<div>{{ body.maker_name }}</div>
							<div>Получатель</div>
						</td>
					</tr>

				</table>
			</div>
			<div style="width: 100%; text-align: center; font-weight: bold; font-size: 16pt; margin-top: 5mm;">
				Счет на оплату №{{ body.payment_id || 0 }} от {{ formatDate(body.document_date || 0) }}
			</div>
			<div>
				<div>Поставщик: {{ body.maker_name}}</div>
				<div>Покупатель: {{ body.organisation || 'неизвестно'}}</div>
			</div>
			<div>
				<table style="width: 180mm !important;">
					<tr>
						<td style="width: 5mm; border-bottom: 1px solid black; text-align: right;">№</td>
						<td style="width: 85mm; border-bottom: 1px solid black; text-align: right;">Товар или услуга
						</td>
						<td style="width: 30mm; border-bottom: 1px solid black; text-align: right;">Кол-во</td>
						<td style="width: 30mm; border-bottom: 1px solid black; text-align: right;">Цена</td>
						<td style="width: 30mm; border-bottom: 1px solid black; text-align: right;">Сумма</td>
					</tr>
					<tr>
						<td style="width: 5mm; border-bottom: 1px solid black; text-align: right;">1</td>
						<td style="width: 85mm; border-bottom: 1px solid black; text-align: right;">{{
							body.payment_position_name}}
						</td>
						<td style="width: 30mm; border-bottom: 1px solid black; text-align: right;">1</td>
						<td style="width: 30mm; border-bottom: 1px solid black; text-align: right;">{{
							body.payment_amount }}</td>
						<td style="width: 30mm; border-bottom: 1px solid black; text-align: right;">{{
							body.payment_amount }}</td>
					</tr>
					<tr>
						<td colspan="3"
							style="border-bottom: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
						</td>
						<td
							style="border-bottom: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
							Итого</td>
						<td
							style="border-bottom: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
							{{
							body.payment_amount }}</td>
					</tr>
					<tr>
						<td colspan="3"
							style="border: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
						</td>
						<td
							style="border: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
							Без НДС</td>
						<td
							style="border: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
							{{ body.payment_without_nds }}</td>
					</tr>
					<tr>
						<td colspan="3"
							style="border: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
						</td>
						<td
							style="font-weight: bold; border: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
							Всего</td>
						<td
							style="border: 0px solid black; border-right: 0px solid black; border-left: 0px solid black; text-align: right;">
							{{ body.payment_total }}</td>
					</tr>
				</table>
			</div>
			<div style="height: 10mm; margin-top: 5mm; font-size: 7pt;">
				<div style="display: inline-block; width: 40mm; height: 10mm;">
					{{ body.maker_receiver }}
				</div>
				<div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
					<dir style="border-bottom: 0.4mm solid black; height: 7mm;">

					</dir>
					<div style=" height: 3mm; font-size: 5pt;">
						Подпись
					</div>
				</div>
				<div style="display: inline-block; width: 50mm; margin-left: 7mm; text-align: center;">
					<dir style="border-bottom: 0.4mm solid black; height: 7mm;">

					</dir>
					<div style=" height: 3mm; font-size: 5pt;">
						(Ф.И.О.)
					</div>
				</div>


			</div>
		</div>22
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

var style = "<style>" +
	"@page {" +
	"size: A4;" +
	"margin: 0;" +
	"}" +
	".container {" +
	"margin: 0;" +
	"padding: 0;" +
	"font-family: 'Arial', sans-serif;" +
	"font-size: 12px;" +
	"background: white;" +
	"color: black;" +
	"width: 210mm;" +
	"height: 297mm;" +
	"padding: 10mm 10mm 10mm 20mm;" +
	"box-sizing: border-box;" +
	"display: block;" +
	"margin: auto;" +
	"position: relative;" +
	"}" +
	"table {" +
	"width: 180mm;" +
	"border-collapse: collapse;" +
	"table-layout: fixed;" +
	"margin-top: 10px;" +
	"font-size: 8pt;" +
	"}" +
	"th, td {" +
	"border: 0.6mm solid black;" +
	"height: 5mm;" +
	"text-align: center;" +
	"vertical-align: top;" +
	"}" +
	"tr {" +
	"height: 5mm;" +
	"}" +
	".aligned-cells {" +
	"text-align: left;" +
	"vertical-align: bottom;" +
	"}" +
	".no-right-border {" +
	"border-right: transparent !important;" +
	"}" +
	".no-left-border {" +
	"border-left: transparent !important;" +
	"}" +
	"</style>";

export default {
	name: "document_info_payment",
	components: {
	},
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.$q.notify({
				progress: true,
				message: "Документ удален",
				icon: 'checkmark',
				color: 'white',
				textColor: 'primary'
			});
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});

		this.emitter.on('onCreateTxtFromPayement', (response) => {

			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = "erp_to_kl.txt"
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.$q.notify({
				progress: true,
				message: "Документ восстановлен",
				icon: 'checkmark',
				color: 'white',
				textColor: 'primary'
			});
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.name = response.data.name;
			this.page_title = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.is_active = response.data.is_active;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1'
				);
			})
		}
		return {
			name: ref(''),
			body: ref({}),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null),
			is_active: ref(true),
			page_title: ref(null)
		}
	},
	methods: {
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				item_id,
				value,
				direction
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'document_edit_payment', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$route.params.id
			)
		},
		onDownload() {
			this.connection.createTxtFromPayement(
				'1',
				[this.$route.params.id]
			)
		}
	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	width: 210mm;
	height: 297mm;
	padding: 10mm 10mm 10mm 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}

table {
	width: 180mm;
	border-collapse: collapse;
	table-layout: fixed;
	margin-top: 10px;
	font-size: 8pt;
}

th,
td {
	border: 0.6mm solid black;
	height: 5mm;
	text-align: center;
	vertical-align: top;
}

tr {
	height: 5mm;
}

.aligned-cells {
	text-align: left;
	vertical-align: bottom;
}

.no-right-border {
	border-right: transparent !important;
}

.no-left-border {
	border-left: transparent !important;
}






</style>