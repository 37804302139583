<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el label="Документы" @click="$router.push({ name: 'documents_list', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="row">
    <div class="col">
      <div class="q-ma-md text-h5 row">
        <div class="col">
          {{ page_title }}
        </div>
        <div class="col" style="text-align: right;">
          <q-btn-dropdown color="primary" label="Управление" icon="settings">
            <q-list>
              <q-item clickable v-close-popup @click="onPrintButtonClick">
                <q-item-section>
                  <q-item-label>Печать</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="onEditDocument">
                <q-item-section>
                  <q-item-label>Проводки и редактирование</q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-if="is_active" clickable v-close-popup @click="onDeleteDocument">
                <q-item-section>
                  <q-item-label>Удалить</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-else clickable v-close-popup @click="onRestoreDocument">
                <q-item-section>
                  <q-item-label>Восстановить</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>

      </div>
    </div>
  </div>
  <div class="doc landscape upd" id="print_content">
    <table class="updskeleton" width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td width="110" style="padding: 0px">
            Универсальный передаточный документ

            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="vertical-align: middle !important;">Статус:</td>
                  <td
                    style="width: 5mm; border: 1px solid #000; text-align: center !important; padding: 1mm; margin-right: 2mm;">
                    {{
                    body.status || '' }}</td>
                </tr>
              </tbody>
            </table>

            1 — счет-фактура и передаточный документ (акт)

            2 — передаточный документ (акт)
          </td>
          <td style="border-left: 1px solid #000">
            <table width="100%" border="0" cellspacing="0" cellpadding="5">
              <tbody>
                <tr>
                  <td width="100">Счет-фактура №</td>
                  <td width="160" style="border-bottom: 1px solid #000; text-align: center !important">{{
                    body.in_org_number || '' }}</td>
                  <td width="20">от</td>
                  <td width="160" style="border-bottom: 1px solid #000; text-align: center !important"> {{
                    formatDate(body.document_date || 0) }}</td>
                  <td width="20">(1)</td>
                  <td rowspan="2" style="text-align: right !important; font-size: 5pt">
                    Приложение № 1 к постановлению Правительства Российской Федерации от 26 декабря 2011 г. № 1137
                    (в редакции постановления Правительства Российской Федерации от 2 апреля 2021 г. № 534)
                  </td>
                </tr>
                <tr>
                  <td>Исправление №</td>
                  <td style="border-bottom: 1px solid #000">{{ body.change_n || '' }}</td>
                  <td>от</td>
                  <td style="border-bottom: 1px solid #000">{{ body.change_date || '' }}</td>
                  <td>(1а)</td>
                </tr>
              </tbody>
            </table>
            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-size: 7pt">
              <tbody>
                <tr>
                  <td width="50%">
                    <table width="100%" border="0" cellspacing="0" cellpadding="5">
                      <tbody>
                        <tr>
                          <td width="170"><b>Продавец:</b></td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller || '' }}</td>
                          <td width="20">(2)</td>
                        </tr>
                        <tr>
                          <td>Адрес:</td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller_adr || '' }}</td>
                          <td>(2а)</td>
                        </tr>
                        <tr>
                          <td>ИНН/КПП продавца:</td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller_inn || '' }}</td>
                          <td>(2б)</td>
                        </tr>
                        <tr>
                          <td>Грузоотправитель и его адрес:</td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller_sender || '' }}</td>
                          <td>(3)</td>
                        </tr>
                        <tr>
                          <td>Грузополучатель и его адрес:</td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller_receiver || '' }}</td>
                          <td>(4)</td>
                        </tr>
                        <tr>
                          <td>К платежно-расчетному документу №</td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller_doc || '' }}</td>
                          <td>(5)</td>
                        </tr>
                        <tr>
                          <td>Документ об отгрузке</td>
                          <td style="border-bottom: 1px solid #000">{{ body.seller_send_doc || '' }}</td>
                          <td>(5a)</td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                  <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="5">
                      <tbody>
                        <tr>
                          <td width="170"><b>Покупатель:</b></td>
                          <td style="border-bottom: 1px solid #000">{{ body.cust || '' }}</td>
                          <td width="20">(6)</td>
                        </tr>
                        <tr>
                          <td>Адрес:</td>
                          <td style="border-bottom: 1px solid #000">{{ body.cust_adr || '' }}</td>
                          <td>(6а)</td>
                        </tr>
                        <tr>
                          <td>ИНН/КПП покупателя:</td>
                          <td style="border-bottom: 1px solid #000">{{ body.cust_inn || '' }}</td>
                          <td>(6б)</td>
                        </tr>
                        <tr>
                          <td>Валюта: наименование, код</td>
                          <td style="border-bottom: 1px solid #000">{{ body.currency || '' }}</td>
                          <td>(7)</td>
                        </tr>
                        <tr>
                          <td>Идентификатор государственного контракта, договора (соглашения) (при наличии):</td>
                          <td style="border-bottom: 1px solid #000">{{ body.goscon || '' }}</td>
                          <td>(8)</td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              </tbody>
            </table>

          </td>
        </tr>
      </tbody>
    </table>

    <table class="updorderlist" width="100%" border="0" cellspacing="0" cellpadding="5"
      style="font-size:7pt; line-height: 1; text-align: center;">
      <tbody>
        <tr>
          <td rowspan="2" style="width: 10mm;">Код товара / работ, услуг</td>
          <td rowspan="2" style="width: 5mm;">№ п/п</td>
          <td style="width: 60mm;" rowspan="2">Наименование товара (описание выполненных работ, оказанных услуг),
            имущественного права</td>
          <td rowspan="2" style="width: 15mm;">Код вида товара</td>
          <td colspan="2" style="width: 30mm;">Единица измерения</td>
          <td rowspan="2" style="width: 15mm;">Количество (объём)</td>
          <td rowspan="2" style="width: 15mm;">Цена (тариф) за единицу измерения</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав без налога —
            всего
          </td>
          <td rowspan="2" style="width: 10mm;">В том числе сумма акциза</td>
          <td rowspan="2" style="width: 15mm;">Налоговая ставка</td>
          <td rowspan="2" style="width: 15mm;">Сумма налога, предъявляемая покупателю</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав с налогом —
            всего
          </td>
          <td colspan="2" style="width: 30mm;">Страна происхождения товара</td>
          <td rowspan="2" style="width: 30mm;">Регистрационный номер декларации</td>
        </tr>
        <tr>
          <td style="width: 10mm;">Код</td>
          <td style="width: 20mm;">Условное обозначение (национальное)</td>
          <td style="width: 10mm;">Цифровой код</td>
          <td style="width: 20mm;">Краткое наименование</td>
        </tr>
        <tr style=" height: 3mm;">
          <td style="text-align: center; font-size: 6pt">A</td>
          <td style="text-align: center; font-size: 6pt">1</td>
          <td style="text-align: center; font-size: 6pt">1а</td>
          <td style="text-align: center; font-size: 6pt">1б</td>
          <td style="text-align: center; font-size: 6pt">2</td>
          <td style="text-align: center; font-size: 6pt">2а</td>
          <td style="text-align: center; font-size: 6pt">3</td>
          <td style="text-align: center; font-size: 6pt">4</td>
          <td style="text-align: center; font-size: 6pt">5</td>
          <td style="text-align: center; font-size: 6pt">6</td>
          <td style="text-align: center; font-size: 6pt">7</td>
          <td style="text-align: center; font-size: 6pt">8</td>
          <td style="text-align: center; font-size: 6pt">9</td>
          <td style="text-align: center; font-size: 6pt">10</td>
          <td style="text-align: center; font-size: 6pt">10а</td>
          <td style="text-align: center; font-size: 6pt">11</td>
        </tr>
        <tr style=" height: 1mm;" v-for="item in body.table_rows || []" v-bind:key="item.id">
          <td>{{ item.code || '' }}</td>
          <td>{{ item.id || '' }}</td>
          <td>{{ item.name || '' }}</td>
          <td>{{ item.kind_code || '' }}</td>
          <td>{{ (item.measure_value || { value: '' }).value || '' }}</td>
          <td>{{ (item.measure_value || { label: '' }).label || '' }}</td>
          <td>{{ item.count || '' }}</td>
          <td>{{ item.price || '' }}</td>
          <td>{{ item.cost_without_nds || '' }}</td>
          <td>{{ item.aciz || '' }}</td>
          <td>{{ (item.nds || { label: '' }).label || '' }}</td>
          <td>{{ item.nalog || '' }}</td>
          <td>{{ item.summtotal || '' }}</td>
          <td>{{ (item.countryname || { value: '' }).value || '' }}</td>
          <td>{{ (item.countryname || { label: '' }).label || '' }}</td>
          <td>{{ item.doc || '' }}</td>
        </tr>
        <tr>
          <td> </td>
          <td colspan="7">Всего к оплате (9)</td>
          <td>ИТОГО</td>
          <td colspan="2" style="text-align: center !important"></td>
          <td>{{ (body.summary || { nalog: 0 }).nalog || '' }}</td>
          <td>{{ (body.summary || { summtotal: 0 }).summtotal || '' }}</td>
          <td colspan="3"></td>
        </tr>
      </tbody>
    </table>
    <table class="updskeleton" width="100%" border="0" cellspacing="0" cellpadding="0"
      style="font-size:7pt; line-height: 1;">
      <tbody>
        <tr>
          <td width="110" style="padding: 0px">
            Документ составлен на x листe
          </td>
          <td style="border-left: 1px solid #000; border-bottom: 1px solid #000; padding-bottom: 5px">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td width="49%">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tbody>
                        <tr>
                          <td width="170" style="padding: 0px">Руководитель организации или иное уполномоченное лицо
                          </td>
                          <td style="border-bottom: 1px solid #000; padding: 0px" width="100"> </td>
                          <td width="10"> </td>
                          <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 0px">
                            {{
                            body.rukvod || '' }}</td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td style="text-align: center; font-size:9px">(подпись)</td>
                          <td> </td>
                          <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="2%"> </td>
                  <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tbody>
                        <tr>
                          <td width="170" style="padding: 0px">Главный бухгалтер или иное уполномоченное лицо</td>
                          <td style="border-bottom: 1px solid #000; padding: 0px" width="100"> </td>
                          <td width="10"> </td>
                          <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 0px">
                            {{
                            body.glavbuh || '' }}</td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td style="text-align: center; font-size:9px">(подпись)</td>
                          <td> </td>
                          <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-size:7pt; line-height: 1;">
              <tbody>
                <tr>
                  <td width="170" style="padding: 0px">Индивидуальный предприниматель или иное уполномоченное лицо
                  </td>
                  <td style="border-bottom: 1px solid #000; padding: 0px" width="100"> </td>
                  <td width="10"> </td>
                  <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 0px">{{
                    body.ip_name || '' }}</td>
                  <td width="2%"> </td>
                  <td style="vertical-align: bottom !important; border-bottom: 1px solid #000; padding: 0px"
                    width="49%">{{ body.ip_req || '' }}</td>
                </tr>
                <tr>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(подпись)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(реквизиты свидетельства о государственной
                    регистрации
                    индивидуального предпринимателя)</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td width="280" style="padding: 0px">Основание передачи (сдачи) / получения (приемки)</td>
          <td style="border-bottom: 1px solid #000; padding: 0px">{{ body.osn_rec_sent || '' }}</td>
          <td width="10" style="padding: 0px">[8]</td>
        </tr>
        <tr>
          <td> </td>
          <td style="text-align: center; font-size:9px">(договор, доверенность и др.)</td>
          <td> </td>
        </tr>
      </tbody>
    </table>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td width="190" style="padding: 0px; padding-top:0px;">Данные о транспортировке и грузе</td>
          <td style="border-bottom: 1px solid #000; padding: 0px; padding-top:0px;">{{ body.transp || '' }}</td>
          <td width="10" style="padding: 0px; padding-top:0px;">[9]</td>
        </tr>
        <tr>
          <td> </td>
          <td style="text-align: center; font-size:9px; padding-bottom: 5px">(транспортная накладная, поручение
            экспедитору, экспедиторская/складская расписка и др., масса нетто/брутто груза, если не приведены ссылки
            на
            транспортные документы, содержащие эти сведения)</td>
          <td> </td>
        </tr>
      </tbody>
    </table>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="page-break-inside:avoid">
      <tbody>
        <tr>
          <td width="50%" style="padding-right: 5px; border-right: 1px solid #000">
            <div style="padding-left:5px;">Товар (груз) передал / услуги, результаты работ, права сдал</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td width="200" style="border-bottom: 1px solid #000; padding: 0px">{{ body.goods_sent_workplace
                    || ''
                    }}</td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000" width="100"> </td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000; padding: 0px">{{ body.goods_sent_fio || '' }}</td>
                  <td width="10" style="padding: 0px">[10]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:9px">(должность)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(подпись)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>
            <table width="100%" border="0" cellspacing="0" cellpadding="5">
              <tbody>
                <tr>
                  <td width="210">Дата отгрузки, передачи (сдачи)</td>
                  <td style="border-bottom: 1px solid #000;">{{ body.date_sent || '' }}</td>
                  <td width="10">[11]</td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:5px;">Иные сведения об отгрузке, передаче</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="border-bottom: 1px solid #000">{{ body.sent_info || '' }}</td>
                  <td width="10" style="padding: 0px">[12]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:9px">(ссылки на неотъемлемые приложения, сопутствующие
                    документы, иные документы и т.п.)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:5px;">Ответственный за правильность оформления факта хозяйственной жизни</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td width="200" style="border-bottom: 1px solid #000; padding: 0px">{{ body.hozvod_workplace || ''
                    }}
                  </td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000" width="100"> </td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000; padding: 0px">{{ body.hozvod_fio || '' }}</td>
                  <td width="10" style="padding: 0px">[13]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:9px">(должность)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(подпись)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:5px;">Наименование экономического субъекта — составителя документа (в т. ч.
              комиссионера / агента)</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="border-bottom: 1px solid #000; padding: 0px">{{ body.econom_name || '' }}</td>
                  <td width="10" style="padding: 0px">[14]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:9px">(может не заполняться при проставлении печати в
                    М.П.,
                    может быть указан ИНН/КПП)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:50px;">М.П.</div>
          </td>
          <td style="padding-left: 5px">
            <div style="padding-left:5px;">Товар (груз) получил / услуги, результаты работ, права принял</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td width="200" style="border-bottom: 1px solid #000;">{{ body.goods_rec_workplace || '' }}</td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000" width="100">{{ body.goods_rec_fio || '' }}</td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000;"> </td>
                  <td width="10" style="padding: 0px">[15]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:9px">(должность)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(подпись)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:9px">(ф.и.о.)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>
            <table width="100%" border="0" cellspacing="0" cellpadding="5">
              <tbody>
                <tr>
                  <td width="210">Дата получения (приемки)</td>
                  <td style="border-bottom: 1px solid #000;">{{ body.date_rec || '' }}</td>
                  <td width="10">[16]</td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:5px;">Иные сведения о получении, приемке</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="border-bottom: 1px solid #000">{{ body.rec_info || '' }}</td>
                  <td width="10" style="padding: 0px">[17]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:9px">(информация о наличии/отсутствии претензии; ссылки
                    на
                    неотъемлемые приложения и другие документы и т. п.)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:5px;">Ответственный за правильность оформления факта хозяйственной жизни</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td width="200" style="border-bottom: 1px solid #000">{{ body.hozvod_rec_workplace || '' }}</td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000" width="100"> </td>
                  <td width="10"> </td>
                  <td style="border-bottom: 1px solid #000">{{ body.hozvod_rec_fio || '' }}</td>
                  <td width="10" style="padding: 0px">[18]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:6pt">(должность)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:6pt">(подпись)</td>
                  <td> </td>
                  <td style="text-align: center; font-size:6pt">(ф.и.о.)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:5px;">Наименование экономического субъекта — составителя документа</div>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="border-bottom: 1px solid #000; padding: 0px">{{ body.econom_rec_name || '' }}</td>
                  <td width="10" style="padding: 0px">[19]</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-size:6pt">(может не заполняться при проставлении печати в
                    М.П.,
                    может быть указан ИНН/КПП)</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>

            <div style="padding-left:50px;">М.П.</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" style="margin-top: 54px;">
    <div class="col">
      <q-select style="max-width: 350px;" :options="quarter_years_options" v-model="nds_period"
        @update:model-value="(x) => updateNdsStateForDocument(x.value)" label="Отразить в НДС за период" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

const style = "<style>" +
  "@page {" +
  "  /*size: 210mm 297mm; */" +
  "  size:  297mm 210mm;" +
  "  /* Chrome sets own margins, we change these printer settings */" +
  "  margin: 10mm 10mm 10mm 10mm;" +
  "}" +
  "" +
  "body {" +
  "  margin:0px;" +
  "  width:100%;" +
  "  height:100%;" +
  "  background:#FFF;" +
  "}" +
  "" +
  "@media screen {" +
  "  body {" +
  "    padding: 30px;" +
  "  }" +
  "}" +
  "" +
  "* {" +
  "  -moz-box-sizing:border-box;" +
  "  -webkit-box-sizing:border-box;" +
  "  box-sizing:border-box;" +
  "}" +
  "" +
  "body,td,th {" +
  "  color:#000;" +
  "}" +
  "" +
  "body .upd, .upd td, .upd th {" +
  "  font-size: 7pt;" +
  "  font-family: 'Times New Roman', Times, serif;" +
  "  vertical-align: top;" +
  "}" +
  "" +
  ".bold {" +
  "  font-weight: bold;" +
  "}" +
  "" +
  "a {" +
  "  color:#000;" +
  "  text-decoration: underline !important;" +
  "}" +
  "a img {" +
  "  border:none;" +
  "}" +
  "" +
  "img.full_width {" +
  "  width:100%;" +
  "  height:auto;" +
  "}" +
  "" +
  "h1,h2,h3 {" +
  "  font-weight: bold;" +
  "}" +
  "" +
  "body,td,th {" +
  "  font-size:8pt;" +
  "}" +
  "h1 {" +
  "  font-size:30px;" +
  "}" +
  "h2 {" +
  "  font-size:24px;" +
  "}" +
  "h3 {" +
  "  font-size:18px;" +
  "}" +
  "" +
  ".gap {" +
  "  height:30px;" +
  "}" +
  "" +
  ".nowrap {" +
  "  white-space:nowrap;" +
  "}" +
  "" +
  ".clear {" +
  "  clear:both;" +
  "  height:0px;" +
  "  line-height:0px;" +
  "  font-size:0px;" +
  "}" +
  ".clearfix {" +
  "  overflow:hidden;" +
  "}" +
  "" +
  ".vertical_centered_content {" +
  "  display: -webkit-flex;" +
  "  -webkit-align-items: center;" +
  "  display: flex;" +
  "  align-items: center;" +
  "}" +
  ".vertical_centered_content > .inner {" +
  "  display:inline;" +
  "  width:100%;" +
  "}" +
  ".horizontal_centered_content {" +
  "  display: -webkit-flex;" +
  "  -webkit-justify-content: center;" +
  "  display: flex;" +
  "  justify-content: center;" +
  "  text-align:center;" +
  "}" +
  ".horizontal_centered_content > .inner {" +
  "  display:inline;" +
  "  width:100%;" +
  "}" +
  "" +
  "@media screen {" +
  "  .doc.landscape {" +
  "    width: 1080px;" +
  "  }" +
  "  .doc.portrait {" +
  "    width: 720px;" +
  "  }" +
  "}" +
  "" +
  ".updorderlist, .updorderlist td {" +
  "  border: 1px solid #000;" +
  "  border-collapse: collapse;" +
  "}" +
  "" +
  ".updskeleton, .updskeleton > tbody > tr > td {" +
  "  border: 1px solid #FFF;" +
  "}" +
  "" +
  "table {" +
  "  border-collapse: collapse;" +
  "}" +
  "" +
  "</style>";

export default {
  name: "document_info_upd",
  data() {
    this.emitter.on('onDeleteDocument', (data) => {
      if (data.error !== undefined) {
        if (data.error === 'access_denied') {
          this.$q.notify('Недостаточно прав');
          return;
        }
      }
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$route.params.id
      );
    });

    this.emitter.on('onRestoreDocument', (data) => {
      if (data.error !== undefined) {
        if (data.error === 'access_denied') {
          this.$q.notify('Недостаточно прав');
          return;
        }
      }
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$route.params.id
      );
    });
    this.emitter.on('didReceiveDocumentInfo', (response) => {
      // const changes = response.data.changes;
      console.log(response.body);
      this.updateQuarterYearsOptions();
      this.is_active = response.data.is_active;
      this.name = response.data.name;
      this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
      this.changes = response.data.changes;
      this.transfers = response.data.transfers;
      this.page_title = response.data.name;
      const nds_period_index = this.quarter_years_options.findIndex( x => x.value == response.data.nds_period);
      if (nds_period_index >= 0) {
        this.nds_period = this.quarter_years_options[nds_period_index];
      }
      this.$q.loading.hide();
    });
    this.emitter.on('onCreateUpdTransferWildcard', (data) => {
      console.log(data)
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        this.$route.params.history_id
      );
    });
    this.emitter.on('didReceiveBillListFlatCompact', (data) => {
      this.bills_list = data.bills.map((element) => {
        return { label: element.full_name, value: element.id }
      });
    });
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        this.$route.params.history_id
      );
      this.connection.gitBillsListFlatCompact(
        '1',
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentInfo(
          '1',
          this.$route.params.id,
          this.$route.params.history_id
        );
        this.connection.gitBillsListFlatCompact(
          '1',
        );
      })
    }
    return {
      name: ref(''),
      body: ref({}),
      is_active: ref(true),
      transfers: ref([]),
      changes: ref([]),
      document_id: ref(this.$route.params.id),
      bills_list: ref([]),
      wildcard_summ: ref(null),
      wildcard_name: ref(null),
      wildcard_comment: ref(null),
      wildcard_bill_from: ref(null),
      wildcard_bill_to: ref(null),
      is_in_nds: ref(false),
      quarter_years_options: ref([]),
      nds_period: ref(null),
      page_title: ref(null)
    }
  },
  methods: {
    updateQuarterYearsOptions() {
      const years = [
        { value: '2025', label: '2025 года' },
        { value: '2024', label: '2024 года' },
        { value: '2023', label: '2023 года' },
        { value: '2022', label: '2022 года' },
      ];
      const quarters = [
        { value: 1, label: '1 квартал' },
        { value: 2, label: '2 квартал' },
        { value: 3, label: '3 квартал' },
        { value: 4, label: '4 квартал' },
      ]
      var options = [{ value: null, label: 'Не учтен' }];
      const current_year = String(moment().year());
      const current_quarter = moment().quarter();
      years.forEach(year => {
        quarters.forEach(quarter => {
          if (year.value === current_year) {
            if (quarter.value <= current_quarter) {
              options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
            }
          } else {
            options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
          }
        });
      });
      this.quarter_years_options = options;
    },
    updateNdsStateForDocument(value) {
      this.connection.updateDocumentNdsState(
        '1',
        this.$route.params.id,
        value
      )
    },
    onTransferStateChange(value, item_id) {
      this.connection.updateTransferActiveState(
        '1',
        item_id,
        value
      )
    },
    onTransferBillChange(value, item_id, direction) {
      this.connection.updateTransferBillState(
        '1',
        item_id,
        value,
        direction
      )
    },
    valueForTable(array, id, key) {
      return array[id][key].label || array[id][key].value
    },
    valueFor(array, key) {
      return array.find(item => item.field_id === key).value;
    },
    formatDate(date) {
      return moment.unix(date).format('DD.MM.YYYY');
    },
    onPrintButtonClick() {
      let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

      mywindow.document.write(`<html><head><title></title>`);
      mywindow.document.write('</head><body >' + style);
      mywindow.document.write(document.getElementById('print_content').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    },
    createTransferWildcard() {
      this.$q.loading.show();
      this.connection.createUpdTrasferWildcard(
        '1',
        this.$route.params.id,
        this.wildcard_summ,
        this.wildcard_name,
        this.wildcard_comment,
        this.wildcard_bill_from.value,
        this.wildcard_bill_to.value
      )
    },
    onEditDocument() {
      this.$router.push({ name: 'document_edit_upd', params: { id: this.$route.params.id } });
    },
    onDeleteDocument() {
      this.connection.deleteDocument(
        '1',
        this.$route.params.id
      )
    },
    onRestoreDocument() {
      this.connection.restoreDocument(
        '1',
        this.$route.params.id
      )
    }
  }
}
</script>

<style scoped>
@page {
  /*size: 210mm 297mm; */
  size: 297mm 210mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 10mm 10mm 10mm 10mm;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: #FFF;
}

@media screen {
  body {
    padding: 30px;
  }
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
td,
th {
  font-weight: normal;
  color: #000;
}

body .upd,
.upd td,
.upd th {
  font-size: 7pt;
  vertical-align: top;
}

.bold {
  font-weight: bold;
}

a {
  color: #000;
  text-decoration: underline !important;
}

a img {
  border: none;
}

img.full_width {
  width: 100%;
  height: auto;
}

h1,
h2,
h3 {
  font-weight: bold;
}

body,
td,
th {
  font-size: 8pt;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

.gap {
  height: 30px;
}

.nowrap {
  white-space: nowrap;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
}

.clearfix {
  overflow: hidden;
}

.vertical_centered_content {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
}

.vertical_centered_content>.inner {
  display: inline;
  width: 100%;
}

.horizontal_centered_content {
  display: -webkit-flex;
  -webkit-justify-content: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.horizontal_centered_content>.inner {
  display: inline;
  width: 100%;
}

@media screen {
  .doc.landscape {
    width: 1080px;
  }

  .doc.portrait {
    width: 720px;
  }
}

.updorderlist,
.updorderlist td {
  border: 1px solid #000;
  border-collapse: collapse;
}

.updskeleton,
.updskeleton>tbody>tr>td {
  border: 1px solid #FFF;
}

table {
  border-collapse: collapse;
}
</style>