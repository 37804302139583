<template>

	<div class="">
		<q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
			label="Печать" />
		<q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
			label="Редактирование" />
		<q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete"
			text-color="negative" label="Удалить" />
		<q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
			label="Восстановить" />
	</div>
	<div class="doc landscape upd" id="print_content" style="width: 182mm;">
		<h6 class="header" style="font-size: 16pt; width: 100%; text-align: center;">Отчет об изменениях капитала {{
			formatDate(body.date_current) }}г. </h6>
		<div style="width: 182mm; display: flex; flex-wrap: wrap;">
			<div style="display: inline; width: 120mm;">
				<p style="width: 100%; padding-right: 10mm">Организация: {{ body.organisation_name }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Идентификационный номер налогоплательщика: {{
					body.organisation_inn }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Вид экономической деятельности: {{
					body.organisation_econ }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Организационно-правовая форма/форма собственности: {{ body.organisation_form }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Единица измерения: {{ ((body.currency_measure || { label: '' }).label) }}
				</p>
			</div>
			<div style="display: inline; width: 60mm;">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"></td>
						<td colspan="2"></td>
						<td colspan="2"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"></td>
						<td colspan="3"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				1. Движение капитала
			</div>

			<table class="table-cap" style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th style="width: 60mm;">Наименование показателя</th>
						<th style="width: 20mm;">Уставной капитад</th>
						<th style="width: 20mm;">Собственные акции, выкупленные у акционеров</th>
						<th style="width: 20mm;">Добавочный капитал</th>
						<th style="width: 20mm;">Резервный капитал</th>
						<th style="width: 20mm;">Нераспределенная прибыль (непокрытый убыток)</th>
						<th style="width: 20mm;">Итого</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Величина капитала на 31 декабря 2024г.</td>
						<td>{{ body.i_cap_val.ust_cap }} </td>
						<td>{{ body.i_cap_val.redeemed_papers }} </td>
						<td>{{ body.i_cap_val.add_cap }} </td>
						<td>{{ body.i_cap_val.rez_cap }} </td>
						<td>{{ body.i_cap_val.dry_profit }} </td>
						<td>{{ body.i_cap_val.total }} </td>
					</tr>
					<tr>
						<td>За 20г.<br />Увеличение капитала всего</td>
						<td>{{ body.i_cap_increase.ust_cap }} </td>
						<td>{{ body.i_cap_increase.redeemed_papers }} </td>
						<td>{{ body.i_cap_increase.add_cap }} </td>
						<td>{{ body.i_cap_increase.rez_cap }} </td>
						<td>{{ body.i_cap_increase.dry_profit }} </td>
						<td>{{ body.i_cap_increase.total }} </td>
					</tr>
					<tr>
						<td>В том числе:<br />чистая прибыль</td>
						<td>Х</td>
						<td>Х</td>
						<td>Х</td>
						<td>Х</td>
						<td>{{ body.i_clear_profit.dry_profit }} </td>
						<td>{{ body.i_clear_profit.total }} </td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>Х</td>
						<td>Х</td>
						<td>{{ body.i_reval_goods.add_cap }} </td>
						<td>Х</td>
						<td>{{ body.i_reval_goods.dry_profit }} </td>
						<td>{{ body.i_reval_goods.total }} </td>
					</tr>
					<tr>
						<td>доходы, относящиеся непосредственно на увеличение капитала</td>
						<td>Х</td>
						<td>Х</td>
						<td>{{ body.i_income_cap_increase.add_cap }} </td>
						<td>Х</td>
						<td>{{ body.i_income_cap_increase.dry_profit }} </td>
						<td>{{ body.i_income_cap_increase.total }} </td>
					</tr>
					<tr>
						<td>Дополнительный выпуск акций</td>
						<td>{{ body.i_add_papres_create.ust_cap }} </td>
						<td>{{ body.i_add_papres_create.redeemed_papers }} </td>
						<td>{{ body.i_add_papres_create.add_cap }} </td>
						<td>Х</td>
						<td>Х</td>
						<td>{{ body.i_add_papres_create.total }} </td>
					</tr>
					<tr>
						<td>Увеличение номинальной стоимости акций</td>
						<td>{{ body.i_papres_val_increase.ust_cap }} </td>
						<td>{{ body.i_papres_val_increase.redeemed_papers }} </td>
						<td>{{ body.i_papres_val_increase.add_cap }} </td>
						<td>Х</td>
						<td>{{ body.i_papres_val_increase.dry_profit }} </td>
						<td>Х</td>
					</tr>
					<tr>
						<td>реорганизация номинальной стоимости акции</td>
						<td>{{ body.i_nom_papers_price_reval.ust_cap }} </td>
						<td>{{ body.i_nom_papers_price_reval.redeemed_papers }} 
						</td>
						<td>{{ body.i_nom_papers_price_reval.add_cap }} </td>
						<td>{{ body.i_nom_papers_price_reval.rez_cap }} </td>
						<td>{{ body.i_nom_papers_price_reval.dry_profit }} </td>
						<td>{{ body.i_nom_papers_price_reval.total }} </td>
					</tr>
					<tr>
						<td>Уменьшение капитала - всего</td>
						<td>{{ body.i_cap_decrease.ust_cap }} </td>
						<td>{{ body.i_cap_decrease.redeemed_papers }} </td>
						<td>{{ body.i_cap_decrease.add_cap }} </td>
						<td>{{ body.i_cap_decrease.rez_cap }} </td>
						<td>{{ body.i_cap_decrease.dry_profit }} </td>
						<td>{{ body.i_cap_decrease.total }} </td>
					</tr>
					<tr>
						<td>в том числе:<br />убыток</td>
						<td>{{ body.i_cap_decr_loss.ust_cap }} </td>
						<td>{{ body.i_cap_decr_loss.redeemed_papers }} </td>
						<td>{{ body.i_cap_decr_loss.add_cap }} </td>
						<td>{{ body.i_cap_decr_loss.rez_cap }} </td>
						<td>{{ body.i_cap_decr_loss.dry_profit }} </td>
						<td>{{ body.i_cap_decr_loss.total }} </td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>{{ body.i_cap_decr_reval.ust_cap }} </td>
						<td>{{ body.i_cap_decr_reval.redeemed_papers }} </td>
						<td>{{ body.i_cap_decr_reval.add_cap }} </td>
						<td>{{ body.i_cap_decr_reval.rez_cap }} </td>
						<td>{{ body.i_cap_decr_reval.dry_profit }} </td>
						<td>{{ body.i_cap_decr_reval.total }} </td>
					</tr>
					<tr>
						<td>расходы, относящиеся непосредственно на уменьшение капитала</td>
						<td>{{ body.i_cap_decr_cost.ust_cap }} </td>
						<td>{{ body.i_cap_decr_cost.redeemed_papers }} </td>
						<td>{{ body.i_cap_decr_cost.add_cap }} </td>
						<td>{{ body.i_cap_decr_cost.rez_cap }} </td>
						<td>{{ body.i_cap_decr_cost.dry_profit }} </td>
						<td>{{ body.i_cap_decr_cost.total }} </td>
					</tr>
					<tr>
						<td>уменьшение номинальной стоимости акций</td>
						<td>{{ body.i_cap_decr_papers_price_decr.ust_cap }} </td>
						<td>{{ body.i_cap_decr_papers_price_decr.redeemed_papers }} 
						</td>
						<td>{{ body.i_cap_decr_papers_price_decr.add_cap }} </td>
						<td>{{ body.i_cap_decr_papers_price_decr.rez_cap }} </td>
						<td>{{ body.i_cap_decr_papers_price_decr.dry_profit }} </td>
						<td>{{ body.i_cap_decr_papers_price_decr.total }} </td>
					</tr>
					<tr>
						<td>уменьшение количества акций</td>
						<td>{{ body.i_cap_decr_papres_count.ust_cap }} </td>
						<td>{{ body.i_cap_decr_papres_count.redeemed_papers }} </td>
						<td>{{ body.i_cap_decr_papres_count.add_cap }} </td>
						<td>{{ body.i_cap_decr_papres_count.rez_cap }} </td>
						<td>{{ body.i_cap_decr_papres_count.dry_profit }} </td>
						<td>{{ body.i_cap_decr_papres_count.total }} </td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td>{{ body.i_cap_decr_reorg.ust_cap }} </td>
						<td>{{ body.i_cap_decr_reorg.redeemed_papers }} </td>
						<td>{{ body.i_cap_decr_reorg.add_cap }} </td>
						<td>{{ body.i_cap_decr_reorg.rez_cap }} </td>
						<td>{{ body.i_cap_decr_reorg.dry_profit }} </td>
						<td>{{ body.i_cap_decr_reorg.total }} </td>
					</tr>
					<tr>
						<td>дивиденды</td>
						<td>x</td>
						<td>x</td>
						<td>x</td>
						<td>x</td>
						<td>{{ body.i_cap_decr_divid.dry_profit }} </td>
						<td>{{ body.i_cap_decr_divid.total }} </td>
					</tr>
					<tr>
						<td>Изменение добавочного капитала</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_cap_decr_add_cap_decr.add_cap }} </td>
						<td>{{ body.i_cap_decr_add_cap_decr.rez_cap }} </td>
						<td>{{ body.i_cap_decr_add_cap_decr.dry_profit }} </td>
						<td>X</td>
					</tr>
					<tr>
						<td>Изменение резервного капитала</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_change.rez_cap }} </td>
						<td>{{ body.i_rez_cap_change.dry_profit }} </td>
						<td>X</td>
					</tr>
					<tr>
						<td>Величина капитала на 31 декабря 2024</td>
						<td>{{ body.i_rez_cap_value.ust_cap }} </td>
						<td>{{ body.i_rez_cap_value.redeemed_papers }} </td>
						<td>{{ body.i_rez_cap_value.add_cap }} </td>
						<td>{{ body.i_rez_cap_value.rez_cap }} </td>
						<td>{{ body.i_rez_cap_value.dry_profit }} </td>
						<td>{{ body.i_rez_cap_value.total }} </td>
					</tr>
					<tr>
						<td>За 20 г <br />Увеличение капитала - всего:</td>
						<td>{{ body.i_total_cap_incr.ust_cap }} </td>
						<td>{{ body.i_total_cap_incr.redeemed_papers }} </td>
						<td>{{ body.i_total_cap_incr.add_cap }} </td>
						<td>{{ body.i_total_cap_incr.rez_cap }} </td>
						<td>{{ body.i_total_cap_incr.dry_profit }} </td>
						<td>{{ body.i_total_cap_incr.total }} </td>
					</tr>
					<tr>
						<td>в том числе:<br /> чистая прибыль</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_clear_profit.dry_profit }} </td>
						<td>{{ body.i_rez_cap_clear_profit.total }} </td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_tax_operations.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_tax_operations.dry_profit }} </td>
						<td>{{ body.i_tax_operations.total }} </td>
					</tr>
					<tr>
						<td>доходы, относящиеся непосредственно на увеличение капитала</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_income_cap_incr.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_rez_cap_income_cap_incr.dry_profit }} </td>
						<td>{{ body.i_rez_cap_income_cap_incr.total }} </td>
					</tr>
					<tr>
						<td>дополнительный выпуск акций</td>
						<td>{{ body.i_rez_cap_add_papers_create.ust_cap }} </td>
						<td>{{ body.i_rez_cap_add_papers_create.redeemed_papers }} 
						</td>
						<td>{{ body.i_rez_cap_add_papers_create.add_cap }} </td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_add_papers_create.total }} </td>
					</tr>
					<tr>
						<td>увеличение номинальной стоимости акций</td>
						<td>{{ body.i_rez_cap_add_nom_price_papers.ust_cap }} </td>
						<td>{{ body.i_rez_cap_add_nom_price_papers.redeemed_papers }}</td>
						<td>{{ body.i_rez_cap_add_nom_price_papers.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_rez_cap_add_nom_price_papers.dry_profit }} 
						</td>
						<td>X</td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td>{{ body.i_rez_cap_reorg.ust_cap }} </td>
						<td>{{ body.i_rez_cap_reorg.redeemed_papers }} </td>
						<td>{{ body.i_rez_cap_reorg.add_cap }} </td>
						<td>{{ body.i_rez_cap_reorg.rez_cap }} </td>
						<td>{{ body.i_rez_cap_reorg.dry_profit }} </td>
						<td>{{ body.i_rez_cap_reorg.total }} </td>
					</tr>
					<tr>
						<td>Уменьшение капитала - всего:</td>
						<td>{{ body.i_rez_cap_total_cap_decr.ust_cap }} </td>
						<td>{{ body.i_rez_cap_total_cap_decr.redeemed_papers }} 
						</td>
						<td>{{ body.i_rez_cap_total_cap_decr.add_cap }} </td>
						<td>{{ body.i_rez_cap_total_cap_decr.rez_cap }} </td>
						<td>{{ body.i_rez_cap_total_cap_decr.dry_profit }} </td>
						<td>{{ body.i_rez_cap_total_cap_decr.total }} </td>
					</tr>
					<tr>
						<td>в том числе: <br /> убыток</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_loss.dry_profit }} </td>
						<td>{{ body.i_rez_cap_loss.total }} </td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_reval.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_rez_cap_reval.dry_profit }} </td>
						<td>{{ body.i_rez_cap_reval.total }} </td>
					</tr>
					<tr>
						<td>расходы, относящиеся непосредственно на уменьшение капитала</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_cap_decr.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_rez_cap_cap_decr.dry_profit }} </td>
						<td>{{ body.i_rez_cap_cap_decr.total }} </td>
					</tr>
					<tr>
						<td>уменьшение номинальной стоимости акций</td>
						<td>{{ body.i_rez_cap_decr_price_papers.ust_cap }} </td>
						<td>{{ body.i_rez_cap_decr_price_papers.redeemed_papers }} 
						</td>
						<td>{{ body.i_rez_cap_decr_price_papers.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_rez_cap_decr_price_papers.dry_profit }} </td>
						<td>{{ body.i_rez_cap_decr_price_papers.total }} </td>
					</tr>
					<tr>
						<td>уменьшение количества акций</td>
						<td>{{ body.i_rez_cap_papers_decr.ust_cap }} </td>
						<td>{{ body.i_rez_cap_papers_decr.redeemed_papers }} </td>
						<td>{{ body.i_rez_cap_papers_decr.add_cap }} </td>
						<td>X</td>
						<td>{{ body.i_rez_cap_papers_decr.dry_profit }} </td>
						<td>{{ body.i_rez_cap_papers_decr.total }} </td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td>{{ body.i_rez_cap_decr_reorg.ust_cap }} </td>
						<td>{{ body.i_rez_cap_decr_reorg.redeemed_papers }} </td>
						<td>{{ body.i_rez_cap_decr_reorg.add_cap }} </td>
						<td>{{ body.i_rez_cap_decr_reorg.rez_cap }} </td>
						<td>{{ body.i_rez_cap_decr_reorg.dry_profit }} </td>
						<td>{{ body.i_rez_cap_decr_reorg.total }} </td>
					</tr>
					<tr>
						<td>дивиденды</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_decr_divid.dry_profit }} </td>
						<td>{{ body.i_rez_cap_decr_divid.total }} </td>
					</tr>
					<tr>
						<td>Изменение добавочного капитала</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_decr_cap_change.add_cap }} </td>
						<td>{{ body.i_rez_cap_decr_cap_change.rez_cap }} </td>
						<td>{{ body.i_rez_cap_decr_cap_change.dry_profit }} </td>
						<td>X</td>
					</tr>
					<tr>
						<td>Изменение резервного капитала</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>{{ body.i_rez_cap_decr_change.rez_cap }} </td>
						<td>{{ body.i_rez_cap_decr_change.dry_profit }} </td>
						<td>X</td>
					</tr>
					<tr>
						<td>Величина капитала на 31 декабря 2024 г</td>
						<td>{{ body.i_rez_cap_decr_value.ust_cap }} </td>
						<td>{{ body.i_rez_cap_decr_value.redeemed_papers }} </td>
						<td>{{ body.i_rez_cap_decr_value.add_cap }} </td>
						<td>{{ body.i_rez_cap_decr_value.rez_cap }} </td>
						<td>{{ body.i_rez_cap_decr_value.dry_profit }} </td>
						<td>{{ body.i_rez_cap_decr_value.total }} </td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				2. Корректировки в связи с изменением учетной политики и исправлением ошибок
			</div>

			<table class="table-cap" style="table-layout: fixed; max-width: 180mm; ">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th rowspan="2" style="width: 60mm;">Наименование показателя</th>
						<th rowspan="2" style="width: 30mm;">На 31 декабря 2024 г. </th>
						<th rowspan="1" colspan="2" style="width: 60mm;">Изменения капитала за 2024 г.</th>
						<th rowspan="2" style="width: 30mm;">На 31 декабря 2024 г.</th>
					</tr>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th rowspan="1" style="width: 30mm;">за счет чистой прибыли (убытка)</th>
						<th rowspan="1" style="width: 30mm;">за счет иных факторов</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Капитал - всего
							<br />до корректировок
						</td>
						<td>{{ body.ii_cap_total_before_corr.preprevious }}
								</td>
						<td>{{ body.ii_cap_total_before_corr.previous_clear }}
								</td>
						<td>{{ body.ii_cap_total_before_corr.previous_other }}
								</td>
						<td>{{ body.ii_cap_total_before_corr.previous }}
						</td>
					</tr>
					<tr>
						<td>корректировка в связи с:
							<br />изменением учетной политики
						</td>
						<td>{{ body.ii_cap_total_corr_polit.preprevious }}
								</td>
						<td>{{ body.ii_cap_total_corr_polit.previous_clear }}
								</td>
						<td>{{ body.ii_cap_total_corr_polit.previous_other }}
								</td>
						<td>{{ body.ii_cap_total_corr_polit.previous }}
						</td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td>{{ body.ii_cap_total_debug.preprevious }} 
						</td>
						<td>{{ body.ii_cap_total_debug.previous_clear }} 
						</td>
						<td>{{ body.ii_cap_total_debug.previous_other }} 
						</td>
						<td>{{ body.ii_cap_total_debug.previous }} </td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td>{{ body.ii_cap_total_after_corr.preprevious }}
								</td>
						<td>{{ body.ii_cap_total_after_corr.previous_clear }}
								</td>
						<td>{{ body.ii_cap_total_after_corr.previous_other }}
								</td>
						<td>{{ body.ii_cap_total_after_corr.previous }}
						</td>
					</tr>
					<tr>
						<td>в том числе:<br />нераспределенная прибыль (непокрытый убыток):<br />до корректировок</td>
						<td>{{ body.ii_income_undef_profit.preprevious }}
								</td>
						<td>{{ body.ii_income_undef_profit.previous_clear }}
								</td>
						<td>{{ body.ii_income_undef_profit.previous_other }}
								</td>
						<td>{{ body.ii_income_undef_profit.previous }}
						</td>
					</tr>
					<tr>
						<td>корректировка в связи с:<br />изменением учетной политики</td>
						<td>{{ body.ii_income_corr_polit.preprevious }}
						</td>
						<td>{{ body.ii_income_corr_polit.previous_clear }}
								</td>
						<td>{{ body.ii_income_corr_polit.previous_other }}
								</td>
						<td>{{ body.ii_income_corr_polit.previous }}
						</td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td>{{ body.ii_income_debug.preprevious }}</td>
						<td>{{ body.ii_income_debug.previous_clear }}
						</td>
						<td>{{ body.ii_income_debug.previous_other }}
						</td>
						<td>{{ body.ii_income_debug.previous }}</td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td>{{ body.ii_income_after_corr.preprevious }}
						</td>
						<td>{{ body.ii_income_after_corr.previous_clear }}
						</td>
						<td>{{ body.ii_income_after_corr.previous_other }}
						</td>
						<td>{{ body.ii_income_after_corr.previous }}
						</td>
					</tr>
					<tr>
						<td>другие статьи капитала, по которым осуществлены корректировки:<br />до корректировок</td>
						<td>{{ body.ii_other_before_corr.preprevious }}
						</td>
						<td>{{ body.ii_other_before_corr.previous_clear }}
						</td>
						<td>{{ body.ii_other_before_corr.previous_other }}
						</td>
						<td>{{ body.ii_other_before_corr.previous }}
						</td>
					</tr>
					<tr>
						<td>корректировка в связи с:<br />изменением учетной политики</td>
						<td>{{ body.ii_other_corr_polit.preprevious }}
						</td>
						<td>{{ body.ii_other_corr_polit.previous_clear }}
						</td>
						<td>{{ body.ii_other_corr_polit.previous_other }}
						</td>
						<td>{{ body.ii_other_corr_polit.previous }}</td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td>{{ body.ii_other_debug.preprevious }}</td>
						<td>{{ body.ii_other_debug.previous_clear }}
						</td>
						<td>{{ body.ii_other_debug.previous_other }}
						</td>
						<td>{{ body.ii_other_debug.previous }}</td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td>{{ body.ii_other_after_corr.preprevious }} 
						</td>
						<td>{{ body.ii_other_after_corr.previous_clear }}</td>
						<td>{{ body.ii_other_after_corr.previous_other }}</td>
						<td>{{ body.ii_other_after_corr.previous }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				3. Чистые активы
			</div>

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th style="width: 60mm;">Наименование показателя</th>
						<th style="width: 40mm;">На none 2024г.</th>
						<th style="width: 40mm;">На none 2024г.</th>
						<th style="width: 40mm;">На none 2024г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Чистые активы
						</td>
						<td>{{ body.iii_clear_act.preprevious }}</td>
						<td>{{ body.iii_clear_act.previous }}</td>
						<td>{{ body.iii_clear_act.current }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

var style = "<style>@page{size: A4;margin: 0}.doc{margin: 0;padding: 0;font-family: 'Times New Roman', Times, serif;font-size: 12px;background: white;color: black;width: 210mm;padding: 20mm;box-sizing: border-box;display: block;margin: auto;position: relative}.header{text-align: center}.section{margin: 20px 0}table{width: 100%;border-collapse: collapse}table,th,td{border: 0.3mm solid black;padding: 0px 4px;text-align: left}.padded{padding-left: 5mm}.bold{font-weight: bold}.right-align{text-align: right}.signature{margin-top: 20px;text-align: right}@media print{@page{size: A4;margin: 20mm}body{margin: 0;font-size: 12pt}table,th,td{border: 1px solid black;padding: 5px}.header{margin-top: 0}.pagebreak{page-break-before: always}}</style>";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];
export default {
	name: "info_document_report_cap_change",
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});

		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.show = true;
			this.is_active = response.data.is_active;
			this.name = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1'
				);
			})
		}
		return {
			show: ref(false),
			name: ref(''),
			body: ref({
				organisation_name: null,
				organisation_inn: null,
				organisation_econ: null,
				organisation_form: null,
				document_date_unix: 0,
				document_date: null,
				currency_measure_options: currency_measure_options,
				currency_measure: currency_measure_options[0],
				i_cap_val: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_increase: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_clear_profit: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_reval_goods: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_income_cap_increase: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_add_papres_create: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_papres_val_increase: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_nom_papers_price_reval: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decrease: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_loss: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_reval: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_cost: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_papers_price_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_papres_count: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_reorg: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_divid: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_cap_decr_add_cap_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_change: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_value: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_total_cap_incr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_clear_profit: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_tax_operations: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_income_cap_incr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_add_papers_create: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_add_nom_price_papers: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_reorg: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_total_cap_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_loss: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_reval: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_cap_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_decr_price_papers: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_papers_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_decr_reorg: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_decr_divid: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_decr_cap_change: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_decr_change: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
				i_rez_cap_decr_value: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),

				ii_cap_total_before_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_cap_total_corr_polit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_cap_total_debug: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_cap_total_after_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_income_undef_profit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_income_corr_polit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_income_debug: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_income_after_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_other_before_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_other_corr_polit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_other_debug: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
				ii_other_after_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),

				iii_clear_act: ref({ current: 0, previous: 0, desc: '' }),
				date_current: 0,
				date_previous: 0,
				date_preprevious: 0
			}),
			is_active: ref(true),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null)
		}
	},
	methods: {
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				item_id,
				value,
				direction
			)
		},
		valueForTable(array, id, key) {
			return array[id][key].label || array[id][key].value
		},
		valueFor(array, key) {
			return array.find(item => item.field_id === key).value;
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			console.log('onPrintButtonClick');
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'reports_edit_money_using', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$route.params.id
			)
		}
	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.doc {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	width: 210mm;
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.padded {
	padding-left: 5mm;
}

.bold {
	font-weight: bold;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}

	.pagebreak {
		page-break-before: always;
	}
}
</style>