<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="row">
    <div class="col">
      <div class="q-ma-md text-h5 row">
        <div class="col">
          {{ page_title }}
        </div>
        <div class="col" style="text-align: right;">
          <q-btn-dropdown color="primary" label="Создать" icon="add">
            <q-list>
              <q-item clickable v-close-popup @click="$router.push({ name: 'document_create_upd', params: {} })">
                <q-item-section>
                  <q-item-label>УПД</q-item-label>
                </q-item-section>
              </q-item>

              <q-item clickable v-close-popup
                @click="$router.push({ name: 'document_create_factura_avance', params: {} })">
                <q-item-section>
                  <q-item-label>Счет-фактура на аванс</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>

      </div>
      <q-table wrap-cells flat borderless :rows="table_rows" :columns="table_columns" row-key="name" separator="cell"
        :pagination="tablePagination" @row-click="onRowClick" v-model="table_rows" :loading="isUpdatingData">

        <template v-slot:body-cell-nds_period="props">
          <q-td :props="props">
            <q-chip color="info" text-color="white">
              {{ props.row.nds_period.label }}
            </q-chip>
          </q-td>
        </template>

      </q-table>
    </div>
    <div class="col" style="max-width: 320px; padding-left: 16px;">
      <q-select v-model="document_direction" :options="document_direction_options" label="Направление документов"
        @update:model-value="(newValue) => { onFilterChange(newValue, document_type, is_active) }" />
      <q-select v-model="document_type" :options="document_type_options" label="Тип документов"
        @update:model-value="(newValue) => { onFilterChange(document_direction, newValue, is_active) }" />
      <q-select v-model="is_active" :options="document_active_options" label="Состояние документов"
        @update:model-value="(newValue) => { onFilterChange(document_direction, document_type, newValue) }" />

      <q-date v-model="period_range.from" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({from: newValue, to: period_range.to} ) }" />
      <q-date v-model="period_range.to" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({ from: period_range.from, to: newValue }) }" />
      <div v-if="period_range == null">
        Период не установлен
      </div>
      <div v-else>
        Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const document_direction_options = [
  { label: 'Любые', value: 'all' },
  { label: 'Входящие', value: 'in'},
  { label: 'Исходящие', value: 'out' },
  { label: 'Внутренние', value: 'internal' }
]

const document_type_options = [
  { label: 'Все', value: 'upd,avance' },
  { label: 'УПД', value: 'upd' },
  { label: 'Счет-фактура на аванс', value: 'avance' },
  { label: 'Счета на оплату', value: 'bill_to_pay' },
  { label: 'Акт сверки', value: 'act_sver' },
  { label: 'Зачеты авансов', value: 'accept_factura_avance'}
]

const document_active_options = [
  { label: 'Активные', value: true },
  { label: 'Удаленные', value: false }
]

const table_columns = [
  { name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
  { name: 'name', align: 'left', label: 'Название', field: 'name' },
  { name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
  { name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
  { name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
  { name: 'nds_period', align: 'left', label: 'Период НДС', field: 'nds_period', sortable: true }
]
const year_options = [
  { value: '2025', label: '2025' },
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
];
const quarter_options = [
  { value: 1, label: '1 квартал' },
  { value: 2, label: '2 квартал' },
  { value: 3, label: '3 квартал' },
  { value: 4, label: '4 квартал' },
];
export default {
  name: "documents_list",
  components: {
  },
  data() {
    this.emitter.on('didReceiveDocumentsList', (data) => {
      // console.log(data)
      // this.updateQuarterYearsOptions();
      this.table_rows = data.data;
      this.isUpdatingData = false;
    })
    const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
    const period_end = moment().endOf('day');
    const period_start_unix = period_start.unix();
    const period_end_unix = period_end.unix();
    var range = this.$store.state.filter_period;
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentsList(
        '1',
        moment(range.from).startOf('day').unix(),
        moment(range.to).endOf('day').unix(),
        document_direction_options[0].value,
        document_type_options[0].value,
        true
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentsList(
          '1',
          moment(range.from).startOf('day').unix(),
          moment(range.to).endOf('day').unix(),
          document_direction_options[0].value,
          document_type_options[0].value,
          true
        );
      })
    }
    return {
      isUpdatingData: ref(true),
      quarter_options: quarter_options,
      year_options: year_options,
      table_rows: ref([]),
      period_start_unix: ref(period_start_unix),
      period_end_unix: ref(period_end_unix),
      incoming: ref('all'),
      template_id: ref({ label: 'Все', value: -1 }),
      templates_list_options: ref([]),
      document_direction: ref(document_direction_options[0]),
      document_direction_options: document_direction_options,
      document_type: ref(document_type_options[0]),
      document_type_options: document_type_options,
      document_active_options: ref(document_active_options),
      is_active: ref(document_active_options[0]),
      period_range: ref(range),
      tablePagination: {
        sortBy: 'date',
        descending: true,
        page: 1,
        rowsPerPage: 50
      },
      quarter_years_options: ref([]),
      page_title: ref('Список документов')
    }
  },
  setup() {
    moment.locale('ru');
    const period_start_proxy = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end_proxy = ref(moment().endOf('day').format("YYYY/MM/DD"));
    const period_start = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end = ref(moment().endOf('day').format("YYYY/MM/DD"));
    return {
      create_new_bill: ref(false),
      create_new_transfer: ref(false),
      table_columns,
      period_start_proxy,
      period_end_proxy,
      period_start,
      period_end
    }
  },
  methods: {
    prettyPrintNdsPeriod(value) {
      const nds_period_index = this.quarter_years_options.findIndex(x => x.value == value);
      if (nds_period_index >= 0) {
        return this.quarter_years_options[nds_period_index].label;
      } else {
        return "Неизвестно";
      }
    },
    updateQuarterYearsOptions() {
      const years = [
        { value: '2025', label: '2025 года' },
        { value: '2024', label: '2024 года' },
        { value: '2023', label: '2023 года' },
        { value: '2022', label: '2022 года' },
      ];
      const quarters = [
        { value: 1, label: '1 квартал' },
        { value: 2, label: '2 квартал' },
        { value: 3, label: '3 квартал' },
        { value: 4, label: '4 квартал' },
      ]
      var options = [{ value: null, label: 'Не учтен' }];
      const current_year = String(moment().year());
      const current_quarter = moment().quarter();
      years.forEach(year => {
        quarters.forEach(quarter => {
          if (year.value === current_year) {
            if (quarter.value <= current_quarter) {
              options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
            }
          } else {
            options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
          }
        });
      });
      this.quarter_years_options = options;
    },
    updateFilterStartProxy() {
      this.period_start_proxy = this.period_start
    },
    updateFilterEndProxy() {
      this.period_start_proxy = this.period_start
    },
    filterSaveStartProxy() {
      this.period_start = this.period_start_proxy;
      this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
      this.filterSave();
    },
    filterSaveEndProxy() {
      this.period_end = this.period_end_proxy;
      this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
      this.filterSave();
    },
    onRowClick(event, row) {
      if (row.document_type === 'upd') {
        this.$router.push({ name: 'document_info_upd', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'avance') {
        this.$router.push({ name: 'document_info_factura_avance', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'accept_factura_avance') {
        this.$router.push({ name: 'document_info_factura_avance', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'bill_to_pay') {
        this.$router.push({ name: 'document_info_bill_to_pay', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'act_sver') {
        this.$router.push({ name: 'document_info_act_sver', params: { id: row.id, history_id: 'last' } })
      } 

    },
    onSubRowClick(event, row) {
      console.log(row);
    },
    onPeriodChange(model_value) {
      console.log(model_value);
      if (model_value == null) {
        return;
      }
      this.$store.commit('updateFilterPeriod', model_value);
      this.isUpdatingData = true;
      if (this.connection.isAuthenticated) {
        this.connection.getDocumentsList(
          '1',
          moment(model_value.from).endOf('day').unix(),
          moment(model_value.to).endOf('day').unix(),
          this.document_direction.value,
          this.document_type.value,
          this.is_active.value
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getDocumentsList(
            '1',
            moment(model_value.from).endOf('day').unix(),
            moment(model_value.to).endOf('day').unix(),
            this.document_direction.value,
            this.document_type.value,
            this.is_active.value
          );
        })
      }
    },
    onFilterChange(direction, doctype, is_active) {
      if (this.connection.isAuthenticated) {
        this.connection.getDocumentsList(
          '1',
          moment(this.period_range.from).endOf('day').unix(),
          moment(this.period_range.to).endOf('day').unix(),
          direction.value,
          doctype.value,
          is_active.value
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getDocumentsList(
            '1',
            moment(this.period_range.from).endOf('day').unix(),
            moment(this.period_range.to).endOf('day').unix(),
            direction.value,
            doctype.value,
            is_active.value
          );
        })
      }
    },
    prettyDatePrint(date) {
      return moment(date).format('DD MMMM YYYY');
    }
  },
}
</script>

<style scoped></style>