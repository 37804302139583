import { createRouter, createWebHashHistory } from 'vue-router'
import OrganizationMain from  '../views/org/org_main'
import InvoicesHistory from '../views/org/invoices/invoice_history'

// import MyProfile from '../views/profile/my_profile'
import TaskInfo from '../views/tasks/task_info'
import NewTask from '../views/tasks/new_task'
import SignIn from '../views/core/signin'
import SignUp from '../views/core/signup'
import FinanceSummary from '../views/finance/finance_summary'
import BillsStartupMaster from '../views/finance/bills_startup_master'
import BillsPlan from '../views/finance/bills_plan'
import FinancePolicy from '../views/finance/finance_policy'
import TaxReglament from '../views/finance/tax_reglament'
import BillInfo from '../views/finance/bills/bill_info'
import BillSaldo from '../views/finance/bills/bill_saldo_master'
import BillCard from '../views/finance/bills/bill_card'
import BillOperationInfo from '../views/finance/bills/operation_info'
import DocsIncoming from '../views/docs/incoming'
import DocsOutgoing from '../views/docs/outgoing'
import DocsInsight from '../views/docs/insight'
import OrganizationNew from '../views/org/create_new_orgainsation'
import PaymentsList from '../views/finance/bank/payments_list'
import OrgRoleSettings from '../views/org/roles/role_settings'
import OrgRoleNew from '../views/org/roles/new_role'
import MyOrgList from '../views/home/user/my_org'
import ProfileSettings from '../views/home/user/profile_settings'
import TransferTemplatesList from '../views/finance/transfers/transfer_templates_list'
import TransferTemplatesNew from '../views/finance/transfers/transfer_templates_new'
import TransferTemplatesEdit from '../views/finance/transfers/transfer_templates_edit'
import OrganisationProfileBuhAutomatic from '../views/org/automatic_settings'
import OrganisationProfileInfo from '../views/org/profile/info'
import FinanceAccountBills from '../views/finance/account_bills'
import FinanceDocumentsList from  '../views/finance/documents/documents_list'
import StoreAbstractPositionsList from '../views/store/abstract_positions_list'
import StorePositionsList from '../views/store/positions_list'
import ContragentsGlossary from '../views/finance/contragents/glossary'

import DocumentInfoRoot from '../views/finance/documents/info'
import DocumentInfoUpd from '../views/finance/documents/upd/info'
import DocumentInfoPayment from '../views/finance/documents/payment/info'
import DocumentInfoFacturaAvance from '../views/finance/documents/factura_avance/info'
import DocumentInfoBillToPay from '../views/finance/documents/bill_to_pay/info'
import DocumentInfoActSver from '../views/finance/documents/act_sver/info'

import DocumentEditRoot from '../views/finance/documents/edit'
import DocumentEditUpd from '../views/finance/documents/upd/edit'
import DocumentEditPayment from '../views/finance/documents/payment/edit'
import DocumentEditFacturaAvance from '../views/finance/documents/factura_avance/edit'

import DocumentCreateRoot from '../views/finance/documents/create'
import DocumentCreateUpd from '../views/finance/documents/upd/create'
import DocumentCreatePayment from '../views/finance/documents/payment/create'
import DocumentCreateFacturaAvance from '../views/finance/documents/factura_avance/create'

import FinanceReportsRoot from '../views/finance/reports/root'
import FinanceReportsMaster from '../views/finance/reports/master'
import FinanceReportsList from '../views/finance/reports/reports_list'
import FinanceReportsInfoRoot from '../views/finance/reports/info'
import FinanceReportsInfoBalance from '../views/finance/reports/balance/info'
import FinanceReportsInfoFinRez from '../views/finance/reports/fin_rez/info'
import FinanceReportsInfoCapChange from '../views/finance/reports/cap_change/info'
import FinanceReportsInfoMoneyMove from '../views/finance/reports/money_move/info'
import FinanceReportsInfoMoneyUsing from '../views/finance/reports/money_using/info'
import FinanceReportsCreateRoot from '../views/finance/reports/create'
import FinanceReportsCreateBalance from '../views/finance/reports/balance/create'
import FinanceReportsCreateFinRez from '../views/finance/reports/fin_rez/create'
import FinanceReportsCreateCapChange from '../views/finance/reports/cap_change/create'
import FinanceReportsCreateMoneyMove from '../views/finance/reports/money_move/create'
import FinanceReportsCreateMoneyUsing from '../views/finance/reports/money_using/create'
import FinanceReportsCreateNDS from '../views/finance/reports/nds/create'
import FinanceReportsCreateSellsBook from '../views/finance/reports/sells_book/create'
import FinanceReportsCreateBuysBook from '../views/finance/reports/buys_book/create'

import FinanceReportsEditRoot from '../views/finance/reports/edit'
import FinanceReportsEditBalance from '../views/finance/reports/balance/edit'
import FinanceReportsEditFinRez from '../views/finance/reports/fin_rez/edit'
import FinanceReportsEditCapChange from '../views/finance/reports/cap_change/edit'
import FinanceReportsEditMoneyMove from '../views/finance/reports/money_move/edit'
import FinanceReportsEditMoneyUsing from '../views/finance/reports/money_using/edit'

import FinanceOverview from '../views/finance/finance_overview.vue'

import InitialTransfersMaster from '../views/finance/bills/initial_transfers_master'
import ActCheckMaster from '../views/finance/act_check_master'
import Dashboard from '../views/home/dashboard.vue'

import SignUpByInvite from '../views/core/signup_by_invite'

import TarifPlan from '../views/org/tarif_plan.vue'



const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: '/public/invite/:id',
    name:'signup_by_invite',
    component: SignUpByInvite,
    props: true
  },
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    children: [
      {
        path: '/organization',
        name: 'org_main',
        component: OrganizationMain
      },
      {
        path: '/organization/tarif/',
        name: 'tarif_plan',
        component: TarifPlan
      },
      {
        path: '/organization/invoices/history',
        name: 'org_invoices_history',
        component: InvoicesHistory
      },
      {
        path: '/organization/profile/info',
        name: 'org_profile_info',
        component: OrganisationProfileInfo
      },  
      {
        path: '/organization/profile/buh/automatic/',
        name: 'org_profile_buh_automatic',
        component: OrganisationProfileBuhAutomatic
      },
      {
        path: '/org/new',
        name: 'org_new',
        component: OrganizationNew
      },
      {
        path: '/profile',
        name: 'profile',
        component: MyOrgList
      },
      {
        path: '/profile/organization/list/',
        name: 'my_org_list',
        component: MyOrgList,
      },
      {
        path: '/profile/settings',
        name: 'profile_settings',
        component: ProfileSettings,
      },
      {
        path: '/task/:task_id',
        name: 'task_info',
        component: TaskInfo
      },
      {
        path: '/task_new',
        name: 'task_new',
        component: NewTask
      },
      {
        path: '/role/settings/:id',
        name: 'org_role_settings',
        component: OrgRoleSettings,
        props: true
      },
      {
        path: '/role/new',
        name: 'org_role_new',
        component: OrgRoleNew
      },
      {
        path: '/store/positions/',
        name: 'store_positions_list',
        component: StorePositionsList
      },
      {
        path: '/store/positions/abstract',
        name: 'store_abstract_positions_list',
        component: StoreAbstractPositionsList
      },
      {
        path: '/contragents/glossary',
        name: 'contragents_glossary',
        component: ContragentsGlossary
      },
      {
        path: '/finance',
        name: 'finance_summary',
        component: FinanceSummary,
        children: [
          {
            path: 'overview/',
            name: 'finance_overview',
            component: FinanceOverview
          },
          {
            path: 'finance_account_bills',
            name: 'finance_account_bills',
            component: FinanceAccountBills
          },
          {
            path: 'bills_startup_master',
            name: 'bills_startup_master',
            component: BillsStartupMaster
          },
          {
            path: 'master/initial/',
            name: 'initial_transfers_master',
            component: InitialTransfersMaster
          },
          {
            path: 'bill_info',
            name: 'bill_info',
            component: BillInfo,
            props: true
          },
          {
            path: 'bill_saldo/:id?',
            name: 'bill_saldo',
            component: BillSaldo,
            props: true
          },
          {
            path: 'check/act/master/',
            name: 'act_check_master',
            component: ActCheckMaster
          },
          {
            path: 'bill_card/:id',
            name: 'bill_card',
            component: BillCard,
            props: true
          },
          {
            path: 'bill_operation_info/:id',
            name: 'bill_operation_info',
            component: BillOperationInfo,
            props: true
          },
          {
            path: 'finance_policy',
            name: 'finance_policy',
            component: FinancePolicy,
          },
          {
            path: 'tax_reglament',
            name: 'tax_reglament',
            component: TaxReglament,
          },
          {
            path: '/documents/list/',
            name: 'documents_list',
            component: FinanceDocumentsList,
            props: false
          },
          {
            path: '/reports/',
            name: 'reports_root',
            component: FinanceReportsRoot,
            children: [
              {
                path: 'list/',
                name: 'reports_list',
                component: FinanceReportsList
              },
              {
                path: 'master/',
                name: 'reports_master',
                component: FinanceReportsMaster
              },
              {
                path: 'info/:id/',
                name: 'reports_info_root',
                component: FinanceReportsInfoRoot,
                children: [
                  {
                    path: 'balance/:history_id?',
                    name: 'reports_info_balance',
                    component: FinanceReportsInfoBalance,
                    props: true
                  },
                  {
                    path: 'fin/rez/:history_id?',
                    name: 'reports_info_fin_rez',
                    component: FinanceReportsInfoFinRez,
                    props: true
                  },
                  {
                    path: 'cap/change/:history_id?',
                    name: 'reports_info_cap_change',
                    component: FinanceReportsInfoCapChange,
                    props: true
                  },
                  {
                    path: 'money/move/:history_id?',
                    name: 'reports_info_money_move',
                    component: FinanceReportsInfoMoneyMove,
                    props: true
                  },
                  {
                    path: 'money/using/:history_id?',
                    name: 'reports_info_money_using',
                    component: FinanceReportsInfoMoneyUsing,
                    props: true
                  }
                ],
              },
              {
                path: 'create/',
                name: 'reports_create_root',
                component: FinanceReportsCreateRoot,
                children: [
                  {
                    path: 'balance/',
                    name: 'reports_create_balance',
                    component: FinanceReportsCreateBalance
                  },
                  {
                    path: 'fin/rez/',
                    name: 'reports_create_fin_rez',
                    component: FinanceReportsCreateFinRez
                  },
                  {
                    path: 'cap/chnage/',
                    name: 'reports_create_cap_change',
                    component: FinanceReportsCreateCapChange
                  },
                  {
                    path: 'money/move/',
                    name: 'reports_create_money_move',
                    component: FinanceReportsCreateMoneyMove
                  },
                  {
                    path: 'money/using/',
                    name: 'reports_create_money_using',
                    component: FinanceReportsCreateMoneyUsing
                  },
                  {
                    path: 'nds/',
                    name: 'reports_create_nds',
                    component: FinanceReportsCreateNDS
                  },
                  {
                    path: 'book/sells/',
                    name: 'reports_create_sells_book',
                    component: FinanceReportsCreateSellsBook
                  },
                  {
                    path: 'book/buys/',
                    name: 'reports_create_buys_book',
                    component: FinanceReportsCreateBuysBook
                  }
                ],
              },
              {
                path: 'edit/:id/',
                name: 'reports_edit_root',
                component: FinanceReportsEditRoot,
                children: [
                  {
                    path: 'balance/',
                    name: 'reports_edit_balance',
                    component: FinanceReportsEditBalance
                  },
                  {
                    path: 'fin/rez/',
                    name: 'reports_edit_fin_rez',
                    component: FinanceReportsEditFinRez
                  },
                  {
                    path: 'cap/change/',
                    name: 'reports_edit_cap_change',
                    component: FinanceReportsEditCapChange
                  },
                  {
                    path: 'money/move/',
                    name: 'reports_edit_money_move',
                    component: FinanceReportsEditMoneyMove
                  },
                  {
                    path: 'money/using/',
                    name: 'reports_edit_money_using',
                    component: FinanceReportsEditMoneyUsing
                  }
                ],
              }
            ]
          },
          {
            path: '/documents/info/:id/',
            name: 'document_info_root',
            component: DocumentInfoRoot,
            props: true,
            children: [
              {
                path: 'upd/:history_id?',
                name: 'document_info_upd',
                component: DocumentInfoUpd,
                props: true
              },
              {
                path: 'payment/:history_id?',
                name: 'document_info_payment',
                component: DocumentInfoPayment,
                props: true
              },
              {
                path: 'factura_avance/:history_id?',
                name: 'document_info_factura_avance',
                component: DocumentInfoFacturaAvance,
                props: true
              },
              {
                path: 'bill_to_pay/:history_id?',
                name: 'document_info_bill_to_pay',
                component: DocumentInfoBillToPay,
                props: true
              },
              {
                path: 'act_sver/:history_id?',
                name: 'document_info_act_sver',
                component: DocumentInfoActSver,
                props: true
              }
            ]
          },
          {
            path: '/documents/create/',
            name: 'document_create_root',
            component: DocumentCreateRoot,
            children: [
              {
                path: 'upd/',
                name: 'document_create_upd',
                component: DocumentCreateUpd
              },
              {
                path: 'payment/',
                name: 'document_create_payment',
                component: DocumentCreatePayment
              },
              {
                path: 'factura_avance/',
                name: 'document_create_factura_avance',
                component: DocumentCreateFacturaAvance
              }
            ]
          },
          {
            path: '/documents/edit/:id/',
            name: 'document_edit_root',
            component: DocumentEditRoot,
            children: [
              {
                path: 'upd/',
                name: 'document_edit_upd',
                component: DocumentEditUpd,
                props: true
              },
              {
                path: 'payment/',
                name: 'document_edit_payment',
                component: DocumentEditPayment,
                props: true
              },
              {
                path: 'factura_avance/',
                name: 'document_edit_factura_avance',
                component: DocumentEditFacturaAvance,
                props: true
              }
            ]
          },
          {
            path: '/transfer_templates_list',
            name: 'transfer_templates_list',
            component: TransferTemplatesList,
            props: true
          },
          {
            path: 'bills_plan',
            name: 'bills_plan',
            component: BillsPlan
          },
          {
            path: '/transfer/templates/new',
            name: 'transfer_templates_new',
            component: TransferTemplatesNew,
            props: true
          },
          {
            path: '/transfer/templates/edit/:id',
            name: 'transfer_templates_edit',
            component: TransferTemplatesEdit,
            props: true
          },
          {
            path: '/docs_incoming',
            name: 'docs_incoming',
            component: DocsIncoming
          },
          {
            path: '/docs_insight',
            name: 'docs_insight',
            component: DocsInsight
          },
          {
            path: '/docs_outgoing',
            name: 'docs_outgoing',
            component: DocsOutgoing
          },
          {
            path: '/payments_list',
            name: 'payments_list',
            component: PaymentsList
          }
        ]
      }
    ]
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
