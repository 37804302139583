<template>
  <div class="doc print_doc" id="print_content">
    <div>
      <div style="width: 100%; text-align: right; font-size: 6pt;">
        Приложение N 1 к постановлению Правительства Российской Федерации от 26 декабря 2011 г. N 1137<br />(в ред.
        Постановления Правительства РФ от 19.08.2017 No 981)
      </div>
      <div style="font-size: 10pt; line-height: 1.5;">
        <div style="font-weight: bold;">
          Счет-фактура на аванс № <q-input dense class="inline" v-model="in_org_number" /> от
          <q-input class="inline" dense v-model="document_date">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date mask="DD.MM.YYYY" v-model="document_date"
                    @update:model-value="(newValue) => onDateChanged(newValue)">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Закрыть" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div>
          Исправление № -- от --
        </div>
      </div>
      <div style="font-size: 11pt; line-height: 1; margin-bottom: 3mm;">
        <div>
          Продавец: <q-input dense class="inline" v-model="seller" />
        </div>
        <div>
          Адрес: <q-input dense class="inline" v-model="seller_adr" />
        </div>
        <div>
          ИНН / КПП продавца: <q-input mask="## ## ###### ##" dense class="inline" v-model="seller_inn"
            @update:model-value="checkAgentBy(seller_inn, 'seller_inn')" />
        </div>
        <div>
          Грузоотправитель и его адрес: -
        </div>
        <div>
          Грузополучатель и его адрес: -
        </div>
        <div>
          К платежно-расчетному документу: <q-input dense class="inline" v-model="plat_ras" />
        </div>
        <div>
          Покупатель: <q-input dense class="inline" v-model="cust" />
        </div>
        <div>
          Адрес: <q-input dense class="inline" v-model="cust_adr" />
        </div>
        <div>
          ИНН / КПП покупателя: <q-input mask="## ## ###### ##" dense class="inline" v-model="cust_inn"
            @update:model-value="checkAgentBy(cust_inn, 'cust_inn')" />
        </div>
        <div>
          Валюта: наименование, код: <q-input dense class="inline" v-model="currency" />
        </div>
        <div>
          Идентификатор государственного контракта, договора (соглашения) (при наличии): <q-input dense class="inline"
            v-model="goscon" />
        </div>
      </div>
    </div>

    <table class="main-table" cellspacing="0" cellpadding="5"
      style="font-size:7pt; line-height: 1; text-align: center;">
      <tbody>
        <tr>
          <td style="width: 60mm;" rowspan="2">Наименование товара (описание выполненных работ, оказанных услуг),
            имущественного права</td>
          <td rowspan="2" style="width: 15mm;">Код вида товара</td>
          <td colspan="2" style="width: 30mm;">Единица измерения</td>
          <td rowspan="2" style="width: 15mm;">Количество (объём)</td>
          <td rowspan="2" style="width: 15mm;">Цена (тариф) за единицу измерения</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав без налога — всего
          </td>
          <td rowspan="2" style="width: 10mm;">В том числе сумма акциза</td>
          <td rowspan="2" style="width: 15mm;">Налоговая ставка</td>
          <td rowspan="2" style="width: 15mm;">Сумма налога, предъявляемая покупателю</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав с налогом — всего
          </td>
          <td colspan="2" style="width: 30mm;">Страна происхождения товара</td>
          <td rowspan="2" style="width: 30mm;">Регистрационный номер декларации</td>
        </tr>
        <tr>
          <td style="width: 10mm;">Код</td>
          <td style="width: 20mm;">Условное обозначение (национальное)</td>
          <td style="width: 10mm;">Цифровой код</td>
          <td style="width: 20mm;">Краткое наименование</td>
        </tr>
        <tr>
          <td style="text-align: center; font-size: 6pt">1</td>
          <td style="text-align: center; font-size: 6pt">1а</td>
          <td style="text-align: center; font-size: 6pt">2</td>
          <td style="text-align: center; font-size: 6pt">2а</td>
          <td style="text-align: center; font-size: 6pt">3</td>
          <td style="text-align: center; font-size: 6pt">4</td>
          <td style="text-align: center; font-size: 6pt">5</td>
          <td style="text-align: center; font-size: 6pt">6</td>
          <td style="text-align: center; font-size: 6pt">7</td>
          <td style="text-align: center; font-size: 6pt">8</td>
          <td style="text-align: center; font-size: 6pt">9</td>
          <td style="text-align: center; font-size: 6pt">10</td>
          <td style="text-align: center; font-size: 6pt">10а</td>
          <td style="text-align: center; font-size: 6pt">11</td>
        </tr>
        <tr v-for="item in table_rows" v-bind:key="item.id">
          <td style="text-align: left;"><q-input dense class="inline" v-model="item.name" /></td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;"><q-select :options="nds_percentage_options"
              @update:model-value="(newValue) => onNdsValueChanged(newValue.value)" dense borderless
              v-model="item.nds" />
          </td>
          <td style="text-align: right;"><q-input dense borderless v-model="item.nalog"
              @update:model-value="(newValue) => onNalogChanged(newValue)" /></td>
          <td style="text-align: right;"><q-input dense borderless v-model="item.summtotal"
              @update:model-value="(newValue) => onSummtotalChanged(newValue)" /></td>
          <td>-</td>
          <td>-</td>
          <td style="text-align: left;">-</td>
        </tr>
        <tr style="border-bottom: transparent !important; border-right: transparent !important;">

          <td colspan="6" style="font-weight: bold; text-align: left;">Всего к оплате</td>
          <td style="text-align: right;">-</td>
          <td colspan="2">X</td>
          <td style="text-align: right;">{{ Math.round((summary || { nalog: 0 }).nalog * 100) / 100 }}</td>
          <td style="text-align: right;">{{ Math.round((summary || { summtotal: 0 }).summtotal * 100) / 100 }}</td>
          <td colspan="3" style="border: 0px solid white;"></td>
        </tr>
      </tbody>
    </table>

    <div style=" height: 10mm; margin-top: 3mm; vertical-align: top; font-size: 7pt;">
      <div style="display: inline-block; width: 40mm; height: 10mm;">
        Руководитель организации или иное уполномоченное лицо
      </div>

      <div style="display: inline-block; width: 92mm; margin-left: 3mm; text-align: center;">
        <dir>
          <q-input dense class="inline" style="width: 100%;" label="Ф.И.О." v-model="rukvod" />
        </dir>
      </div>
      <div style="display: inline-block; width: 35mm; height: 10mm; margin-left: 8mm;">
        Главный бухгалтер или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 105mm; margin-left: 3mm; text-align: center;">
        <dir>
          <q-input dense class="inline" style="width: 100%;" label="Ф.И.О." v-model="glavbuh" />
        </dir>
      </div>
    </div>
    <div style="height: 10mm; margin-top: 5mm; font-size: 7pt;">
      <div style="display: inline-block; width: 40mm; height: 10mm;">
        Индивидуальный предприниматель или иное уполномоченное лицо
      </div>

      <div style="display: inline-block; width: 92mm; margin-left: 3mm; text-align: center;">
        <dir>
          <q-input dense class="inline" style="width: 100%;" label="Ф.И.О." v-model="ip_name" />
        </dir>
      </div>

      <div style="display: inline-block; width: 133mm; margin-left: 8mm; text-align: center;">
        <dir>
          <q-input dense class="inline" style="width: 100%;"
            label="(реквизиты свидетельства о государственной регистрации индивидуального предпринимателя)"
            v-model="ip_req" />
        </dir>
      </div>
    </div>
  </div>
  <div style="margin-top: 10mm;">
    <q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { nanoid } from "nanoid";

const document_direction_options = [
  {
    label: 'Входящий',
    value: 'in'
  },
  {
    label: 'Исходящий',
    value: 'out'
  },
  {
    label: 'Внутренний',
    value: 'internal'
  }
]

const nds_percentage_options = [
  {
    label: '20/120',
    value: 6,
  },
  {
    label: '10/110',
    value: 11,
  }
];

const transfers_table_columns = [
  { name: 'name', align: 'left', label: 'Название', field: 'name', sortable: true },
  { name: 'bill_from', align: 'left', label: 'Счет списания', field: 'bill_from', sortable: true },
  { name: 'bill_to', align: 'left', label: 'Счет зачисления', field: 'bill_to', sortable: true },
  { name: 'summ', align: 'left', label: 'Сумма', field: 'summ' },
  { name: 'status', align: 'left', label: 'Статус', field: 'status' }
]

export default {
  name: "create_document_factura_avance",
  data() {
    this.emitter.on('didCreateNewDocument', (data) => {
      console.log(data);
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Документ не был создан',
          cancel: false,
          persistent: true
        }).onOk(() => {
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Документ был создан');
        this.$router.push({ name: 'documents_list', params: {} });
      }
    })
    this.emitter.on('didReceiveNewDocumentNumber', (data) => {
      this.in_org_number = data.number;
      this.step = 2;
    });
    this.emitter.on('didReceiveAgentInfoByInn', (data) => {
      console.log(data);
      if (data.request_id === 'seller_inn') {
        this.seller_adr = data.info.address.unrestricted_value;
        this.seller = data.info.name.short_with_opf;
      }
      if (data.request_id === 'cust_inn') {
        this.cust_adr = data.info.address.unrestricted_value;
        this.cust = data.info.name.short_with_opf;
      }
    })
    if (this.connection.isAuthenticated) {
      this.connection.getNewDocumentNumber(
        nanoid(),
        'avance'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getNewDocumentNumber(
          nanoid(),
          'avance'
        );
      })
    }
    return {
      step: ref(1),
      templates_list_options: ref([]),
      document_type: ref(null),
      top_section: ref([]),
      table_section: ref([]),
      bottom_section: ref([]),
      bottom_section_left: ref([]),
      bottom_section_right: ref([]),
      table_raw_columns: ref([]),
      table_columns: ref([]),
      table_sections: ref([]),
      table_rows: ref([{
        code: '',
        id: 1,
        uuid: nanoid(),
        name: '',
        kind_code: '',
        measure_value: '',
        measure_label: '',
        count: '',
        price: '',
        cost_without_nds: '',
        aciz: '',
        nds: { label: '20/120', value: 6 },
        nalog: 0,
        summtotal: 0,
        contrycode: '',
        countryname: '',
        doc: '',
      }]),
      proto_values: ref({}),
      temporary_values: ref({}),
      template_id: ref(null),
      in_org_number: ref(null),
      document_direction_options: document_direction_options,
      document_direction: ref(null),
      document_date_proxy: ref(moment().format("YYYY/MM/DD")),
      document_date: ref(moment().format("YYYY/MM/DD")),
      document_date_unix: ref(moment().unix()),
      bills_list_options: ref([]),
      position_transfers: ref([]),
      document_id: ref(null),
      all_transfers_done: ref(false),
      nds_percentage_options: nds_percentage_options,
      users_list_options: ref([]),
      current_contragent_field: ref(''),
      original_positions_list_options: ref([]),
      transfers_table_columns: ref(transfers_table_columns),
      summary: ref({ nalog: 0, summtotal: 0 }),
      cust: ref(null),
      cust_adr: ref(null),
      cust_inn: ref(null),
      currency: ref(null),
      goscon: ref(null),
      seller: ref(null),
      seller_adr: ref(null),
      seller_inn: ref(null),
      seller_sender: ref(null),
      seller_receiver: ref(null),
      seller_doc: ref(null),
      seller_send_doc: ref(null),
      change_n: ref(null),
      change_date: ref(null),
      code: ref(null),
      date: ref(null),
      kind_code: ref(null),
      status: ref(1),
      rukvod: ref(null),
      glavbuh: ref(null),
      ip_name: ref(null),
      ip_req: ref(null),
      osn_rec_sent: ref(null),
      transp: ref(null),
      goods_sent: ref(null),
      goods_sent_workplace: ref(null),
      goods_sent_fio: ref(null),
      date_sent: ref(null),
      sent_info: ref(null),
      hozvod_workplace: ref(null),
      hozvod_fio: ref(null),
      econom_name: ref(null),
      date_rec: ref(null),
      goods_rec_fio: ref(null),
      goods_rec_workplace: ref(null),
      rec_info: ref(null),
      econom_rec_name: ref(null),
      hozvod_rec_fio: ref(null),
      hozvod_rec_workplace: ref(null)
    }
  },
  methods: {
    onDateChanged(newValue) {
      this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
    },
    checkAgentBy(inn_val, field_id) {
      const inn = inn_val.replace(/\s/g, "");
      console.log(inn, field_id)
      if (inn.length < 9) {
        return;
      }
      this.connection.getAgentInfoByInn(
        field_id,
        inn
      );
    },
    addTableRow() {
      const item = {
        code: '',
        id: this.table_rows.length + 1,
        uuid: nanoid(),
        name: '',
        kind_code: '',
        measure_value: '',
        measure_label: '',
        count: '',
        price: '',
        cost_without_nds: '',
        aciz: '',
        nds: '',
        nalog: '',
        summtotal: '',
        contrycode: '',
        countryname: '',
        doc: '',
      }
      this.table_rows.push(item);
    },
    onNdsValueChanged(nds_changed) {
      const nalog = this.table_rows[0].nalog;
      const summtotal = this.table_rows[0].summtotal;
      const nds_percent = nds_changed;
      if (nalog !== 0) {
        const new_summtotal = nds_percent * nalog;
        this.table_rows[0].summtotal = new_summtotal;
        this.summary.summtotal = new_summtotal;
        return
      } else {
        if (summtotal !== 0) {
          const new_nalog = summtotal / nds_percent;
          this.table_rows[0].nalog = new_nalog;
          this.summary.nalog = new_nalog;
          return
        }
      }
    },
    onSummtotalChanged(summtotal) {
      const nds_percent = this.table_rows[0].nds.value;
      const new_nalog = summtotal / nds_percent;
      this.table_rows[0].nalog = new_nalog;
      this.summary.nalog = new_nalog;
      this.summary.summtotal = summtotal;
    },
    onNalogChanged(nalog) {
      const nds_percent = this.table_rows[0].nds.value;
      const new_summtotal = nalog * nds_percent;
      this.table_rows[0].summtotal = new_summtotal;
      this.summary.nalog = nalog;
      this.summary.summtotal = new_summtotal;
    },
    onSave() {
      this.$q.loading.show();
      const body = {
        summary: this.summary,
        cust: this.cust,
        cust_adr: this.cust_adr,
        cust_inn: this.cust_inn,
        currency: this.currency,
        goscon: this.goscon,
        seller: this.seller,
        seller_adr: this.seller_adr,
        seller_inn: this.seller_inn,
        seller_sender: this.seller_sender,
        seller_receiver: this.seller_receiver,
        seller_doc: this.seller_doc,
        seller_send_doc: this.seller_send_doc,
        change_n: this.change_n,
        change_date: this.change_date,
        code: this.code,
        date: this.date,
        kind_code: this.kind_code,
        status: this.status,
        rukvod: this.rukvod,
        glavbuh: this.glavbuh,
        ip_name: this.ip_name,
        ip_req: this.ip_req,
        osn_rec_sent: this.osn_rec_sent,
        transp: this.transp,
        goods_sent: this.goods_sent,
        goods_sent_workplace: this.goods_sent_workplace,
        goods_sent_fio: this.goods_sent_fio,
        date_sent: this.date_sent,
        sent_info: this.sent_info,
        hozvod_workplace: this.hozvod_workplace,
        hozvod_fio: this.hozvod_fio,
        econom_name: this.econom_name,
        date_rec: this.date_rec,
        goods_rec_fio: this.goods_rec_fio,
        goods_rec_workplace: this.goods_rec_workplace,
        rec_info: this.rec_info,
        econom_rec_name: this.econom_rec_name,
        hozvod_rec_fio: this.hozvod_rec_fio,
        hozvod_rec_workplace: this.hozvod_rec_workplace,
        table_rows: this.table_rows,
        in_org_number: this.in_org_number,
        document_date: this.document_date_unix,
        document_type: 'avance'
      };
      this.connection.createNewDocument(
        nanoid(),
        body
      )
    },
  }
}
</script>

<style scoped>
@page {
  size: 297mm 210mm;
  margin: 10mm 10mm 10mm 10mm;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: #FFF;
}

.print_doc {
  font-family: 'Times New Roman', Times, serif;
  font-size: 7pt;
}

.main-table td {
  border: 0.3mm solid black;
}
</style>