<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el label="Структура организации" @click="$router.push({ name: 'org_main', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="q-ma-md text-h5">
    {{ page_title }}
  </div>
  <div class="row">
    <div class="col" style="max-width: 500px;">

      <q-list>
        <q-item-label>Сотрудник</q-item-label>
        <q-item v-if="current_user.id > 0" v-ripple clickable>
          <q-item-section center avatar>
            <q-avatar color="primary" size="60px" text-color="white">
              {{ current_user.email.slice(0,2) }}
            </q-avatar>
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ current_user.display_name }}</q-item-label>
            <q-item-label caption>{{ current_user.email }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-btn flat v-show="!is_root_role" dense color="negative" label="Уволить"
              @click="removeUserFromOrg(current_user.id)" />
            <!-- <q-btn dense flat icon="settings">
                  <q-menu>  
                    <q-list style="min-width: 100px">
                      <q-item clickable v-close-popup @click="removeUserFromOrg(current_user.id)">
                        <q-item-section class="text-red">Уволить</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn> -->
          </q-item-section>
        </q-item>
        <q-item v-else-if="!is_has_invite">

          <q-item-section>
            <q-item-label>Место вакантно</q-item-label>
          </q-item-section>

        </q-item>
        <div v-if="is_has_invite">
          <q-item>
            <q-item-section>
              Приглашение {{ display_name_invite }} отправлено на <span class="text-primary">{{ old_invite_email
                }}</span>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section side>
              <q-btn color="primary" label="Отменить приглашение" @click="cancelInvitation" />
            </q-item-section>
          </q-item>
        </div>

        <div v-else-if="current_user.id <= 0">

          <q-item>
            <q-item-section>
              <q-input dense v-model="first_name_invite" label="Имя" :loading="show_invite_loading" />
            </q-item-section>
            <q-item-section>
              <q-input dense v-model="last_name_invite" label="Фамилия" :loading="show_invite_loading" />
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <q-input dense v-model="email_invite" label="E-mail" :loading="show_invite_loading" type="email" :rules="[
                  val => !!val || 'Введите email',
                  val => validateEmail(val) || 'Неверный формат email'
                ]" />
            </q-item-section>
            <q-item-section side>
              <q-btn flat :disable="!(invite_allowed && (email_invite || '').length !== 0)" label="Пригласить"
                @click="inviteUserToOrganisation" />
            </q-item-section>
          </q-item>
        </div>
        <q-item-label>Настройки</q-item-label>
        <q-item>
          <q-item-section>
            <q-input v-model="role_title" label="Название рабочего места" />
          </q-item-section>
        </q-item>
        <q-item-label>Права доступа</q-item-label>
        <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox :disable="is_root_role" v-model="edit_org" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Структура организации</q-item-label>
          </q-item-section>
        </q-item>
        <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox :disable="is_root_role" v-model="change_billing" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Тарифный план</q-item-label>
          </q-item-section>
        </q-item>
        <!-- <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox :disable="is_root_role" v-model="open_fin_documents" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Документы</q-item-label>
          </q-item-section>
        </q-item> -->
        <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox :disable="is_root_role" v-model="edit_fin_documents" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Бухгалтерия</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-ripple>
          <q-item-section>
            <q-item-label>
              <q-btn label="Сохранить" @click="saveRole" color="primary" />
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-ripple>
          <q-item-section>
            <q-item-label>
              <q-btn :disable="!delete_allowed || is_root_role" label="Удалить" color="red" @click="tryToDeleteRole" />
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "role_settings",
  mounted() {

  },
  setup() {

  },
  data() {
    this.emitter.on('didInviteUserToOrg', (data) => {
      console.log(data);
      this.$q.loading.hide();
      this.connection.getUsersList(
        '1'
      );
      this.connection.getRoleInfoById(
        '1', 
        this.$route.params.id
      );
      this.email_invite = null
      // this.show_invite_loading = false;
      this.$q.notify('Приглашение было успешно отправлено');
    })
    this.emitter.on('didUpdateRoleInfoById', (data) => {
      console.log(data);
      this.loading.hide();
      this.$q.notify('Рабочее место было успешно изменено');
      this.connection.getUsersList(
        '1'
      );
      this.connection.getRoleInfoById(
        '1', 
        this.$route.params.id
      );
    })
    this.emitter.on('didDeleteRoleFromOrganisation', (data) => {
      console.log(data);
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете удалить это рабочее место',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Рабочее место было успешно удалено');
        this.$router.back();
      }
    });
    this.emitter.on('didReceiveRoleInfoById', (response) => {
      const data = response.role.permissions;
      this.is_root_role = response.role.root;
      this.role_title = response.role.role;
      this.page_title = response.role.role;
      this.edit_org = data.edit_org;
      this.change_billing = data.change_billing;
      this.edit_fin_documents = data.edit_fin_documents;
      this.open_fin_documents = data.open_fin_documents;
      
      this.invite_allowed = !(response.role.has_user);
      this.delete_allowed = true;
      this.is_has_invite = !response.role.invite.empty;
      this.old_invite_email = response.role.invite.email;
      this.display_name_invite = response.role.invite.display_name;
      this.is_has_worker = response.role.has_user;
      if (response.role.has_user) {
        this.current_user = response.role.user;
      } else {
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
      }
    })
    this.emitter.on('onCancelInvitation', (data) => {
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете отменить это приглашение',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Приглашение было успешно отменено');
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
        this.is_has_invite = false;
      }
    });

    this.emitter.on('onAddUserToRole', (data) => {
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете назначить работника на рабочее место',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Работник успешно назначен');
        this.connection.getUsersList(
          '1'
        );
      }
    });

    this.emitter.on('onRemoveUserFromRole', (data) => {
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете убрать работника с этого рабочего места',
          cancel: false,
          persistent: true
        }).onOk(() => {
          this.confirmation_code = null;
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Рабочее место успешно освобождено');
        this.connection.getUsersList(
          '1'
        );
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
        this.is_has_invite = false;
      }
    });
    this.emitter.on('onRemoveUserFromOrg', (data) => {
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Вы не можете уволить работника',
          cancel: false,
          persistent: true
        }).onOk(() => {
          console.log('cancel')
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Работник успешно уволен');
        this.current_user = { email: '', display_name: '', role: { role: '' }, id: -1 };
        this.is_has_invite = false;
        this.connection.getUsersList(
          '1'
        );
        this.connection.getRoleInfoById(
          '1', 
          this.$route.params.id
        );
      }
    });
    this.emitter.on('onGetUsersList', (data) => {
      this.all_users = data.data;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getRoleInfoById(
        '1',
         this.$route.params.id);
      this.connection.getUsersList(
        '1'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getRoleInfoById(
          '1',
          this.$route.params.id
        );
        this.connection.getUsersList(
          '1'
        );
      })
    }
    return {
      is_root_role: ref(false),
      can_save_role: ref(true),
      can_invite_user: ref(false),
      show_invite_loading: ref(false),
      organisation_flat: ref({roles: []}),
      parents: [],
      options: ref([]),
      parent: ref(''),
      role_title: ref(''),
      edit_org: ref(false),
      change_billing: ref(false),
      open_fin_documents: ref(false),
      edit_fin_documents: ref(false),
      role_id: ref(this.$route.params.id),
      invite_allowed: ref(true),
      delete_allowed: ref(false),
      users_list: ref([]),
      is_has_worker: ref(false),
      current_user: ref({ email: '', display_name: '', role: { role: '' }, id: -1 }),
      all_users: ref([]),
      selected_user: ref(null),
      email_invite: ref(null),
      first_name_invite: ref(null),
      last_name_invite: ref(null),
      is_has_invite: ref(false),
      old_invite_email: ref(null),
      display_name_invite: ref(null),
      page_title: ref('')
    }
  },
  methods: {
    saveRole() {
      const data = {
        role_title: this.role_title,
        edit_org: this.edit_org,
        change_billing: this.change_billing,
        open_fin_documents: this.edit_fin_documents,
        edit_fin_documents: this.edit_fin_documents,
      }
      this.connection.updateRoleInfoById('1', this.role_id, data);
    },
    inviteUserToOrganisation() {
      this.$q.loading.show();
      this.connection.inviteUserToOrg(
        '1', 
        this.email_invite, 
        this.role_id, 
        this.first_name_invite, 
        this.last_name_invite
      )
    },
    tryToDeleteRole() {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Рабочее место будет удалено, привязанные пользователи будут исключены из организации',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.deleteRole();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      })
    },
    deleteRole() {
      this.connection.deleteRoleFromOrganisation('1', this.role_id);
      this.$q.loading.show();
    },
    cancelInvitation() {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Приглашение будет отозвано',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.connection.cancelInvitation('1', this.role_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });
    },
    removeUserFromOrg(user_id) {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Пользователь будет исключен из организации',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.connection.removeUserFromOrg('1', user_id, this.role_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });

    },
    addUserToRole(user_id) {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Пользователь будет назначен на рабочее место',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.connection.addUserToRole('1', user_id, this.role_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });

    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    removeUserFromRole() {
      this.$q.dialog({
        title: 'Предупреждение',
        message: 'Пользователь будет убран с рабочего места',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.connection.removeUserFromRole('1', this.role_id);
        this.$q.loading.show();
      }).onCancel(() => {
        console.log('cancel')
      }).onDismiss(() => {
        console.log('dismiss')
      });

    }
  }
}
</script>

<style scoped>

</style>