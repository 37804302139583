<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el label="Документы" @click="$router.push({ name: 'documents_list', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="row">
    <div class="col">
      <div class="q-ma-md text-h5 row">
        <div class="col">
          {{ page_title }}
        </div>
        <div class="col" style="text-align: right;">
          <q-btn-dropdown color="primary" label="Управление" icon="settings">
            <q-list>
              <!-- <q-item v-show="direction == 'out' && document_type != 'accept_factura_avance'" clickable v-close-popup @click="onAcceptAvance">
                <q-item-section>
                  <q-item-label>Зачесть аванс</q-item-label>
                </q-item-section>
              </q-item> -->
              <!-- <q-item v-show="body.avance_id !== undefined && document_type != 'accept_factura_avance'" clickable v-close-popup @click="onEditAvance">
                <q-item-section>
                  <q-item-label>Редактировать аванс</q-item-label>
                </q-item-section>
              </q-item> -->
              <q-item clickable v-close-popup @click="onPrintButtonClick">
                <q-item-section>
                  <q-item-label>Печать</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="onEditDocument">
                <q-item-section>
                  <q-item-label>Проводки и редактирование</q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-if="is_active" clickable v-close-popup @click="onDeleteDocument">
                <q-item-section>
                  <q-item-label>Удалить</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-else clickable v-close-popup @click="onRestoreDocument">
                <q-item-section>
                  <q-item-label>Восстановить</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>

      </div>
    </div>
  </div>
  <div class="doc print_doc" id="print_content">
    <div>
      <div style="width: 100%; text-align: right; font-size: 6pt;">
        Приложение N 1 к постановлению Правительства Российской Федерации от 26 декабря 2011 г. N 1137<br />(в ред.
        Постановления Правительства РФ от 19.08.2017 No 981)
      </div>
      <div style="font-size: 10pt; line-height: 1.5;">
        <div style="font-weight: bold;">
          Счет-фактура на аванс № {{ body.in_org_number || "" }} от {{ formatDate(body.document_date || 0) }}
        </div>
        <div>
          Исправление № {{ body.change_number || "--" }} от {{ body.document_change_date || "--" }}
        </div>
      </div>
      <div style="font-size: 7pt; line-height: 1; margin-bottom: 3mm;">
        <div>
          Продавец: {{ body.seller || "" }}
        </div>
        <div>
          Адрес: {{ body.seller_adr || "" }}
        </div>
        <div>
          ИНН / КПП продавца: {{ body.seller_inn || "" }}
        </div>
        <div>
          Грузоотправитель и его адрес: -
        </div>
        <div>
          Грузополучатель и его адрес: -
        </div>
        <div>
          К платежно-расчетному документу: {{ body.plat_ras || "" }}
        </div>
        <div>
          Покупатель: {{ body.cust || "" }}
        </div>
        <div>
          Адрес: {{ body.cust_adr || "" }}
        </div>
        <div>
          ИНН / КПП покупателя: {{ body.cust_inn || "" }}
        </div>
        <div>
          Валюта: наименование, код: {{ body.currency || "" }}
        </div>
        <div>
          Идентификатор государственного контракта, договора (соглашения) (при наличии): {{ body.goscon || "" }}
        </div>
      </div>
    </div>

    <table class="main-table" cellspacing="0" cellpadding="3"
      style="font-size:7pt; line-height: 1; text-align: center;">
      <tbody>
        <tr>
          <td style="width: 60mm;" rowspan="2">Наименование товара (описание выполненных работ, оказанных услуг),
            имущественного права</td>
          <td rowspan="2" style="width: 15mm;">Код вида товара</td>
          <td colspan="2" style="width: 30mm;">Единица измерения</td>
          <td rowspan="2" style="width: 15mm;">Количество (объём)</td>
          <td rowspan="2" style="width: 15mm;">Цена (тариф) за единицу измерения</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав без налога — всего
          </td>
          <td rowspan="2" style="width: 10mm;">В том числе сумма акциза</td>
          <td rowspan="2" style="width: 15mm;">Налоговая ставка</td>
          <td rowspan="2" style="width: 15mm;">Сумма налога, предъявляемая покупателю</td>
          <td rowspan="2" style="width: 20mm;">Стоимость товаров (работ, услуг), имущественных прав с налогом — всего
          </td>
          <td colspan="2" style="width: 30mm;">Страна происхождения товара</td>
          <td rowspan="2" style="width: 30mm;">Регистрационный номер декларации</td>
        </tr>
        <tr>
          <td style="width: 10mm;">Код</td>
          <td style="width: 20mm;">Условное обозначение (национальное)</td>
          <td style="width: 10mm;">Цифровой код</td>
          <td style="width: 20mm; border-right: none;">Краткое наименование</td>
        </tr>
        <tr>
          <td style="text-align: center; font-size: 6pt">1</td>
          <td style="text-align: center; font-size: 6pt">1а</td>
          <td style="text-align: center; font-size: 6pt">2</td>
          <td style="text-align: center; font-size: 6pt">2а</td>
          <td style="text-align: center; font-size: 6pt">3</td>
          <td style="text-align: center; font-size: 6pt">4</td>
          <td style="text-align: center; font-size: 6pt">5</td>
          <td style="text-align: center; font-size: 6pt">6</td>
          <td style="text-align: center; font-size: 6pt">7</td>
          <td style="text-align: center; font-size: 6pt">8</td>
          <td style="text-align: center; font-size: 6pt">9</td>
          <td style="text-align: center; font-size: 6pt">10</td>
          <td style="text-align: center; font-size: 6pt">10а</td>
          <td style="text-align: center; font-size: 6pt">11</td>
        </tr>
        <tr v-for="item in body.table_rows" v-bind:key="item.id">
          <td style="text-align: left;">{{ item.name }}</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">-</td>
          <td style="text-align: right;">{{ item.nds.label || "" }} </td>
          <td style="text-align: right;">{{ Math.round(((item || { nalog: 0 }).nalog) * 100) / 100 || "" }} </td>
          <td style="text-align: right;">{{ Math.round(((item || { summtotal: 0 }).summtotal) * 100) / 100 || "" }}</td>
          <td style="border-bottom: 0.4mm solid black !important;">-</td>
          <td style="border-bottom: 0.4mm solid black !important;">-</td>
          <td style="text-align: left; border-bottom: 0.4mm solid black !important;">-</td>
        </tr>
        <tr style="border-bottom: transparent !important; border-right: transparent !important;">

          <td colspan="6" style="font-weight: bold; text-align: left;">Всего к оплате</td>
          <td style="text-align: right;">-</td>
          <td colspan="2">X</td>
          <td style="text-align: right;">{{ Math.round((body.summary || { nalog: 0 }).nalog * 100) / 100 }}</td>
          <td style="text-align: right;">{{ Math.round((body.summary || { summtotal: 0 }).summtotal * 100) / 100 }}</td>
          <td colspan="3" style="border: 0px solid white; border-left: 0.4mm solid black !important;"></td>
        </tr>
      </tbody>
    </table>

    <div style=" height: 10mm; margin-top: 3mm; vertical-align: top; font-size: 7pt;">
      <div style="display: inline-block; width: 40mm; height: 10mm;">
        Руководитель организации или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">

        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          Подпись
        </div>
      </div>
      <div style="display: inline-block; width: 50mm; margin-left: 7mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.rukvod || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (Ф.И.О.)
        </div>
      </div>
      <div style="display: inline-block; width: 35mm; height: 10mm; margin-left: 8mm;">
        Главный бухгалтер или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">

        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          Подпись
        </div>
      </div>
      <div style="display: inline-block; width: 53mm; margin-left: 7mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.glavbuh || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (Ф.И.О.)
        </div>
      </div>
    </div>
    <div style="height: 10mm; margin-top: 5mm; font-size: 7pt;">
      <div style="display: inline-block; width: 40mm; height: 10mm;">
        Индивидуальный предприниматель или иное уполномоченное лицо
      </div>
      <div style="display: inline-block; width: 35mm; margin-left: 3mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">

        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          Подпись
        </div>
      </div>
      <div style="display: inline-block; width: 50mm; margin-left: 7mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.ip_name || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (Ф.И.О.)
        </div>
      </div>

      <div style="display: inline-block; width: 133mm; margin-left: 8mm; text-align: center;">
        <dir style="border-bottom: 0.4mm solid black; height: 7mm;">
          {{ body.ip_req || "" }}
        </dir>
        <div style=" height: 3mm; font-size: 5pt;">
          (реквизиты свидетельства о государственной регистрации индивидуального предпринимателя)
        </div>
      </div>
    </div>

  </div>
  <div class="row" style="margin-top: 54px;">
    <div class="col">
      <q-select style="max-width: 350px;" :options="quarter_years_options" v-model="nds_period"
        @update:model-value="(x) => updateNdsStateForDocument(x.value)" label="Отразить в НДС за период" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { document } from "postcss";




const style = "<style>" +
  "@page {size: 297mm 210mm; margin: 10mm 10mm 10mm 10mm;}" +
  "body {margin: 0px;  width: 100%;  height: 100%;  background: #FFF;}" +
  ".print_doc {font-family: 'Times New Roman', Times, serif; font-size: 7pt;}" +
  ".main-table td { border: 0.3mm solid black;}" +
  "</style>";

export default {
  name: "document_info_factura_avance",
  data() {
    this.emitter.on('onDeleteDocument', (data) => {
      if (data.error !== undefined) {
        if (data.error === 'access_denied') {
          this.$q.notify('Недостаточно прав');
          return;
        }
      }
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$route.params.id
      );
    });

    this.emitter.on('onRestoreDocument', (data) => {
      if (data.error !== undefined) {
        if (data.error === 'access_denied') {
          this.$q.notify('Недостаточно прав');
          return;
        }
      }
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$route.params.id
      );
    });
    this.emitter.on('didReceiveDocumentInfo', (response) => {
      this.name = response.data.name;
      this.body = response.data.body;
      this.document_type = response.data.document_type;
      this.updateQuarterYearsOptions();
      this.is_in_nds = response.data.is_in_nds;
      this.is_active = response.data.is_active;
      this.direction = response.data.direction;
      this.page_title = response.data.name;
      const nds_period_index = this.quarter_years_options.findIndex(x => x.value == response.data.nds_period);
      if (nds_period_index >= 0) {
        this.nds_period = this.quarter_years_options[nds_period_index];
      }
    });
    this.emitter.on('onAcceptAvanceFromFactura', (data) => {
      if (data.error !== undefined) {
        if (data.error === 'access_denied') {
          this.$q.notify('Недостаточно прав');
          return;
        }
      }
      const document_id = data.document_id;
      this.$router.push({ name: 'document_edit_factura_avance', params: { id: document_id } });
    });
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentInfo(
        '1',
        this.$route.params.id,
        this.$route.params.history_id
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentInfo(
          '1',
          this.$route.params.id,
          this.$route.params.history_id
        );
      })
    }
    return {
      name: ref(''),
      body: ref({}),
      document_id: ref(this.$route.params.id),
      is_active: ref(true),
      is_in_nds: ref(false),
      quarter_years_options: ref([]),
      nds_period: ref(null),
      page_title: ref(null),
      direction: ref('in'),
      document_type: ref(null),
    }
  },
  methods: {
    updateQuarterYearsOptions() {
      const years = [
        { value: '2025', label: '2025 года' },
        { value: '2024', label: '2024 года' },
        { value: '2023', label: '2023 года' },
        { value: '2022', label: '2022 года' },
      ];
      const quarters = [
        { value: 1, label: '1 квартал' },
        { value: 2, label: '2 квартал' },
        { value: 3, label: '3 квартал' },
        { value: 4, label: '4 квартал' },
      ]
      var options = [{ value: null, label: 'Не учтен' }];
      const current_year = String(moment().year());
      const current_quarter = moment().quarter();
      years.forEach(year => {
        quarters.forEach(quarter => {
          if (year.value === current_year) {
            if (quarter.value <= current_quarter) {
              options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
            }
          } else {
            options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
          }
        });
      });
      this.quarter_years_options = options;

    },
    updateNdsStateForDocument(value) {
      this.connection.updateDocumentNdsState(
        '1',
        this.$route.params.id,
        value
      )
    },
    formatDate(date) {
      return moment.unix(date).format('DD.MM.YYYY');
    },
    onPrintButtonClick() {
      let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

      mywindow.document.write(`<html><head><title></title>`);
      mywindow.document.write('</head><body >' + style);
      mywindow.document.write(document.getElementById('print_content').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    },
    onEditDocument() {
      this.$router.push({ name: 'document_edit_factura_avance', params: { id: this.$route.params.id } });
    },
    onDeleteDocument() {
      this.connection.deleteDocument(
        '1',
        this.$route.params.id
      )
    },
    onRestoreDocument() {
      this.connection.restoreDocument(
        '1',
        this.$route.params.id
      )
    },
    onAcceptAvance() {
      this.connection.acceptAvanceFromFactura(
        '1',
        this.$route.params.id
      )
    },
    onEditAvance() {
      this.$router.push({ name: 'document_edit_factura_avance', params: { id: this.body.avance_id } });
    }
  }
}
</script>

<style scoped>
@page {
  size: 297mm 210mm;
  margin: 10mm 10mm 10mm 10mm;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: #FFF;
}

.print_doc {
  font-family: 'Times New Roman', Times, serif;
  font-size: 7pt;
}

.main-table td {
  border-collapse:collapse;
  border: 0.4mm solid black;
  border-bottom: none;
  border-right: none;
}
.main-table tr:last-child td {
    border-bottom: 1px solid #000;
}

.main-table tr td:last-child {
    border-right: 1px solid #000;
}
</style>