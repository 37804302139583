<template>
  <q-layout view="hHh LpR fFf">
    <q-drawer v-model="leftDrawerOpen" :width="expanded ? 240 : 72" :mini="!expanded" :breakpoint="500" bordered
      content-class="bg-white">
      <div class="erp-menu-container">
        <q-scroll-area class="erp-menu-scroll">
          <q-list padding>
            <q-item-label header>Организации</q-item-label>
            <div v-for="org in organisations" :key="org.id">
              <q-item v-ripple clickable @click="onSelectOrg(org.id)">
                <q-item-section avatar>
                  <q-avatar size="24px" color="primary" text-color="white">
                    {{ (org.short_name || "??").slice(0, 2) }}
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ org.short_name }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-icon v-show="selectedOrgId == org.id" name="checkmark" />
                </q-item-section>
              </q-item>
            </div>
            <div v-for="link in links" :key="link.title">
              <q-item-label v-if="link.category" header>{{ link.title }}</q-item-label>
              <q-item v-else v-ripple clickable @click="onMenuClick(link)" active-class="erp-menu-link-active"
                class="erp-menu-link">
                <q-item-section avatar>
                  <q-icon :name="link.icon" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ link.title }}</q-item-label>
                </q-item-section>
              </q-item>
            </div>

          </q-list>
        </q-scroll-area>

        <!-- Bottom Section -->
        <div class="erp-menu-bottom">
          <q-item clickable v-ripple @click="toggleExpanded" class="erp-menu-link">
            <q-item-section avatar>
              <q-icon :name="expanded ? 'chevron_left' : 'chevron_right'" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ expanded ? 'Свернуть' : 'Развернуть' }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple class="erp-menu-link" @click="$router.push({ name: 'profile', params: {} })">
            <q-item-section avatar>
              <q-avatar size="24px" color="primary" text-color="white">
                {{ (display_name || "??").slice(0, 2) }}
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Профиль</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="logout" class="erp-menu-link">
            <q-item-section avatar>
              <q-icon name="logout" color="negative" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-negative">Выйти</q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </div>
      <q-inner-loading :showing="loadOrganisationData">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-drawer>

    <q-page-container>
      <div class="q-pa-md">
        <router-view />
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";

const links = [
  
];

export default {
  name: "dashboard",
  data() {

    this.emitter.on('didReceiveAuthFail', (data) => {
      console.log(data);
      this.connection.logout(this.$q);
      this.$router.push({ name: 'signin', params: {} })
    });
    if (!this.connection.makeConnection(this.$q)) {
      this.$router.push({ name: 'signin', params: {} })
    }

    this.emitter.on('didReceiveUserInfo', (data) => {
      console.log(data, "asdasdfasdasdfsad", this.connection.selectedOrgId(this.$q));
      this.user_info = data;
      this.email = data.email;
      this.display_name = data.display_name;
      this.organisations = data.organisations;
      if (this.connection.selectedOrgId(this.$q) === null) {
        if (data.organisations.length > 0) {
          this.connection.updateOrgId(this.$q, data.organisations[0].id);
        }
      }

      this.connection.getUserInfoAcrossOrg(
        '1'
      );
      this.selectedOrgId = this.connection.selectedOrgId(this.$q);
    });
    this.emitter.on('accessDeniedErrorReceived', (data) => {
      console.log(data);
      this.$q.loading.hide();
      this.$q.notify({
        message: 'Нет прав на совершение этого действия. Обратитесь к администратору вашей организации.',
        color: 'negative',
        icon: 'shield',
        group: 'access_denied',
        progress: true,
        closeBtn: 'Ок',
        multiline: true,
        position: 'bottom'
      })
    })
    this.emitter.on('notifyRoleUpdated', (response) => {
      const role = response.data;
      const org = response.organisation;
      if (org.id !== parseInt(this.connection.selectedOrgId(this.$q))) { 
        return;
      } else {
        if (role !== undefined) {
          this.edit_org = role.unit_info.permissions.edit_org;
          this.change_billing = role.unit_info.permissions.change_billing;
          this.open_fin_documents = role.unit_info.permissions.open_fin_documents;
          this.edit_fin_documents = role.unit_info.permissions.edit_fin_documents;
        } else {
          this.edit_org = false;
          this.change_billing = false;
          this.open_fin_documents = false;
          this.edit_fin_documents = false;
        }
        this.org_name = org.name || "";
        this.updateLeftMenu();
        this.selectedOrgId = this.connection.selectedOrgId(this.$q);
      }
      this.$q.notify*({
        message: 'Ваши права были изменены',
        color: 'info',
        icon: 'shield'
      })
    })
    this.emitter.on('didReceoveUserInfoAcrossOrg', (data) => {
      this.user_info = data.user;
      this.loadOrganisationData = false;
      if (data.user !== undefined) {
        if (data.user.role !== undefined) {
          this.edit_org = data.user.role.permissions.edit_org;
          this.change_billing = data.user.role.permissions.change_billing;
          this.open_fin_documents = data.user.role.permissions.open_fin_documents;
          this.edit_fin_documents = data.user.role.permissions.edit_fin_documents;
        } else {
          this.edit_org = false;
          this.change_billing = false;
          this.open_fin_documents = false;
          this.edit_fin_documents = false;
        }
      } else {
        this.edit_org = false;
        this.change_billing = false;
        this.open_fin_documents = false;
        this.edit_fin_documents = false;
      }
      this.org_name = data.organisation_metadata.name || "";
      this.updateLeftMenu()
    });
    // if (this.connection.isAuthenticated) {
      
    // } else {
    //   this.emitter.on('connectionReady', () => {

    //   });
    // }
    return {
      user_info: ref({}),
      organisations: ref([]),
      edit_org: ref(false),
      change_billing: ref(false),
      open_fin_documents: ref(false),
      edit_fin_documents: ref(false),
      rightDrawerOpen: ref(false),
      leftDrawer: ref(false),
      miniState: ref(false),
      email: ref(''),
      display_name: ref(''),
      leftDrawerOpen: ref(true),
      expanded: ref(true),
      links: ref(links),
      org_name: ref(''),
      selectedOrgId: ref(this.connection.selectedOrgId(this.$q)),
      loadOrganisationData: ref(true),
    }
  },
  methods: {
    routeToHome() {
      console.log(this.user_info)
      if (this.organisations.length > 0) {
        if (this.can_open_org_structure) {
          this.$router.push({ name: 'org_main', params: {} });
        } else if (this.can_open_finances) {
          this.$router.push({ name: 'bills_plan', params: {} });
        } else {
          this.$router.push({ name: 'profile', params: {} });
        }

      } else {
        this.$router.push({ name: 'profile', params: {} });
      }
    },
    didAuthenticate() {
      console.log("emitted")
    },
    updateSocket(token) {
      console.log(this.$socket, this.socket, token);
    },
    updateLeftMenu() {
      this.links = [];
      if (this.edit_org || this.change_billing) {
        this.links.push({ category: true, title: 'Управление', icon: '', value: '' });
      }
      if (this.edit_org) {
        this.links.push({ category: false, title: 'Структура организации', icon: 'people', value: 'org_main' });
      }
      if (this.change_billing) {
        this.links.push({ category: false, title: 'Тарифный план', icon: 'payments', value: 'tarif_plan' });
      }
      if (this.open_fin_documents || this.edit_fin_documents) {
        this.links.push({ category: true, title: 'Бухгалтерия', icon: '', value: '' });
      }
      // if (this.edit_fin_documents) {
      //   this.links.push({ category: false, title: 'Обзор финансов', icon: 'home', value: 'finance_overview' })
      // }
      if (this.open_fin_documents) {
        this.links.push({ category: false, title: 'Документы', icon: 'folder_copy', value: 'documents_list' })
      }
      if (this.edit_fin_documents) {
        this.links.push({ category: false, title: 'ОСВ', icon: 'assessment', value: 'bill_saldo' })
        this.links.push({ category: false, title: 'План счетов', icon: 'difference', value: 'bills_plan' })
        this.links.push({ category: false, title: 'Акт сверки', icon: 'rule_folder', value: 'act_check_master' })
        this.links.push({ category: false, title: 'Платежные поручения', icon: 'request_quote', value: 'payments_list' })
        this.links.push({ category: false, title: 'Отчеты', icon: 'receipt', value: 'reports_list' })
        this.links.push({ category: false, title: 'Настройки бухгалтерии', icon: 'settings', value: 'org_profile_buh_automatic' })
      }
    },
    onMenuClick(item) {
      this.$router.push({ name: item.value, params: {} });
      // Here you would typically use vue-router to navigate
      // For example: router.push({ name: title.toLowerCase() })
    },
    logout() {
      localStorage.clear();
      this.$store.commit('clearStore');
      this.connection.logout(this.$q);
      this.$router.push({ name: 'signin', params: {} });
    },
    toggleExpanded () {
      this.expanded = !this.expanded
    },
    onSelectOrg(org_id) {
      this.loadOrganisationData = true;
      this.connection.updateOrgId(this.$q, org_id);
      this.selectedOrgId = org_id;
      this.connection.getUserInfoAcrossOrg('1');
      this.$router.push({ name: 'home', params: {} });
    }
  }
}
</script>

<style lang="scss">
.q-drawer {
  transition: width 0.3s;
  background-color: #ffffff;
  overflow-x: hidden;
}

.erp-menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.erp-menu-logo {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.erp-menu-scroll {
  flex-grow: 1;
  overflow-x: hidden;
}

.erp-menu-link {
  border-radius: 8px;
  margin-bottom: 4px;
  transition: all 0.3s;

  .q-item__section--avatar {
    min-width: 56px;
  }

  .q-item__label {
    font-size: 14px;
  }

  .q-icon {
    font-size: 20px;
  }
}

.erp-menu-link-active {
  color: #1976D2;
  background: rgba(25, 118, 210, 0.1);

  .q-icon {
    color: #1976D2;
  }
}

.q-drawer--mini {
  .erp-menu-link {
    justify-content: center;
    padding: 8px;
  }

  .q-item__section--main,
  .q-item__label {
    display: none;
  }

  .q-item__section--avatar {
    min-width: auto;
  }
}

// Ensure proper alignment in expanded mode
.q-drawer:not(.q-drawer--mini) {
  .erp-menu-link {
    padding-left: 16px;
    justify-content: flex-start;
  }

  .q-item__section--avatar {
    min-width: 40px;
    padding-right: 16px;
  }
}

// Remove scrollbars while allowing scrolling
.q-scrollarea__thumb {
  display: none !important;
}

// Ensure consistent width for scroll area content
.q-scrollarea__content {
  width: 100% !important;
}
</style>
