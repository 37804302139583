<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el label="План счетов" @click="$router.push({ name: 'bills_plan', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="row">
    <div class="col">
      <div class="q-ma-md text-h5 row">
        <div class="col">
          {{ page_title }}
        </div>
      </div>
      <q-table :rows="table_rows" :columns="table_columns" row-key="name" flat wrap-cells="" separator="cell"
        :pagination="tablePagination" @row-click="onRowClick" v-model="table_rows" :loading="isUpdatingData"
        loading-label="Обновление данных">
        <template v-slot:header="props">
          <q-tr>
            <q-th colspan="3" />
            <q-th colspan="2">
              Дебет
            </q-th>
            <q-th colspan="2">
              Кредит
            </q-th>
            <q-th colspan="1">

            </q-th>
          </q-tr>

          <q-tr :props="props">
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
          <q-tr>
            <q-th class="text-left" colspan="3">Сальдо на начало</q-th>
            <q-th colspan="2" class="text-right text-bold">
              <div class="text-right vertical-top">
                {{ summary.saldo_debet_start }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ summary.saldo_debet_start_count }}
              </div>
            </q-th>
            <q-th colspan="2" class="text-right text-bold">
              <div class="text-right vertical-top">
                {{ summary.saldo_credit_start }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ summary.saldo_credit_start_count }}
              </div>
            </q-th>
            <q-th colspan="1" class="text-left text-bold"></q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-for="col in props.cols" :key="col.name" :props="props">
              <div v-if="col.name == 'name'">
                <div class="text-black vertical-top">
                  {{ col.value }}
                </div>
                <div class="text-grey text-weight-regular vertical-bottom">
                  Кол-во:
                </div>
              </div>
              <div v-else-if="col.name == 'bill_to_saldo' && props.row.bill_to_saldo != 0">
                <div class="text-right vertical-top">
                  {{ Math.abs(col.value) }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ props.row.count }}
                </div>
              </div>
              <div v-else-if="col.name == 'bill_from_saldo' && props.row.bill_from_saldo != 0">
                <div class="text-right vertical-top">
                  {{ Math.abs(col.value) }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ props.row.count }}
                </div>
              </div>
              <div v-else-if="col.name == 'current_saldo'">
                <div class="text-right vertical-top">
                  {{ col.value }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ props.row.current_saldo_count }}
                </div>
              </div>
              <div v-else>
                {{ col.value }}
              </div>
            </q-td>
          </q-tr>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-th colspan="3" class="text-left text-bold">
              Обороты за период
            </q-th>
            <q-th colspan="1" class="text-left text-bold"></q-th>
            <q-th colspan="1" class="text-right text-bold">{{ summary.debet_end }}</q-th>
            <q-th colspan="1" class="text-left text-bold"></q-th>
            <q-th colspan="1" class="text-right text-bold">{{ summary.credit_end }}</q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="3" class="text-left text-bold">
              Сальдо на конец
            </q-th>
            <q-th colspan="2" class="text-right text-bold">
              <div class="text-right vertical-top">
                {{ summary.saldo_debet_end }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ summary.saldo_debet_end_count }}
              </div>
            </q-th>
            <q-th colspan="2" class="text-right text-bold">
              <div class="text-right vertical-top">
                {{ summary.saldo_credit_end }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ summary.saldo_credit_end_count }}
              </div>
            </q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="9" style="border: 2px black" />
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="col-2 q-pa-md">
      <q-date v-model="period_range.from" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({ from: newValue, to: period_range.to }) }" />
      <q-date v-model="period_range.to" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({ from: period_range.from, to: newValue }) }" />
      <div v-if="period_range == null">
        Период не установлен
      </div>
      <div v-else>
        Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
      </div>
    </div>
  </div>
  <q-page-sticky position="bottom-right" :offset="[18, 18]">
    <q-fab icon="add" direction="left" color="primary">
      <q-fab-action @click="$router.push({ name: 'document_create_upd', params: {} })" color="primary" label="УПД" />
      <q-fab-action @click="$router.push({ name: 'document_create_factura_avance', params: {} })" color="primary"
        label="Счет-фактура на аванс" />
    </q-fab>
  </q-page-sticky>
</template>

<script>
import {ref} from "vue";
import moment from "moment";


const table_columns = [
  { name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true},
  { name: 'document', align: 'left', label: 'Документ', field: 'document', sortable: true},
  { name: 'name', align: 'left', label: 'Операция', field: 'name', sortable: true},
  // { name: 'count', align: 'left', label: 'Количество', field: 'count', sortable: true},
  { name: 'bill_to', align: 'left', label: 'Счет', field: 'bill_to'},
  { name: 'bill_to_saldo', align: 'left', label: 'Сумма', field: 'bill_to_saldo'},
  { name: 'bill_from', align: 'left', label: 'Счет', field: 'bill_from'},
  { name: 'bill_from_saldo', align: 'left', label: 'Сумма', field: 'bill_from_saldo'},
  { name: 'current_saldo', align: 'left', label: 'Текущее сальдо', field: 'current_saldo'},
]

export default {
  name: "bill_card",
  data() {
    this.emitter.on('didReceiveTransfersListByBillCard', (data) => {
      console.log('gggggggg', data.data.transfers);
      this.table_rows = data.data.transfers;
      this.summary = data.data.summary;
      this.isUpdatingData = false;
      this.bill_title = data.data.bill.full_name;
      this.page_title = "Карточка счета " + data.data.bill.full_name;
    });
    const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
    const period_end = moment().endOf('day');
    const period_start_unix = period_start.unix();
    const period_end_unix = period_end.unix();
    var range = {
      from: moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"),
      to: moment().endOf('day').format("YYYY/MM/DD")
    };
    if (this.connection.isAuthenticated) {
      this.connection.getTransfersByBillCardsList(
        '1',
        this.$route.params.id,
        moment(range.from).endOf('day').unix(),
        moment(range.to).endOf('day').unix(),
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getTransfersByBillCardsList(
          '1',
          this.$route.params.id,
          moment(range.from).endOf('day').unix(),
          moment(range.to).endOf('day').unix(),
        );
      })
    }
    const summary = {
      saldo_debet_end: 0,
      saldo_credit_end: 0,
      debet_end: 0,
      credit_end: 0,
      saldo_debet_start: 0,
      saldo_credit_start: 0,
    };
    return {
      isUpdatingData: ref(true),
      table_rows: ref([]),
      summary: ref(summary),
      period_start_unix: ref(period_start_unix),
      period_end_unix: ref(period_end_unix),
      bill_title: ref(''),
      transfer_to_edit: ref(null),
      period_range: ref(range),
      table_columns: ref(table_columns),
      page_title: ref(null),
    }
  },
  methods: {
    showEditTransferDialog(transfer_id) {
      this.transfer_to_edit = transfer_id;
      this.edit_transfer_info = true;
    },
    updateFilterStartProxy () {
      this.period_start_proxy = this.period_start
    },
    updateFilterEndProxy () {
      this.period_start_proxy = this.period_start
    },
    filterSaveStartProxy() {
      this.period_start = this.period_start_proxy;
      this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
      this.filterSave();
    },
    filterSaveEndProxy() {
      this.period_end = this.period_end_proxy;
      this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
      this.filterSave();
    },
    onRowClick(event, row) {
      console.log(row);
    },
    onSubRowClick(event, row) {
      console.log(row);
    },
    updateTableFilter() {
      this.table_rows = this.bills_list.filter(this.tableRowFilter)
    },
    onPeriodChange(model_value) {
      console.log(model_value);
      if (model_value == null) {
        return;
      }
      this.isUpdatingData = true;
      if (this.connection.isAuthenticated) {
        this.connection.getTransfersByBillCardsList(
          '1',
          this.$route.params.id,
          moment(model_value.from).endOf('day').unix(),
          moment(model_value.to).endOf('day').unix(),
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getTransfersByBillCardsList(
            '1',
            this.$route.params.id,
            moment(model_value.from).endOf('day').unix(),
            moment(model_value.to).endOf('day').unix(),
          );
        })
      }
    },
    prettyDatePrint(date) {
      return moment(date).format('DD MMMM YYYY');
    }

  },
}
</script>

<style scoped>

</style>