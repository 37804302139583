<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el label="Список отчетов" @click="$router.push({ name: 'reports_list', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col">
			<div style="max-width: 350px;">
				<q-select label="Год" :options="year_options" v-model="selected_year"
					@update:model-value="(x) => onChangeYear(x)" />
				<q-select label="Квартал" :options="quarter_options" v-model="selected_quarter"
					@update:model-value="(x) => onChangeQuarter(x)" />
			</div>

			<div>
				Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
			</div>
			<div class="q-ma-sm">
				<q-btn flat icon="download" label="Книга покупок" @click="onSaveSell" />
				<q-btn flat icon="download" label="Книга продаж" @click="onSaveBuy" />
				<q-btn icon="download" color="primary" label="Сформировать" @click="onSave" />
			</div>

		</div>
		<div class="col">
			<q-list bordered padding style="max-width: 500px;">
				<q-item-label header>НДС за период {{ selected_period }}</q-item-label>

				<q-item clickable v-ripple>
					<q-item-section>
						<q-item-label>Сумма к уплате</q-item-label>
						<q-item-label caption>
							Сумма налога к уплате за выбранный период
						</q-item-label>
					</q-item-section>
					<q-item-section side>
                        <q-item-label><q-chip>{{ nds_summ_calc }} руб.</q-chip></q-item-label>
                    </q-item-section>
				</q-item>
			</q-list>
		</div>
	</div>
	<div class="row">
		<div class="col q-pa-sm">

			<q-table bordered dense wrap-cells title="Входящие документы" :rows="documents_list_incoming"
				:columns="table_columns" row-key="name" flat separator="cell" :pagination="tablePagination"
				@row-click="onRowClick" v-model="documents_list_incoming" :loading="isUpdatingDataIn">
				<template v-slot:body-cell-nds_period="props">
					<q-td :props="props">
						<q-select :options="quarter_years_options"
							v-model="documents_list_incoming[props.rowIndex].nds_period"
							@update:model-value="(x) => onChangeNdsStateFor(props.row.id, x.value)" />
					</q-td>
				</template>
			</q-table>
		</div>
		<div class="col q-pa-sm">

			<q-table bordered dense wrap-cells title="Исходящие документы" :rows="documents_list_outgoing"
				:columns="table_columns" row-key="name" flat separator="cell" :pagination="tablePagination"
				@row-click="onRowClick" v-model="documents_list_outgoing" :loading="isUpdatingDataOut">
				<template v-slot:body-cell-nds_period="props">
					<q-td :props="props">
						<q-select :options="quarter_years_options"
							v-model="documents_list_outgoing[props.rowIndex].nds_period"
							@update:model-value="(x) => onChangeNdsStateFor(props.row.id, x.value)" />
					</q-td>
				</template>
			</q-table>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const table_columns = [
	{ name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
	{ name: 'name', align: 'left', label: 'Название', field: 'name' },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
	{ name: 'nds_period', align: 'left', label: 'Период НДС', field: 'nds_period', sortable: true },
	{ name: 'summ_nds', align: 'left', label: 'Сумма НДС', field: 'summ_nds', sortable: true },
]

const year_options = [
	{ value: '2025', label: '2025' },
	{ value: '2024', label: '2024' },
	{ value: '2023', label: '2023' },
	{ value: '2022', label: '2022' },
];
const quarter_options = [
	{ value: 1, label: '1 квартал' },
	{ value: 2, label: '2 квартал' },
	{ value: 3, label: '3 квартал' },
	{ value: 4, label: '4 квартал' },
];

export default {
	name: "create_document_report_nds",
	data() {
		this.emitter.on('onUpdateDocumentNdsState', (data) => {
			console.log(data)
			this.isUpdatingDataIn = false;
			this.isUpdatingDataOut = false;
			this.updateRowData();
		});
		this.emitter.on('onGetNDSReportRawData', (response) => {
			this.$q.loading.hide();
            if (response.error!== undefined) {
                if (response.error === 'access_denied') {
                    this.$q.notify('Недостаточно прав');
                    return;
                }
            }
			this.nds_summ_calc = response.nalog_summ;
		})
		this.emitter.on('onGetNDSReport', (response) => {
			this.$q.loading.hide();
			if (response.error !== undefined) {
				if (response.error === 'access_denied') {
					this.$q.notify('Недостаточно прав');
					return;
				}
			}
			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = response.name
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		this.emitter.on('didReceiveDocumentsListNDS', (data) => {
			console.log(data)
			if (data.request_id === this.request_id_for_incoming) {
				this.documents_list_incoming = data.data;
				this.isUpdatingDataIn = false;
			} else if (data.request_id === this.request_id_for_outgoing) {
				this.documents_list_outgoing = data.data;
				this.isUpdatingDataOut = false;
			}

			const current_year = String(moment().year());
			const current_quarter = moment().quarter();
			if (this.selected_year.value === current_year) {
				this.quarter_options = quarter_options.filter(x => x.value <= current_quarter);
			} else {
				this.quarter_options = quarter_options;
			}
			this.updateRowData();
			this.updateQuarterYearsOptions();
		});
		this.emitter.on('onGetSellBook', (response) => {
			this.$q.loading.hide();
			if (response.error !== undefined) {
				if (response.error === 'access_denied') {
					this.$q.notify('Недостаточно прав');
					return;
				}
			}
			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = response.name
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		this.emitter.on('onGetBuysBook', (response) => {
			this.$q.loading.hide();
			if (response.error !== undefined) {
				if (response.error === 'access_denied') {
					this.$q.notify('Недостаточно прав');
					return;
				}
			}
			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = response.name
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		const quarter = moment().quarter();
		const current_quarter = String(moment().quarter());
		const current_year = String(moment().year());
		const period = current_quarter + current_year;
		const selected_quarter = quarter_options[quarter - 1];
		const start = moment().quarter(quarter).startOf('quarter');
		const end = moment().quarter(quarter).endOf('quarter');

		var range = { from: start, to: end };
		


		const current_pretty_period = current_quarter + ' квартал ' + current_year;

		var request_id_for_incoming = 'income';
		var request_id_for_outgoing = 'outcome';

		if (this.connection.isAuthenticated) {
			this.connection.getDocumentsListNDS(
				request_id_for_incoming,
				period,
				'in'
			);
			this.connection.getDocumentsListNDS(
				request_id_for_outgoing,
				period,
				'out'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentsListNDS(
					request_id_for_incoming,
					period,
					'in'
				);
				this.connection.getDocumentsListNDS(
					request_id_for_outgoing,
					period,
					'out'
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			audit: ref(false),
			document_date_unix: ref(0),
			document_date: ref(null),
			table_columns: ref(table_columns),
			documents_list_incoming: ref([]),
			documents_list_outgoing: ref([]),
			period_range: ref(range),
			isUpdatingDataIn: ref(true),
			isUpdatingDataOut: ref(true),
			quarter_options: quarter_options,
			year_options: year_options,
			selected_year: ref(year_options[0]),
			selected_quarter: ref(selected_quarter),
			page_title: ref('Отчет по НДС'),
			tablePagination: {
				sortBy: 'date',
				descending: true,
				page: 1,
				rowsPerPage: 50
			},
			quarter_years_options: ref([]),
			request_id_for_incoming: ref(request_id_for_incoming),
            request_id_for_outgoing: ref(request_id_for_outgoing),
			selected_period: ref(current_pretty_period),
			nds_summ_calc: ref(0)
		}
	},
	methods: {
		updateQuarterYearsOptions() {
			const years = [
				{ value: '2025', label: '2025 года' },
				{ value: '2024', label: '2024 года' },
				{ value: '2023', label: '2023 года' },
				{ value: '2022', label: '2022 года' },
			];
			const quarters = [
				{ value: 1, label: '1 квартал' },
				{ value: 2, label: '2 квартал' },
				{ value: 3, label: '3 квартал' },
				{ value: 4, label: '4 квартал' },
			]
			var options = [{ value: null, label: 'Не учтен' }];
			const current_year = String(moment().year());
			const current_quarter = moment().quarter();
			years.forEach(year => {
                quarters.forEach(quarter => {
					if (year.value === current_year) {
						if (quarter.value <= current_quarter) {
							options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
						}
					} else {
						options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
					}
                });
            });
			this.quarter_years_options = options;
			
		},
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		onChangeYear(value) {
			const current_year = String(moment().year());
			const current_quarter = moment().quarter();
			if (value.value === current_year) {
				this.quarter_options = quarter_options.filter(x => x.value <= current_quarter);
				if (this.selected_quarter.value > current_quarter) {
					this.selected_quarter = quarter_options[current_quarter - 1];
				}
			} else {
				this.quarter_options = quarter_options;
			}
			const quarter = String(this.selected_quarter.value);
			const year = String(value.value);
			const period = quarter + year;
			this.selected_period = this.prettyPrintSelectedPeriod();
			this.isUpdatingDataIn = true
			this.isUpdatingDataOut = true
			this.connection.getDocumentsListNDS(
				this.request_id_for_incoming,
				period,
				'in'
			);
			this.connection.getDocumentsListNDS(
				this.request_id_for_outgoing,
				period,
				'out'
			);
		},
		onChangeQuarter(value) {
			const quarter = String(value.value);
			const year = String(this.selected_year.value);
			const period = quarter + year;
			this.selected_period = this.prettyPrintSelectedPeriod();
			this.isUpdatingData = true
			this.connection.getDocumentsListNDS(
				this.request_id_for_incoming,
				period,
				'in'
			);
			this.connection.getDocumentsListNDS(
				this.request_id_for_outgoing,
				period,
				'out'
			);
		},
		onChangeNdsStateFor(document_id, state) {
			// this.isUpdatingData = true;
			this.connection.updateDocumentNdsState(
				'1',
				document_id,
				state
			)
		},
		updateRowData() {
			const quarter = String(this.selected_quarter.value);
			const year = String(this.selected_year.value);
			const period = quarter + year;
			this.connection.getNDSReportRawData(
				'1',
				year,
				period,
				moment(this.period_range.from).endOf('day').unix(),
				moment(this.period_range.to).endOf('day').unix(),
			)
		},
		onSave() {
			// this.$q.loading.show();
			console.log(this.period_range.from.year())
			const quarter = String(this.selected_quarter.value);
			const year = String(this.selected_year.value);
			const period = quarter + year;
			this.selected_period = this.prettyPrintSelectedPeriod();
			console.log(year, period)
			this.$q.loading.show();
			this.connection.getNDSReport(
				'1',
				year,
				period,
				moment(this.period_range.from).endOf('day').unix(),
				moment(this.period_range.to).endOf('day').unix(),
			)
		},

		onSaveSell() {
			// this.$q.loading.show();
			console.log(this.period_range.from.year())
			const quarter = String(this.selected_quarter.value);
			const year = String(this.selected_year.value);
			const period = quarter + year;
			this.selected_period = this.prettyPrintSelectedPeriod();
			console.log(year, period)
			this.$q.loading.show();
			this.connection.getSellBookReport(
				'1',
				year,
				period,
				moment(this.period_range.from).endOf('day').unix(),
				moment(this.period_range.to).endOf('day').unix(),
			)
		},

		onSaveBuy() {
			// this.$q.loading.show();
			console.log(this.period_range.from.year())
			const quarter = String(this.selected_quarter.value);
			const year = String(this.selected_year.value);
			const period = quarter + year;
			this.selected_period = this.prettyPrintSelectedPeriod();
			console.log(year, period)
			this.$q.loading.show();
			this.connection.getBuysBookReport(
				'1',
				year,
				period,
				moment(this.period_range.from).endOf('day').unix(),
				moment(this.period_range.to).endOf('day').unix(),
			)
		},
		prettyDatePrint(date) {
			return moment(date).format('DD MMMM YYYY');
		},
		prettyPrintSelectedPeriod() {
			return this.selected_quarter.label + ' ' + this.selected_year.value
		}

	}
}
</script>

<style scoped>

</style>
