<template>
  <div class="text-h6">
    Шаблон проводки
  </div>
  <div style="width: 500px">
    <div class="q-pa-md">
      <q-select v-model="document_type" :options="document_type_options" @update:model-value="(newValue) => onDocumentTypeChange(newValue.value)" label="Тип документа" />
    </div>
    <div class="q-pa-md">
      <q-input v-model="name" label="Название" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="direction" :options="directions" label="Направление" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="field_id" :options="fields" label="Поле" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="bill_from" :options="bills" label="Счет списания" />
    </div>
    <div class="q-pa-md">
      <q-select v-model="bill_to" :options="bills" label="Счет зачисления" />
    </div>
    <div class="q-pa-md">
      <q-input v-model="comment" label="Комментарий" />
    </div>
    <div class="q-pa-md">
      <q-btn label="Сохранить" @click="addTemplate" color="primary" />
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

const directions = [
  { value: 'in', label: 'Входящий документ' },
  { value: 'out', label: 'Исходящий документ' }
]

const document_type_options = [
  { value: 'upd', label: 'УПД' },
  { value: 'avance', label: 'Счет-фактура на аванс' },
  { value: 'payment', label: 'Платежное поручение' },
];

const fields_upd = [
  { value: 'count', label: 'Количество' },
  { value: 'price', label: 'Цена' },
  { value: 'cost_without_nds', label: 'Стоимость до налогов' },
  { value: 'aciz', label: 'Акциз' },
  { value: 'nalog', label: 'Сумма налога, всего' },
  { value: 'summtotal', label: 'Сумма с налогом, всего' },
];

const fields_factura_avance = [
  { value: 'summtotal', label: 'Сумма всего' },
  { value: 'nalog', label: 'Сумма налога' },
];

const fields_payment = [
  { value: 'summ', label: 'Сумма' },
  { value: 'nds_summ', label: 'Сумма НДС' },
];

export default {
  name: "transfer_templates_new",
  data() {

    this.emitter.on('didReceiveBillListFlatCompact', (data) => {
      this.bills = data.bills.map((element) => {
        return {label: element.full_name, value: element.id}
      });
      this.bill_from = this.bills[0];
      this.bill_to = this.bills[1];
    });

    this.emitter.on('onCreateTransferTemplate', (response) => {
      console.log(response);
      this.$q.loading.hide();
      this.$router.push({ name: 'transfer_templates_list', params: {} })
    });
    if (this.connection.isAuthenticated) {
      this.connection.gitBillsListFlatCompact(
        '1'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.gitBillsListFlatCompact(
          '1'
        );
      })
    }
    
    return {
      name: ref(null),
      field_id: ref(null),
      bill_from: ref(null),
      bill_to: ref(null),
      direction: ref({ value: 'in', label: 'Входящий документ' }),
      directions: ref(directions),
      comment: ref(null),
      fields: ref(fields_upd),
      bills: ref([]),
      document_type_options: ref(document_type_options),
      document_type: ref(document_type_options[0])
    }
  },
  methods: {
    onDocumentTypeChange(document_type) {
      if (document_type == 'upd') {
        this.fields = ref(fields_upd);
      } else if (document_type == 'avance') {
        this.fields = ref(fields_factura_avance);
      } else if (document_type == 'payment') {
        this.fields = ref(fields_payment);
      }
      this.field_id = null;
    },
    addTemplate() {
      this.$q.loading.show();
      this.connection.createTransferTemplate(
        '1',
        this.name, 
        this.field_id.value, 
        this.bill_from.value, 
        this.bill_to.value, 
        this.direction.value,
        this.comment,
        this.document_type.value
      );
    }
  }
}
</script>

<style scoped>

</style>