<template>
  <router-view />
</template>

<script>

export default {
  name: "finance_summary",
  data() {

    return {
    }
  },
}
</script>

<style scoped lang="sass">

</style>
