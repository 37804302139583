<template>

	<div class="">
		<q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
			label="Печать" />
		<q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
			label="Редактирование" />
		<q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete"
			text-color="negative" label="Удалить" />
		<q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
			label="Восстановить" />
	</div>
	<div class="doc landscape upd" id="print_content" style="width: 182mm;">
		<h6 class="header" style="font-size: 16pt; width: 100%; text-align: center;">Отчет о целевом использовании
			средств {{ formatDate(body.date_current) }}г. </h6>
		<div style="width: 182mm; display: flex; flex-wrap: wrap;">
			<div style="display: inline; width: 120mm;">
				<p style="width: 100%; padding-right: 10mm">Организация: {{ body.organisation_name }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Идентификационный номер налогоплательщика: {{
					body.organisation_inn }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Вид экономической деятельности: {{
					body.organisation_econ }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Организационно-правовая форма/форма собственности: {{ body.organisation_form }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Единица измерения: {{ ((body.currency_measure || { label: '' }).label) }}
				</p>
			</div>
			<div style="display: inline; width: 60mm;">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"></td>
						<td colspan="2"></td>
						<td colspan="2"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"></td>
						<td colspan="3"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 37.5mm;">На {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 37.5mm;">На {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{{ body.mon_ost.desc }}
						</td>
						<td>Остаток средств на начало отчетного года
						</td>
						<td>{{ body.mon_ost.current }}</td>
						<td>{{ body.mon_ost.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.mon_inc_invite.desc }}
						</td>
						<td>
							<div style="width: 100%; text-align: center;">
								Поступило средств
							</div>
							<div style="width: 100%; text-align: left;">
								Вступительные взносы
							</div>
						</td>
						<td>{{ body.mon_inc_invite.current }}</td>
						<td>{{ body.mon_inc_invite.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.chlen_income.desc }}
						</td>
						<td>Членские взносы
						</td>
						<td>{{ body.chlen_income.current }}</td>
						<td>{{ body.chlen_income.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.cel_income.desc }}
						</td>
						<td>Целевые взносы</td>
						<td>{{ body.cel_income.current }}</td>
						<td>{{ body.cel_income.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.vol_mat_income.desc }}
						</td>
						<td>Добровольные имущественные взносы и пожертвования
						</td>
						<td>{{ body.vol_mat_income.current }}</td>
						<td>{{ body.vol_mat_income.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.income_actions.desc }}
						</td>
						<td>Прибыль от приносящей доход деятельности
						</td>
						<td>{{ body.income_actions.current }}</td>
						<td>{{ body.income_actions.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.another_income.desc }}
						</td>
						<td>Прочие
						</td>
						<td>{{ body.another_income.current }}</td>
						<td>{{ body.another_income.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_income.desc }}
						</td>
						<td>Всего поступило средств
						</td>
						<td>{{ body.total_income.current }}</td>
						<td>{{ body.total_income.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.outcome_cel.desc }}
						</td>
						<td>
							<div style="width: 100%; text-align: center;">
								Использовано средств
							</div>
							<div style="width: 100%; text-align: left;">
								Расходы на целевые мероприятия
							</div>
						</td>
						<td>{{ body.outcome_cel.current }}</td>
						<td>{{ body.outcome_cel.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.soc_help.desc }}
						</td>
						<td class="padded">в том числе:<br />социальная и благотворительная помощь</td>
						<td>{{ body.soc_help.current }}</td>
						<td>{{ body.soc_help.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.conf_outcome.desc }}
						</td>
						<td class="padded">проведение конференций, совещаний, семинаров и т.п.</td>
						<td>{{ body.conf_outcome.current }}</td>
						<td>{{ body.conf_outcome.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.another_conf.desc }}
						</td>
						<td class="padded">иные мероприятия</td>
						<td>{{ body.another_conf.current }}</td>
						<td>{{ body.another_conf.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.managers_sal.desc }}
						</td>
						<td>Расходы на содержание аппарата управления</td>
						<td>{{ body.managers_sal.current }}</td>
						<td>{{ body.managers_sal.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.salary.desc }}
						</td>
						<td class="padded">в том числе: <br />расходы, связанные с оплатой труда (включая начисления)
						</td>
						<td>{{ body.salary.current }}</td>
						<td>{{ body.salary.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.non_salary.desc }}
						</td>
						<td class="padded">выплаты, не связанные с оплатой труда</td>
						<td>{{ body.non_salary.current }}</td>
						<td>{{ body.non_salary.previous }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="pagebreak"> </div>
		<div class="section">
			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 37.5mm;">На {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 37.5mm;">На {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{{ body.com_drive.desc }}
						</td>
						<td class="padded">расходы на служебные командировки и деловые поездки</td>
						<td>{{ body.com_drive.current }}</td>
						<td>{{ body.com_drive.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.household.desc }}
						</td>
						<td class="padded">содержание помещений, зданий, автомобильного транспорта и иного имущества
							(кроме ремонта)
						</td>
						<td>{{ body.household.current }}</td>
						<td>{{ body.household.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.repair.desc }}
						</td>
						<td class="padded">ремонт основных средств и иного имущества</td>
						<td>{{ body.repair.current }}</td>
						<td>{{ body.repair.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.anouther_outcome.desc }}
						</td>
						<td class="padded">прочие</td>
						<td>{{ body.anouther_outcome.current }}</td>
						<td>{{ body.anouther_outcome.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.inventary.desc }}
						</td>
						<td>Приобретение основных средств, инвентаря и иного имущества</td>
						<td>{{ body.inventary.current }}</td>
						<td>{{ body.inventary.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.another.desc }}
						</td>
						<td>Прочие</td>
						<td>{{ body.another.current }}</td>
						<td>{{ body.another.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_used.desc }}
						</td>
						<td>Всего использовано средств</td>
						<td>{{ body.total_used.current }}</td>
						<td>{{ body.total_used.previous }}</td>
					</tr>
					<tr>
						<td>
							{{ body.remainder.desc }}
						</td>
						<td>Остаток средств на конец отчетного года</td>
						<td>{{ body.remainder.current }}</td>
						<td>{{ body.remainder.previous }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

var style = "<style>@page{size: A4;margin: 0}.doc{margin: 0;padding: 0;font-family: 'Times New Roman', Times, serif;font-size: 12px;background: white;color: black;width: 210mm;padding: 20mm;box-sizing: border-box;display: block;margin: auto;position: relative}.header{text-align: center}.section{margin: 20px 0}table{width: 100%;border-collapse: collapse}table,th,td{border: 0.3mm solid black;padding: 0px 4px;text-align: left}.padded{padding-left: 5mm}.bold{font-weight: bold}.right-align{text-align: right}.signature{margin-top: 20px;text-align: right}@media print{@page{size: A4;margin: 20mm}body{margin: 0;font-size: 12pt}table,th,td{border: 1px solid black;padding: 5px}.header{margin-top: 0}.pagebreak{page-break-before: always}}</style>";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];
export default {
	name: "info_document_report_money_using",
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});

		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.show = true;
			this.is_active = response.data.is_active;
			this.name = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1'
				);
			})
		}
		return {
			show: ref(false),
			name: ref(''),
			body: ref({
				organisation_name: null,
				organisation_inn: null,
				organisation_econ: null,
				organisation_form: null,
				document_date_unix: 0,
				document_date: null,
				currency_measure_options: currency_measure_options,
				currency_measure: currency_measure_options[0],
				mon_ost: ref({ current: 0, previous: 0, desc: '' }),
				mon_inc_invite: ref({ current: 0, previous: 0, desc: '' }),
				chlen_income: ref({ current: 0, previous: 0, desc: '' }),
				cel_income: ref({ current: 0, previous: 0, desc: '' }),
				vol_mat_income: ref({ current: 0, previous: 0, desc: '' }),
				income_actions: ref({ current: 0, previous: 0, desc: '' }),
				another_income: ref({ current: 0, previous: 0, desc: '' }),
				total_income: ref({ current: 0, previous: 0, desc: '' }),
				outcome_cel: ref({ current: 0, previous: 0, desc: '' }),
				soc_help: ref({ current: 0, previous: 0, desc: '' }),
				conf_outcome: ref({ current: 0, previous: 0, desc: '' }),
				another_conf: ref({ current: 0, previous: 0, desc: '' }),
				managers_sal: ref({ current: 0, previous: 0, desc: '' }),
				salary: ref({ current: 0, previous: 0, desc: '' }),
				non_salary: ref({ current: 0, previous: 0, desc: '' }),
				com_drive: ref({ current: 0, previous: 0, desc: '' }),
				household: ref({ current: 0, previous: 0, desc: '' }),
				repair: ref({ current: 0, previous: 0, desc: '' }),
				anouther_outcome: ref({ current: 0, previous: 0, desc: '' }),
				inventary: ref({ current: 0, previous: 0, desc: '' }),
				another: ref({ current: 0, previous: 0, desc: '' }),
				total_used: ref({ current: 0, previous: 0, desc: '' }),
				remainder: ref({ current: 0, previous: 0, desc: '' }),
				date_current: 0,
				date_previous: 0,
				date_preprevious: 0
			}),
			is_active: ref(true),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null)
		}
	},
	methods: {
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				item_id,
				value,
				direction
			)
		},
		valueForTable(array, id, key) {
			return array[id][key].label || array[id][key].value
		},
		valueFor(array, key) {
			return array.find(item => item.field_id === key).value;
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			console.log('onPrintButtonClick');
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'reports_edit_money_using', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$route.params.id
			)
		}
	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.doc {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	width: 210mm;
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.padded {
	padding-left: 5mm;
}

.bold {
	font-weight: bold;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}

	.pagebreak {
		page-break-before: always;
	}
}
</style>