<template>

	<div class="">
		<q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
			label="Печать" />
		<q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
			label="Редактирование" />
		<q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete"
			text-color="negative" label="Удалить" />
		<q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
			label="Восстановить" />
	</div>
	<div class="doc landscape upd" id="print_content" style="width: 180mm;">
		<h6 class="header" style="font-size: 16pt; width: 100%; text-align: center;">Отчет о финансовых результатах за
			{{ formatDate(body.date_current) }}г. </h6>
		<div style="width: 182mm; display: flex; flex-wrap: wrap;">
			<div style="display: inline; width: 120mm;">
				<p style="width: 100%; padding-right: 10mm">Организация: {{ body.organisation_name }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Идентификационный номер налогоплательщика: {{
					body.organisation_inn }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Вид экономической деятельности: {{
					body.organisation_econ }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Организационно-правовая форма/форма собственности: {{ body.organisation_form }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Единица измерения: {{ ((body.currency_measure || { label: '' }).label) }}
				</p>
			</div>
			<div style="display: inline; width: 60mm;">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"></td>
						<td colspan="2"></td>
						<td colspan="2"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"></td>
						<td colspan="3"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 37.5mm;">За {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 37.5mm;">За {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{{ body.profit.desc }}
						</td>
						<td>Выручка
						</td>
						<td>{{ body.profit.current }} </td>
						<td>{{ body.profit.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.sebest.desc }}
						</td>
						<td>Себестоимость продаж
						</td>
						<td>({{ body.sebest.current }})</td>
						<td>({{ body.sebest.previous }})</td>
					</tr>
					<tr>
						<td>
							{{ body.val_profit.desc }}
						</td>
						<td>Валовая прибыль (убыток)
						</td>
						<td>{{ body.val_profit.current }}</td>
						<td>{{ body.val_profit.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.comm_costs.desc }}
						</td>
						<td>Коммерческие расходы</td>
						<td>({{ body.comm_costs.current }})</td>
						<td>({{ body.comm_costs.previous }})</td>
					</tr>
					<tr>
						<td>
							{{ body.man_costs.desc }}
						</td>
						<td>Управленческие расходы
						</td>
						<td>({{ body.man_costs.current }})</td>
						<td>({{ body.man_costs.previous }})</td>
					</tr>
					<tr>
						<td>
							{{ body.sell_profit.desc }}
						</td>
						<td style="text-align: center;">Прибыль (убыток) от продаж
						</td>
						<td>{{ body.sell_profit.current }} </td>
						<td>{{ body.sell_profit.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.another_org_prof.desc }}
						</td>
						<td>Доходы от участия в других организациях
						</td>
						<td>{{ body.another_org_prof.current }} </td>
						<td>{{ body.another_org_prof.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.take_percent.desc }}
						</td>
						<td>Проценты к получению
						</td>
						<td>{{ body.take_percent.current }} </td>
						<td>{{ body.take_percent.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.make_perc.desc }}
						</td>
						<td>Проценты к уплате
						</td>
						<td>({{ body.make_perc.current }})</td>
						<td>({{ body.make_perc.previous }})</td>
					</tr>
					<tr>
						<td>
							{{ body.another_profit.desc }}
						</td>
						<td>Прочие доходы</td>
						<td>{{ body.another_profit.current }} </td>
						<td>{{ body.another_profit.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.another_costs.desc }}
						</td>
						<td>Прочие расходы</td>
						<td>({{ body.another_costs.current }})</td>
						<td>({{ body.another_costs.previous }})</td>
					</tr>
					<tr>
						<td>
							{{ body.profit_gross.desc }}
						</td>
						<td style="text-align: center;">Прибыль (убыток) до налогообложения</td>
						<td>{{ body.profit_gross.current }} </td>
						<td>{{ body.profit_gross.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.profit_tax.desc }}
						</td>
						<td>Налог на прибыль</td>
						<td>{{ body.profit_tax.current }} </td>
						<td>{{ body.profit_tax.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.current_tax_profit.desc }}
						</td>
						<td class="padded">в т.ч. текущий налог на прибыль</td>
						<td>({{ body.current_tax_profit.current }})</td>
						<td>({{ body.current_tax_profit.previous }})</td>
					</tr>
					<tr>
						<td>
							{{ body.delayed_tax_profit.desc }}
						</td>
						<td class="padded">отложенный налог на прибыль</td>
						<td>{{ body.delayed_tax_profit.current }} </td>
						<td>{{ body.delayed_tax_profit.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.another.desc }}
						</td>
						<td>Прочее</td>
						<td>{{ body.another.current }} </td>
						<td>{{ body.another.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.clear_profit.desc }}
						</td>
						<td style="text-align: center;">Чистая прибыль (убыток)</td>
						<td>{{ body.clear_profit.current }} </td>
						<td>{{ body.clear_profit.previous }} </td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="pagebreak"> </div>
		<div class="section">
			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 37.5mm;">За {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 37.5mm;">За {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{{ body.activ_rez.desc }}
						</td>
						<td>Результат от переоценки внеоборотных активов, не включаемый в чистую прибыль (убыток)
							периода</td>
						<td>{{ body.activ_rez.current }} </td>
						<td>{{ body.activ_rez.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.rez_another_operations.desc }}
						</td>
						<td>Результат от прочих операций, не включаемый в чистую прибыль (убыток) периода</td>
						<td>{{ body.rez_another_operations.current }} </td>
						<td>{{ body.rez_another_operations.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.tax_operations.desc }}
						</td>
						<td>Налог на прибыль от операций, результат которых не включается в чистую прибыль (убыток)
							периода</td>
						<td>{{ body.tax_operations.current }} </td>
						<td>{{ body.tax_operations.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.total_fin_rez.desc }}
						</td>
						<td>Совокупный финансовый результат периода</td>
						<td>{{ body.total_fin_rez.current }} </td>
						<td>{{ body.total_fin_rez.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.basic_profit_oblig.desc }}
						</td>
						<td class="bold">Справочно Базовая прибыль (убыток) на акцию</td>
						<td>{{ body.basic_profit_oblig.current }} </td>
						<td>{{ body.basic_profit_oblig.previous }} </td>
					</tr>
					<tr>
						<td>
							{{ body.profit_oblig.desc }}
						</td>
						<td class="bold">Разводненная прибыль (убыток) на акцию</td>
						<td>{{ body.profit_oblig.current }} </td>
						<td>{{ body.profit_oblig.previous }} </td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

var style = "<style>@page{size: A4;margin: 0}.doc{margin: 0;padding: 0;font-family: 'Times New Roman', Times, serif;font-size: 12px;background: white;color: black;width: 210mm;padding: 20mm;box-sizing: border-box;display: block;margin: auto;position: relative}.header{text-align: center}.section{margin: 20px 0}table{width: 100%;border-collapse: collapse}table,th,td{border: 0.3mm solid black;padding: 0px 4px;text-align: left}.padded{padding-left: 5mm}.bold{font-weight: bold}.right-align{text-align: right}.signature{margin-top: 20px;text-align: right}@media print{@page{size: A4;margin: 20mm}body{margin: 0;font-size: 12pt}table,th,td{border: 1px solid black;padding: 5px}.header{margin-top: 0}.pagebreak{page-break-before: always}}</style>";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];
export default {
	name: "info_document_report_fin_rez",
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});

		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.show = true;
			this.is_active = response.data.is_active;
			this.name = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1'
				);
			})
		}
		return {
			show: ref(false),
			name: ref(''),
			body: ref({
				organisation_name: null,
				organisation_inn: null,
				organisation_econ: null,
				organisation_form: null,
				document_date_unix: 0,
				document_date: null,
				currency_measure_options: currency_measure_options,
				currency_measure: currency_measure_options[0],
				profit: ref({ current: 0, previous: 0, desc: '' }),
				sebest: ref({ current: 0, previous: 0, desc: '' }),
				val_profit: ref({ current: 0, previous: 0, desc: '' }),
				comm_costs: ref({ current: 0, previous: 0, desc: '' }),
				man_costs: ref({ current: 0, previous: 0, desc: '' }),
				sell_profit: ref({ current: 0, previous: 0, desc: '' }),
				another_org_prof: ref({ current: 0, previous: 0, desc: '' }),
				take_percent: ref({ current: 0, previous: 0, desc: '' }),
				make_perc: ref({ current: 0, previous: 0, desc: '' }),
				another_profit: ref({ current: 0, previous: 0, desc: '' }),
				another_costs: ref({ current: 0, previous: 0, desc: '' }),
				profit_gross: ref({ current: 0, previous: 0, desc: '' }),
				profit_tax: ref({ current: 0, previous: 0, desc: '' }),
				current_tax_profit: ref({ current: 0, previous: 0, desc: '' }),
				delayed_tax_profit: ref({ current: 0, previous: 0, desc: '' }),
				another: ref({ current: 0, previous: 0, desc: '' }),
				clear_profit: ref({ current: 0, previous: 0, desc: '' }),
				activ_rez: ref({ current: 0, previous: 0, desc: '' }),
				rez_another_operations: ref({ current: 0, previous: 0, desc: '' }),
				tax_operations: ref({ current: 0, previous: 0, desc: '' }),
				total_fin_rez: ref({ current: 0, previous: 0, desc: '' }),
				basic_profit_oblig: ref({ current: 0, previous: 0, desc: '' }),
				profit_oblig: ref({ current: 0, previous: 0, desc: '' }),
				date_current: 0,
				date_previous: 0,
				date_preprevious: 0
			}),
			is_active: ref(true),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null)
		}
	},
	methods: {
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				item_id,
				value,
				direction
			)
		},
		valueForTable(array, id, key) {
			return array[id][key].label || array[id][key].value
		},
		valueFor(array, key) {
			return array.find(item => item.field_id === key).value;
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			console.log('onPrintButtonClick');
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'reports_edit_fin_rez', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$route.params.id
			)
		}
	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.doc {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	width: 210mm;
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.padded {
	padding-left: 5mm;
}

.bold {
	font-weight: bold;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}

	.pagebreak {
		page-break-before: always;
	}
}
</style>