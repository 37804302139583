<template>

	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col" style="max-width: 500px;">
			<q-card flat class="erp-tariff-card">
				<q-card-section class="q-gutter-y-md">
					<q-banner class="bg-primary text-white">
						<template v-slot:avatar>
							<q-icon name="account_balance" color="white" />
						</template>
						<div class="text-h6">Баланс: {{ accountBalance.toFixed(2) }} руб.</div>
						<div>Хватит на {{ remainingDays.toFixed(0) }} дней</div>
					</q-banner>

					<div v-for="module in modules" :key="module.id" class="q-mb-md">
						<q-item tag="label" v-ripple>
							<q-item-section avatar>
								<q-checkbox :disable="canChangeModule(module)" v-model="module.is_active"
									@update:model-value="() => { calculateDailyBilling() }" />
							</q-item-section>
							<q-item-section>
								<q-item-label>{{ module.template.name }}</q-item-label>
								<q-item-label caption>
									Рабочих мест: {{ module.template.workplaces + module.workplaces }}
								</q-item-label>
							</q-item-section>
							<q-item-section side v-show="edit_mode && module.template.is_required">
								<div class="row items-center">
									<q-btn icon="remove" color="grey" round dense flat
										:disable="!module.is_active || module.workplaces === 0"
										@click="() => { changeAdditionalWorkplaces(module.id, -1) }" />
									<div class="q-mx-sm">
										{{ module.workplaces }}
									</div>
									<q-btn icon="add" color="grey" round dense flat :disable="!module.is_active"
										@click="() => { changeAdditionalWorkplaces(module.id, 1); }" />
								</div>
							</q-item-section>
						</q-item>
					</div>
				</q-card-section>


				<q-card-section class="row items-center justify-between">
					<div class="text-h6">Рабочих мест</div>
					<div class="text-h6">{{ roles_count }} / {{ workplaces }}</div>
				</q-card-section>
				<q-card-section class="row items-center justify-between">
					<div class="text-h6">Списывается в день:</div>
					<div class="text-h6">{{ pricePerDay.toFixed(2) }}руб.</div>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn flat icon="history" label="Пополнить баланс" @click="createPaymentDialog = true" />
					<q-btn v-if="!edit_mode" flat icon="history" label="История платежей" @click="invoicesHistory" />
					<q-btn v-if="!edit_mode" flat icon="edit" label="Редактировать" @click="edit_mode = true" />
					<q-btn v-else color="primary" icon="save" :loading="save_billing_details" label="Сохранить"
						@click="saveBillingDetails" />
				</q-card-actions>

				<q-dialog v-model="showWarning" persistent>
					<q-card>
						<q-card-section class="row items-center">
							<q-avatar icon="warning" color="warning" text-color="white" />
							<span class="q-ml-sm">Your account will be suspended in {{ remainingDays }} days. Please add
								funds to
								continue service.</span>
						</q-card-section>
						<q-card-actions align="right">
							<q-btn flat label="Dismiss" color="primary" v-close-popup />
							<q-btn flat label="Add Funds" color="primary" v-close-popup />
						</q-card-actions>
					</q-card>
				</q-dialog>
			</q-card>
		</div>
	</div>
	<q-dialog v-model="createPaymentDialog" persistent>
		<q-card style="min-width: 350px">
			<q-card-section>
				<div class="text-h6">Пополнить баланс</div>
			</q-card-section>

			<q-card-section>
				<q-list>
					<q-item>
						<q-item-section>
							<q-item-label overline>Сумма</q-item-label>
							<q-item-label><q-input v-model="newPaymentAmount" label="Сумма" /></q-item-label>
						</q-item-section>
					</q-item>
					<!-- <q-item>
            <q-item-section>
              <q-item-label overline>Описание</q-item-label>
              <q-item-label><q-input v-model="newPaymentDescription" label="Описание" /></q-item-label>
            </q-item-section>
          </q-item> -->
				</q-list>
			</q-card-section>

			<q-card-actions align="right">
				<q-btn flat label="Получить счет" color="primary" :loading="newPaymentLoading" @click="createPayment" />
				<q-btn flat label="Закрыть" color="primary" v-close-popup />
			</q-card-actions>
		</q-card>
	</q-dialog>
</template>

<script>
import { ref } from 'vue'


export default {
	name: "tarif_plan",
	data() {
		if (this.connection.isAuthenticated) {
			this.connection.getOrgStructure(
				'1'
			);
			this.connection.getBillingDetails(
				'1'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getOrgStructure(
					'1'
				);
				this.connection.getBillingDetails(
					'1'
				);
			});
		}
		this.emitter.on('onSaveBillingDetails', (data) => {
			console.log(data);
			this.save_billing_details = false;
			this.edit_mode = false;
			this.$q.notify('Настройки тарифного плана успешно сохранены');
			this.connection.getUserInfoAcrossOrg(
				'1'
			);
		})
		this.emitter.on('onBillingDetails', (response) => {
			const data = response.data;
			this.accountBalance = data.balance;
			this.pricePerDay = data.daily_amount;
			this.remainingDays = this.accountBalance / this.pricePerDay;
			this.modules = data.modules;
			this.calculateDailyBilling()
		});
		this.emitter.on('didReceiveOrgStructure', (data) => {
			this.roles_count = data.roles_count;
		});
		this.emitter.on('onCreatePayment', (response) => {
			this.$q.notify('Платеж создан успешно');
			console.log(response);
			this.newPaymentLoading = false;
			this.newPaymentAmount = 500;
			this.newPaymentDescription = '';
			this.createPaymentDialog = false;
			this.$router.push({ name: 'document_info_bill_to_pay', params: { id: response.data.id, history_id: 'last' } })
		})
		return {
			roles_count: ref(0),
			edit_mode: ref(false),
			modules: ref([]),
			workplaces: ref(3),
			accountBalance: ref(1000),
			remainingDays: ref(0),
			showWarning: ref(false),
			pricePerDay: ref(0),
			save_billing_details: ref(false),
			createPaymentDialog: ref(false),
			newPaymentAmount: ref(500),
			newPaymentDescription: ref(''),
			newPaymentLoading: ref(false),
			page_title: ref('Тарифный план'),
		}
	},
	methods: {
		onInvoiceTouchUp() {
			window.open("./../assets/invoice.pdf", "_blank")
		},
		newBillToPay() {
			this.connection.createNewTarifBillToPay({
				summ: this.bill_to_pay_summ
			})
		},
		changeAdditionalWorkplaces(id, change) {
			const module = this.modules.find(m => m.id === id)
			const total_base_worplaces = this.modules
				.filter(m => m.is_active)
				.reduce((total, m) => total + m.template.workplaces, 0)
			if (module) {
				module.workplaces = Math.max(this.roles_count - total_base_worplaces, module.workplaces + change)
			}
			this.calculateDailyBilling()
		},
		calculateDailyBilling() {
			this.pricePerDay = 0;
			this.pricePerDay = this.modules
				.filter(m => m.is_active)
				.reduce((total, m) => total + m.template.price + m.template.price_per_workplace * (m.workplaces), 0)
			this.workplaces = this.modules
				.filter(m => m.is_active)
				.reduce((total, m) => total + m.template.workplaces + m.workplaces, 0)
		},
		saveBillingDetails() {
			this.save_billing_details = true;
			this.connection.saveBillingDetails(
				'1',
				this.modules
			)
		},
		saveChangesTarif() {
			this.edit_mode = false;
		},
		invoicesHistory() {
			this.$router.push({ name: 'org_invoices_history', params: {} })
		},
		canChangeModule(module) {
			console.log('edit', this.edit_mode)
			if (!this.edit_mode) {
				return true;
			} else {
				if (!module.is_active) {
					return false;
				}
				console.log('mask', (this.workplaces - module.template.workplaces) < this.roles_count)
				return (this.workplaces - module.template.workplaces) < this.roles_count
			}
		},
		createPayment() {
			this.newPaymentLoading = true;
			this.connection.createPayment(
				'1',
				this.newPaymentAmount,
				this.newPaymentDescription,
				'income'
			)
		}
	}
}
</script>

<style></style>