<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col-6 q-зa-md">
			<div class="q-ma-md text-h6">
				Финансовые результаты
			</div>
			<q-list style="max-width: 400px;">
				<q-item v-ripple clickable>
					<q-item-section>
						<q-item-label>Активы всего</q-item-label>
						<q-item-label caption>Суммарный баланс активов</q-item-label>
					</q-item-section>

					<q-item-section side top>
						<q-badge color="teal" label="0.00 р." />
					</q-item-section>
				</q-item>
				<q-item v-ripple clickable>
					<q-item-section>
						<q-item-label>Пассивы всего</q-item-label>
						<q-item-label caption>Суммарный баланс пассивов</q-item-label>
					</q-item-section>

					<q-item-section side top>
						<q-badge color="teal" label="0.00 р." />
					</q-item-section>
				</q-item>
				<q-item v-ripple clickable>
					<q-item-section>
						<q-item-label>Сумма НДС на период</q-item-label>
						<q-item-label caption>Сумма НДС к уплате за период</q-item-label>
					</q-item-section>

					<q-item-section side top>
						<q-badge color="teal" label="0.00 р." />
					</q-item-section>
				</q-item>
			</q-list>
		</div>
		<div class="col-6">
			<div class="q-ma-md text-h6">
				Ближайшие события
			</div>
			<q-list>
				<q-item>
					<q-item-section>
						<q-item-label>Ближайших событий не найдено</q-item-label>
					</q-item-section>

					<q-item-section side top>
					</q-item-section>
				</q-item>
			</q-list>
		</div>
		<div class="col-12">
			<q-table title="Недавние документы" wrap-cells flat borderless :rows="table_rows" :columns="table_columns"
				row-key="name" separator="cell" :pagination="tablePagination" @row-click="onRowClick"
				v-model="table_rows" :loading="isUpdatingData">

				<template v-slot:body-cell-is_in_nds="props">
					<q-td :props="props">
						<q-chip v-if="props.value" color="positive" text-color="white">
							Да
						</q-chip>
						<q-chip v-else color="grey" text-color="white">
							Нет
						</q-chip>
					</q-td>
				</template>

			</q-table>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from'moment';

const table_columns = [
	{ name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
	{ name: 'name', align: 'left', label: 'Название', field: 'name' },
	{ name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
	{ name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
	{ name: 'is_in_nds', align: 'left', label: 'Отражен в НДС', field: 'is_in_nds', sortable: true }
]

export default {
	name: "finance_overview",
	data() {
		this.emitter.on('didReceiveDocumentsList', (data) => {
			console.log(data)
			this.table_rows = data.data;
			this.isUpdatingData = false;
		})
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentsList(
				'1',
				0,
				moment().endOf('day').unix(),
				'all',
				'upd,payment,avance,act_sver',
				true,
				10
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentsList(
					'1',
					0,
					moment().endOf('day').unix(),
					'all',
					'upd,payment,avance,act_sver',
					true,
					10
				);
			})
		}
		return {
			page_title: ref('Обзор финансов'),
			table_columns: ref(table_columns),
			table_rows: ref([]),
			tablePagination: {
				sortBy: 'date',
				descending: true,
				page: 1,
				rowsPerPage: 10
			},
		}
	},
}
</script>

<style scoped lang="sass">

</style>
