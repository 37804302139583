<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el label="Список отчетов" @click="$router.push({ name: 'reports_list', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col" style="max-width: 350px;">
			<q-select label="Год" :options="year_options" v-model="selected_year"
				@update:model-value="(x) => onChangeYear(x)" />
			<q-select label="Квартал" :options="quarter_options" v-model="selected_quarter"
				@update:model-value="(x) => onChangeQuarter(x)" />
			<div>
				Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
			</div>
			<div class="q-ma-sm">
				<q-btn color="primary" label="Сформировать" @click="onSave" />
			</div>

		</div>
	</div>
	<div class="row">
		<q-table title="Список документов" :rows="documents_list" :columns="table_columns" row-key="name" flat
			separator="cell" :pagination="tablePagination" @row-click="onRowClick" v-model="documents_list"
			:loading="isUpdatingData">
			<template v-slot:body-cell-nds_period="props">
				<q-td :props="props">
					<q-select :options="quarter_years_options" v-model="documents_list[props.rowIndex].nds_period"
						@update:model-value="(x) => onChangeNdsStateFor(props.row.id, x.value)" />
				</q-td>
			</template>
		</q-table>
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const table_columns = [
	{ name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
	{ name: 'name', align: 'left', label: 'Название', field: 'name' },
	{ name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
	{ name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
	{ name: 'nds_period', align: 'left', label: 'Период НДС', field: 'nds_period', sortable: true },
]
const year_options = [
	{ value: '2025', label: '2025' },
	{ value: '2024', label: '2024' },
	{ value: '2023', label: '2023' },
	{ value: '2022', label: '2022' },
];
const quarter_options = [
	{ value: 1, label: '1 квартал' },
    { value: 2, label: '2 квартал' },
    { value: 3, label: '3 квартал' },
    { value: 4, label: '4 квартал' },
];

export default {
	name: "create_document_report_nds",
	data() {
		this.emitter.on('onUpdateDocumentNdsState', (data) => {
			console.log(data)
			this.isUpdatingData = false;
		});
		this.emitter.on('onGetBuysBook', (response) => {
			this.$q.loading.hide();
			if (response.error !== undefined) {
				if (response.error === 'access_denied') {
					this.$q.notify('Недостаточно прав');
					return;
				}
			}
			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = response.name
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		this.emitter.on('didReceiveDocumentsListNDS', (data) => {
			console.log(data)
			this.documents_list = data.data;
			this.isUpdatingData = false;
			this.updateQuarterYearsOptions();
		});
		const quarter = moment().quarter();
		const current_quarter = String(moment().quarter());
		const current_year = String(moment().year());
		const period = current_quarter + current_year;
		const selected_quarter = quarter_options[quarter - 1];
		const start = moment().quarter(quarter).startOf('quarter');
		const end = moment().quarter(quarter).endOf('quarter');

		var range = { from: start, to: end };

		
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentsListNDS(
				'1', 
				period,
				'in',
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentsListNDS(
					'1',
					period,
					'in',
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			audit: ref(false),
			document_date_unix: ref(0),
			document_date: ref(null),
			table_columns: ref(table_columns),
			documents_list: ref([]),
			period_range: ref(range),
			isUpdatingData: ref(true),
			quarter_options: quarter_options,
			year_options: year_options,
			selected_year: ref(year_options[0]),
			selected_quarter: ref(selected_quarter),
			page_title: ref('Книга покупок'),
			tablePagination: {
				sortBy: 'date',
				descending: true,
				page: 1,
				rowsPerPage: 50
			},
			quarter_years_options: ref([])
		}
	},
	methods: {
		updateQuarterYearsOptions() {
			const years = [
				{ value: '2025', label: '2025 года' },
				{ value: '2024', label: '2024 года' },
				{ value: '2023', label: '2023 года' },
				{ value: '2022', label: '2022 года' },
			];
			const quarters = [
				{ value: 1, label: '1 квартал' },
				{ value: 2, label: '2 квартал' },
				{ value: 3, label: '3 квартал' },
				{ value: 4, label: '4 квартал' },
			]
			var options = [{ value: null, label: 'Не учтен' }];
			const current_year = String(moment().year());
			const current_quarter = moment().quarter();
			years.forEach(year => {
				quarters.forEach(quarter => {
					if (year.value === current_year) {
						if (quarter.value <= current_quarter) {
							options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
						}
					} else {
						options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
					}
				});
			});
			this.quarter_years_options = options;

		},
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		onChangeYear(value) {
			const current_year = String(moment().year());
			const current_quarter = moment().quarter();
			if (value.value === current_year) {
				this.quarter_options = quarter_options.filter(x => x.value <= current_quarter);
				if (this.selected_quarter.value > current_quarter) {
					this.selected_quarter = quarter_options[current_quarter - 1];
				}
			} else {
				this.quarter_options = quarter_options;
			}
			const quarter = String(this.selected_quarter.value);
			const year = String(value.value);
			const period = quarter + year;
			this.isUpdatingData = true
			this.connection.getDocumentsListNDS(
				'1',
				period,
				'in',
			);
		},
		onChangeQuarter(value) {
			const quarter = String(value.value);
			const year = String(this.selected_year.value);
			const period = quarter + year;
			this.isUpdatingData = true
			this.connection.getDocumentsListNDS(
				'1',
				period,
				'in',
			);
		},
		onChangeNdsStateFor(document_id, state) {
			this.isUpdatingData = true;
			this.connection.updateDocumentNdsState(
				'1',
				document_id,
				state
			)
		},
		onSave() {
			// this.$q.loading.show();
			console.log(this.period_range.from.year())
			const quarter = String(this.selected_quarter.value);
			const year = String(this.selected_quarter.value);
			const period = quarter + year;
			console.log(year, period)
			this.$q.loading.show();
			this.connection.getBuysBookReport(
				'1',
				year,
				period,
				moment(this.period_range.from).endOf('day').unix(),
				moment(this.period_range.to).endOf('day').unix(),
			)
		},
		onPeriodChange(model_value) {
			console.log(model_value);
			if (model_value == null) {
				return;
			}
			this.isUpdatingData = true;
		},
		prettyDatePrint(date) {
			return moment(date).format('DD MMMM YYYY');
		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>
