import {wsUrl} from "../config";

class Connection {
    constructor(emitter) {
        this.emitter = emitter
        this.socket = undefined
        this.isAuthenticated = false
        this.org_id = -1
        this.token = undefined
        this.token_uuid = undefined
        this.user_id = undefined
    }
    
    hasAccount(q) {
        return q.cookies.has('auth_data_token_uuid')
    }
    loadCredentials(q) {
        this.token = q.cookies.get('auth_data_token');
        this.token_uuid = q.cookies.get('auth_data_token_uuid');
        this.org_id = q.cookies.get('current_org_id') || -1;
        return q.cookies.has('auth_data_token_uuid')
    }
    updateCredentials(q, token, token_uuid) {
        q.cookies.set('auth_data_token', token, {
            expires: '30d',
            secure: true,
            path: '/'
        });
        q.cookies.set('auth_data_token_uuid', token_uuid, {
            expires: '30d',
            secure: true,
            path: '/'
        });
    }
    updateOrgId(q, org_id) {
        console.log('updateOrgId', org_id)
        this.org_id = org_id;
        q.cookies.set('current_org_id', org_id, {
            expires: '30d',
            secure: true,
            path: '/'
        });
    }
    logout(q) {
        this.socket.close();
        q.cookies.remove('auth_data_token', { path: '/' });
        q.cookies.remove('auth_data_token_uuid', { path: '/' });
        q.cookies.remove('current_org_id', { path: '/' });
        this.socket = undefined
        this.isAuthenticated = false
        this.org_id = undefined
        this.user_id = undefined
        this.token = undefined
        this.token_uuid = undefined
    }
    selectedOrgId(q) {
        return q.cookies.get('current_org_id')
    }
    makeConnection(q) {
        if (!this.loadCredentials(q)) {
            return false
        }
        if (this.socket !== undefined) { return true }
        this.openConnection()
        return true
    }
    openConnection() {
        this.socket = new WebSocket(wsUrl);
        this.socket.addEventListener('open', (() => { this.onOpen() }));
        this.socket.addEventListener('message', (event => { this.onMessage(event) }))
        this.socket.addEventListener('close', (() => { this.onClose() }))
    }
    onClose() {
        if (this.isAuthenticated === false) {
            return
        }
        this.socket.close();
        this.socket = undefined
        this.isAuthenticated = false
        this.openConnection()
    }
    confirmUser(request_id, code) {
        this.send({
            request_id: request_id,
            action: 'confirm_user',
            code: code
        })
    }
    resendConfirmationCode(request_id) {
        this.send({
            action: 'resend_confirmation_code',
            request_id: request_id
        })
    }
    getUserInfo() {
        this.send({
            action: 'user_info'
        })
    }
    leaveOrganisation(request_id) {
        this.send({
            action: 'leave_organisation',
            id: request_id,
            org_id: this.org_id
        })
    }
    getOrgStructure(request_id) {
        this.send({
            action: 'get_org_structure',
            request_id: request_id,
            org_id: this.org_id
        })
    }
    getOrgStructureFlat(request_id) {
        this.send({
            action: 'get_org_structure_flat',
            request_id: request_id,
            org_id: this.org_id
        })
    }
    editUserInfo(data) {
        this.send({
            action: 'edit_user_info',
            request_id: 'request_id',
            last_name: data.last_name,
            first_name: data.first_name,
            surname: data.surname
        })
    }
    createNewRole(request_id, data){
        const out = Object.assign({
            action: 'add_new_role',
            request_id: request_id,
            org_id: this.org_id
        }, data)
        this.send(out)
    }
    createOrganisation(inn, is_director, request_id) {
        this.send({
            action: 'create_organisation',
            type: 'set',
            id: request_id,
            inn: inn,
            is_director: is_director
        })
    }
    onOrganisationCreated(data) {
        if (data.action === 'create_organisation') {
            this.emitter.emit('organisationCreated', data);
            this.getUserInfo();
        }
    }
    getAllOrganisations() {
        this.send({
            action: 'get_all_organisations'
        })
    }
    getUserInfoAcrossOrg(request_id) {
        this.send({
            action: 'get_user_info_across_org',
            request_id: request_id,
            org_id: this.org_id
        })
    }
    
    inviteUserToOrg(request_id, email, role_id, name, surname) {
        this.send({
            action: 'invite_user_to_organisation',
            request_id: request_id,
            org_id: this.org_id,
            email: email,
            role_id: role_id,
            name: name,
            surname: surname
        })
    }
    createNewTarifBillToPay(data) {
        this.send({
            action: "create_new_bill_to_pay",
            summ: data.summ
        })
    }
    acceptInvite(request_id, invite_id) {
        this.send({
            action: 'accept_invite',
            request_id: request_id,
            invite_id: invite_id
        })
    }
    declineInvite(request_id, invite_id) {
        this.send({
            action: 'decline_invite',
            request_id: request_id,
            invite_id: invite_id
        })
    }
    getRoleInfoById(request_id, role_id) {
        this.send({
            action: 'get_role_info_by_id',
            org_id: this.org_id,
            request_id: request_id,
            role_id: role_id
        })
    }
    updateRoleInfoById(request_id, role_id, data) {
        const out = Object.assign({
            action: 'update_role_info_by_id',
            request_id: request_id,
            org_id: this.org_id,
            role_id: role_id
        }, data)
        this.send(out)
    }
    deleteRoleFromOrganisation(request_id, role_id) {
        this.send({
            action: 'delete_role_from_organisation',
            request_id: request_id,
            role_id: role_id,
            org_id: this.org_id
        })
    }
    cancelInvitation(request_id, role_id) {
        this.send({
            action: 'cancel_invitation',
            request_id: request_id,
            role_id: role_id,
            org_id: this.org_id
        })
    }
    addUserToRole(request_id, affected_user, role_id) {
        this.send({
            action: 'add_user_to_role',
            request_id: request_id,
            affected_user: affected_user,
            role_id: role_id,
            org_id: this.org_id
        })
    }
    removeUserFromRole(request_id, role_id) {
        this.send({
            action: 'remove_user_from_role',
            request_id: request_id,
            role_id: role_id,
            org_id: this.org_id
        })
    }
    removeUserFromOrg(request_id, affected_user, role_id) {
        this.send({
            action: 'remove_user_from_org',
            request_id: request_id,
            affected_user: affected_user,
            role_id: role_id,
            org_id: this.org_id
        })
    }
    getBillsList(request_id, period_start, period_end) {
        this.send({
            action: 'get_bills_list',
            org_id: this.org_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end
        })
    }
    gitBillsListFlatCompact(request_id) {
        this.send({
            action: 'get_bills_flat_list_compact',
            org_id: this.org_id,
            request_id: request_id
        })
    }
    getTransfersByBillCardsList(request_id, bill_id, period_start, period_end) {
        this.send({
            action: 'get_transfers_for_bill_card',
            org_id: this.org_id,
            bill_id: bill_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end
        })
    }
    createNewSubbill(request_id, data) {
        const out = Object.assign({
            action: 'create_subbill',
            org_id: this.org_id,
            request_id: request_id,
        }, data)
        this.send(out)
    }
    getOrganisationInfo(request_id) {
        this.send({
            action: 'get_organisation_info',
            org_id: this.org_id,
            request_id: request_id
        })
    }
    getAgentInfoByInn(request_id, inn) {
        this.send({
            action: 'get_agent_info_by_inn',
            request_id: request_id,
            org_id: this.org_id,
            inn: inn
        })
    }
    getAgentInfoByBik(request_id, bik) {
        this.send({
            action: 'get_agent_info_by_bik',
            request_id: request_id,
            org_id: this.org_id,
            bik: bik
        })
    }
    deleteDocument(request_id, document_id) {
        this.send({
            action: 'delete_document',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id
        })
    }
    restoreDocument(request_id, document_id) {
        this.send({
            action:'restore_document',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id
        })
    }
    getNewDocumentNumber(request_id, document_type) {
        this.send({
            action: 'get_new_document_number',
            org_id: this.org_id,
            request_id: request_id,
            document_type: document_type
        })
    }
    createNewDocument(request_id, data) {
        this.send({
            action: 'create_new_document',
            org_id: this.org_id,
            request_id: request_id,
            body: data
        })
    }
    saveDocument(request_id, data, document_id, document_type) {
        this.send({
            action:'save_document',
            org_id: this.org_id,
            request_id: request_id,
            body: data,
            document_id: document_id,
            document_type: document_type
        })
    }
    getDocumentsList(request_id, period_start, period_end, direction, document_type, is_active, report_type, limit) {
        this.send({
            action: 'get_document_list',
            org_id: this.org_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end,
            direction: direction,
            document_type: document_type,
            is_active: is_active,
            report_type: report_type,
            limit: limit
        })
    }
    getDocumentsListNDS(request_id, nds_period, direction) {
        this.send({
            action: 'get_document_list_nds',
            org_id: this.org_id,
            request_id: request_id,
            nds_period: nds_period,
            direction: direction,
        })
    }
    getBillSaldoList(request_id, period_start, period_end, bill_id, filter, filter2, first_level_filter) {
        this.send({
            action: 'get_bill_saldo_list',
            org_id: this.org_id,
            request_id: request_id,
            period_start: period_start,
            period_end: period_end,
            bill_id: bill_id,
            filter: filter,
            filter2: filter2,
            first_level_filter: first_level_filter,
        })
    }
    getUsersList(request_id) {
        this.send({
            action: 'get_users_list',
            org_id: this.org_id,
            request_id: request_id,
        })
    }
    getTransfersTemplatesList(request_id) {
        this.send({
            action: 'list_trasfers_templates',
            org_id: this.org_id,
            request_id: request_id,
        })
    }
    acceptAvanceFromFactura(request_id, document_id) {
        this.send({
            action: 'accept_avance_from_factura',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id
        })
    } 
    getDocumentInfo(request_id, document_id, history_id) {
        this.send({
            action: 'get_document_info',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id,
            history_id: history_id
        })
    }
    getDocumentInfoShort(request_id, document_id) {
        this.send({
            action: 'get_document_info_short',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id
        })
    }
    updateTransferActiveState(request_id, transfer_id, is_active) {
        this.send({
            action: 'update_transfer_active_state',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            is_active: is_active
        });
    }
    updateTransferBillState(request_id, transfer_id, bill_id, direction) {
        this.send({
            action: 'update_transfer_bill_state',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            bill_id: bill_id,
            direction: direction
        });
    }
    createTransferTemplate(request_id, name, field_id, bill_from, bill_to, direction, comment, document_type) {
        this.send({
            action: 'create_transfer_template',
            org_id: this.org_id,
            request_id: request_id,
            name: name,
            field_id: field_id,
            bill_from: bill_from,
            bill_to: bill_to,
            direction: direction,
            comment: comment,
            document_type: document_type
        })
    }
    updateTransferTemplate(request_id, transfer_id, name, field_id, bill_from, bill_to, direction, comment, document_type) {
        this.send({
            action: 'update_transfer_template',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            name: name,
            field_id: field_id,
            bill_from: bill_from,
            bill_to: bill_to,
            direction: direction,
            comment: comment,
            document_type: document_type
        })
    }
    createUpdTrasferWildcard(request_id, document_id, summ, name, comment, bill_from, bill_to) {
        this.send({
            action: 'create_upd_transfer_wildcard',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id,
            summ: summ,
            name: name,
            comment: comment,
            bill_from: bill_from,
            bill_to: bill_to
        })
    }
    createTxtFromPayement(request_id, document_id) {
        this.send({
            action: 'create_txt_from_payment',
            org_id: this.org_id,
            request_id: request_id,
            document_ids: document_id
        })
    }
    generateReportBalance(request_id, date) {
        this.send({
            action:'generate_report_balance',
            org_id: this.org_id,
            request_id: request_id,
            date: date
        })
    }
    generateReportFinRez(request_id, date) {
        this.send({
            action: 'generate_report_fin_rez',
            org_id: this.org_id,
            request_id: request_id,
            date: date
        })
    } 
    generateReportMoneyUsing(request_id, date) {
        this.send({
            action: 'generate_report_money_using',
            org_id: this.org_id,
            request_id: request_id,
            date: date
        })
    }
    generateReportMoneyMove(request_id, date) {
        this.send({
            action: 'generate_report_money_move',
            org_id: this.org_id,
            request_id: request_id,
            date: date
        })
    }
    generateReportCapChange(request_id, date) {
        this.send({
            action: 'generate_report_cap_change',
            org_id: this.org_id,
            request_id: request_id,
            date: date
        })
    }
    getBillingDetails(request_id) {
        this.send({
            action: 'get_billing_details',
            org_id: this.org_id,
            request_id: request_id
        })
    }
    saveBillingDetails(request_id, modules) {
        this.send({
            action:'save_billing_details',
            org_id: this.org_id,
            request_id: request_id,
            modules: modules
        })
    }
    
    getPaymentsList(request_id) {
        this.send({
            action: 'get_payments_list',
            org_id: this.org_id,
            request_id: request_id
        })
    }
    createPayment(request_id, amount, description, payment_type) {
        this.send({
            action: 'create_payment',
            org_id: this.org_id,
            request_id: request_id,
            amount: amount,
            description: description,
            payment_type: payment_type
        })
    }
    createInitialTransfersWildcard( request_id, name, summ, bill_to, bill_from, comment, seller_inn, cust_inn, name_id, date, count) {
        this.send({
            action: 'create_initial_transfer_wildcard',
            org_id: this.org_id,
            request_id: request_id,
            name: name,
            summ: summ,
            bill_to: bill_to,
            bill_from: bill_from,
            comment: comment,
            seller_inn: seller_inn,
            cust_inn: cust_inn,
            name_id: name_id,
            date: date,
            count: count
        })
    }
    listInitialTransfers(request_id) {
        this.send({
            action: 'list_initial_transfers',
            org_id: this.org_id,
            request_id: request_id
        })
    }
    editInitialTransfer(request_id, transfer_id, name, summ, bill_to, bill_from, comment, seller_inn, cust_inn, name_id, date, count) {
        this.send({
            action: 'edit_initial_transfer',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id,
            name: name,
            summ: summ,
            bill_to: bill_to,
            bill_from: bill_from,
            comment: comment,
            seller_inn: seller_inn,
            cust_inn: cust_inn,
            name_id: name_id,
            date: date,
            count: count
        })
    }
    deleteTransfer(request_id, transfer_id) {
        this.send({
            action: 'delete_transfer',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id
        })
    }
    createActSver(request_id, contragent, period_start, period_end, additional_data) {
        this.send({
            action: 'create_act_sver',
            org_id: this.org_id,
            request_id: request_id,
            contragent: contragent,
            period_start: period_start,
            period_end: period_end,
            additional_data: additional_data
        })
    }
    deleteTransferTemplate(request_id, transfer_id) {
        this.send({
            action: 'delete_transfer_template',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id
        })
    }
    getTransferTemplate(request_id, transfer_id) {
        this.send({
            action: 'get_transfer_template',
            org_id: this.org_id,
            request_id: request_id,
            transfer_id: transfer_id
        })
    }
    getNDSReport(request_id, year, period, period_start, period_end) {
        this.send({
            action: 'get_nds_report',
            org_id: this.org_id,
            request_id: request_id,
            year: year,
            period: period,
            period_start: period_start,
            period_end: period_end
        })
    } 
    getNDSReportRawData(request_id, year, period, period_start, period_end) {
        this.send({
            action: 'get_nds_report_raw_data',
            org_id: this.org_id,
            request_id: request_id,
            year: year,
            period: period,
            period_start: period_start,
            period_end: period_end
        })
    }
    getBuysBookReport(request_id, year, period, period_start, period_end) {
        this.send({
            action: 'get_buys_book',
            org_id: this.org_id,
            request_id: request_id,
            year: year,
            period: period,
            period_start: period_start,
            period_end: period_end
        })
    }
    getSellBookReport(request_id, year, period, period_start, period_end) {
        this.send({
            action: 'get_sell_book',
            org_id: this.org_id,
            request_id: request_id,
            year: year,
            period: period,
            period_start: period_start,
            period_end: period_end
        })
    }
    getMyInvitesList(request_id) {
        this.send({
            action: 'get_my_invites_list',
            org_id: this.org_id,
            request_id: request_id
        })
    }
    updateDocumentNdsState(request_id, document_id, state) {
        this.send({
            action: 'update_document_nds_state',
            org_id: this.org_id,
            request_id: request_id,
            document_id: document_id,
            state: state
        })
    }
    send(data) {
        console.log(data)
        this.socket.send(JSON.stringify(data));
    }
    onOpen() {
        this.send({
            action: 'auth',
            token: this.token,
            token_uuid: this.token_uuid,
            client: 'SID ERP Web'
        });
        this.emitter.emit('connectionOpen');
    }
    onMessage(event) {
        console.log(event.data)
        const json = JSON.parse(event.data)
        if (this.isAuthenticated) {
            if (this.onAccessDeniedErrorReceived(json)) {
                return;
            }
            this.didReceiveOrganisationInfo(json)
            this.didReceiveUserInfo(json)
            this.onOrganisationCreated(json)
            this.didReceiveOrgStructure(json)
            this.didReceiveOrgStructureFlat(json)
            this.didCreateNewRole(json)
            this.didEditUserInfo(json)
            this.didGetAllOrganisations(json)
            this.didConfirmUser(json)
            this.didAcceptInvite(json)
            this.didReceoveUserInfoAcrossOrg(json)
            this.didInviteUserToOrg(json)
            this.didReceiveRoleInfoById(json)
            this.didUpdateRoleInfoById(json)
            this.didReceiveFinanceSettings(json)
            this.didReceiveBillsList(json)
            this.didDeleteRoleFromOrganisation(json)
            this.didReceiveBillListFlatCompact(json)
            this.didCreateNewSubbill(json)
            this.didReceiveTransfersListByBillCard(json)
            this.didReceiveAgentInfoByInn(json)
            this.didReceiveAgentInfoByBik(json)
            this.didReceiveNewDocumentNumber(json)
            this.didCreateNewDocument(json)
            this.didReceiveDocumentsList(json)
            this.onGetUsersList(json)
            this.didReceiveDocumentInfo(json)
            this.didReceiveBillSaldoList(json)
            this.onResendConfirmationCode(json)
            this.onCancelInvitation(json)
            this.onAddUserToRole(json)
            this.onRemoveUserFromRole(json)
            this.onRemoveUserFromOrg(json)
            this.onSaveDocument(json)
            this.onReceiveTrasnferTemplateList(json)
            this.onCreateTransferTemplate(json)
            this.onUpdateTransferActiveState(json)
            this.onUpdateTransferBillState(json)
            this.onCreateUpdTransferWildcard(json)
            this.didReceiveDocumentInfoShort(json)
            this.didReceiveLeaveOrganisation(json)
            this.onDeleteDocument(json)
            this.onRestoreDocument(json)
            this.onCreateTxtFromPayement(json)
            this.onGenerateReportBalance(json)
            this.onGenerateReportFinRez(json)
            this.onGenerateReportMoneyUsing(json)
            this.onGenerateReportMoneyMove(json)
            this.onGenerateReportCapChange(json)
            this.onBillingDetails(json)
            this.onSaveBillingDetails(json)
            this.onGetPaymentsList(json)
            this.onCreatePayment(json)
            this.onCreateInitialTransfersWildcard(json)
            this.onListInitialTransfers(json)
            this.onEditInitialTransfer(json)
            this.onDeleteTransfer(json)
            this.onCreateActSver(json)
            this.onDeleteTransferTemplate(json)
            this.onGetTransferTemplate(json)
            this.onUpdateTransferTemplate(json)
            this.onGetNDSReport(json)
            this.onGetBuysBook(json)
            this.onGetSellBook(json)
            this.onGetMyInvitesList(json)
            this.onUpdateDocumentNdsState(json)
            this.didReceiveDocumentsListNDS(json)
            this.onGetNDSReportRawData(json)
            this.onAcceptAvanceFromFactura(json)
            
            this.notifyRoleUpdated(json)
        } else {
            this.didReceiveAuthMessage(json)
        }
    }
    onAccessDeniedErrorReceived(data) {
        if (data.error == 'access_denied') {
            this.emitter.emit('accessDeniedErrorReceived', data);
            return true
        }
        return false
    }
    didReceiveAuthMessage(data) {
        console.log("rec_packet", data);
        if (data.action === 'auth') {
            console.log("auth_packet", data);
            if (data.success === 1) {
                console.log("suc_packet", JSON.parse(data.user_info));
                this.isAuthenticated = true;
                const user_info = JSON.parse(data.user_info);
                this.user_id = user_info.id;
                this.emitter.emit('didReceiveUserInfo', user_info);
                this.emitter.emit('connectionReady');
            } else {
                this.isAuthenticated = false;
                this.user_id = undefined
                this.emitter.emit('didReceiveAuthFail', data);
            }
        }
    }
    didReceiveOrganisationInfo(data) {
        if (data.action === 'get_organisation_info') {
            this.emitter.emit('organisationInfoWasReceived', data)
        }
    }
    didReceiveUserInfo(data) {
        if (data.action === 'user_info') {
            // try {
            //     const org_id = data.organisations[0].id;
            //
            //     // this.getUserInfoAcrossOrg('1', org_id);
            // // eslint-disable-next-line no-empty
            // } catch {
            //
            // }
            this.emitter.emit('didReceiveUserInfo', data);
        }
    }
    didReceiveOrgStructure(data) {
        if (data.action === 'get_org_structure') {
            this.emitter.emit('didReceiveOrgStructure', data);
        }
    }
    didReceiveOrgStructureFlat(data) {
        if (data.action === 'get_org_structure_flat') {
            this.emitter.emit('didReceiveOrgStructureFlat', data);
        }
    }
    didCreateNewRole(data) {
        if (data.action === 'add_new_role') {
            this.emitter.emit('didCreateNewRole', data);
        }
    }
    didEditUserInfo(data) {
        if (data.action === 'edit_user_info') {
            this.emitter.emit('didEditUserInfo', data);
        }
    }
    didGetAllOrganisations(data) {
        if (data.action === 'get_all_organisations') {
            this.emitter.emit('didGetAllOrganisations', data);
        }
    }
    
    didInviteUserToOrg(data) {
        if (data.action === 'invite_user_to_organisation') {
            this.emitter.emit('didInviteUserToOrg', data);
        }
    }
    didConfirmUser(data) {
        if (data.action === 'confirm_user') {
            this.emitter.emit('didConfirmUser', data)
        }
    }
    didAcceptInvite(data) {
        if (data.action === 'accept_invite') {
            this.emitter.emit('didAcceptInvite', data)
        }
    }
    didReceoveUserInfoAcrossOrg(data) {
        if (data.action === 'get_user_info_across_org') {
            this.emitter.emit('didReceoveUserInfoAcrossOrg', data)
        }
    }
    didReceiveRoleInfoById(data) {
        if (data.action === 'get_role_info_by_id') {
            this.emitter.emit('didReceiveRoleInfoById', data)
        }
    }

    didUpdateRoleInfoById(data) {
        if (data.action === 'update_role_info_by_id') {
            this.emitter.emit('didUpdateRoleInfoById', data)
        }
    }
    didReceiveFinanceSettings(data) {
        if (data.action === 'get_finance_settings') {
            this.emitter.emit('didReceiveFinanceSettings', data)
        }
    }
    didReceiveBillsList(data) {
        if (data.action === 'get_bills_list') {
            this.emitter.emit('didReceiveBillsList', data)
        }
    }
    didDeleteRoleFromOrganisation(data) {
        if (data.action === 'delete_role_from_organisation') {
            this.emitter.emit('didDeleteRoleFromOrganisation', data)
        }
    }
    didReceiveBillListFlatCompact(data) {
        if (data.action === 'get_bills_flat_list_compact') {
            this.emitter.emit('didReceiveBillListFlatCompact', data)
        }
    }
    
    didCreateNewSubbill(data) {
        if (data.action === 'create_subbill') {
            this.emitter.emit('didCreateNewSubbill', data)
        }
    }

    didReceiveTransfersListByBillCard(data) {
        if (data.action === 'get_transfers_for_bill_card') {
            this.emitter.emit('didReceiveTransfersListByBillCard', data)
        }
    }
    didReceiveAgentInfoByInn(data) {
        if (data.action === 'get_agent_info_by_inn') {
            this.emitter.emit('didReceiveAgentInfoByInn', data)
        }
    }
    didReceiveAgentInfoByBik(data) {
        if (data.action === 'get_agent_info_by_bik') {
            this.emitter.emit('didReceiveAgentInfoByBik', data)
        }
    }
    didReceiveNewDocumentNumber(data) {
        if (data.action === 'get_new_document_number') {
            this.emitter.emit('didReceiveNewDocumentNumber', data)
        }
    }
    didCreateNewDocument(data) {
        if (data.action === 'create_new_document') {
            this.emitter.emit('didCreateNewDocument', data)
        }
    }
    didReceiveDocumentsList(data) {
        if (data.action === 'get_document_list') {
            this.emitter.emit('didReceiveDocumentsList', data)
        }
    }
    didReceiveDocumentsListNDS(data) {
        if (data.action === 'get_document_list_nds') {
            this.emitter.emit('didReceiveDocumentsListNDS', data)
        }
    }
    onGetUsersList(data) {
        if (data.action === 'get_users_list') {
            this.emitter.emit('onGetUsersList', data)
        }
    }
    didReceiveDocumentInfo(data) {
        if (data.action === 'get_document_info') {
            this.emitter.emit('didReceiveDocumentInfo', data)
        }
    }
    didReceiveBillSaldoList(data) {
        if (data.action === 'get_bill_saldo_list') {
            this.emitter.emit('didReceiveBillSaldoList', data)
        }
    }
    onResendConfirmationCode(data) {
        if (data.action === 'resend_confirmation_code') {
            this.emitter.emit('onResendConfirmationCode', data)
        }
    }
    onCancelInvitation(data) {
        if (data.action === 'cancel_invitation') {
            this.emitter.emit('onCancelInvitation', data)
        }
    }
    onAddUserToRole(data) {
        if (data.action === 'add_user_to_role') {
            this.emitter.emit('onAddUserToRole', data)
        }
    }
    onRemoveUserFromRole(data) {
        if (data.action === 'remove_user_from_role') {
            this.emitter.emit('onRemoveUserFromRole', data)
        }
    }
    onRemoveUserFromOrg(data) {
        if (data.action === 'remove_user_from_org') {
            this.emitter.emit('onRemoveUserFromOrg', data)
        }
    }
    onSaveDocument(data) {
        if (data.action ==='save_document') {
            this.emitter.emit('onSaveDocument', data)
        }
    }
    onReceiveTrasnferTemplateList(data) {
        if (data.action === 'list_trasfers_templates') {
            this.emitter.emit('onReceiveTrasnferTemplateList', data)
        }
    }
    onCreateTransferTemplate(data) {
        if (data.action === 'create_transfer_template') {
            this.emitter.emit('onCreateTransferTemplate', data)
        }
    }
    onUpdateTransferActiveState(data) {
        if (data.action === 'update_transfer_active_state') {
            this.emitter.emit('onUpdateTransferActiveState', data)
        }
    }
    onUpdateTransferBillState(data) {
        if (data.action === 'update_transfer_bill_state') {
            this.emitter.emit('onUpdateTransferBillState', data)
        }
    }

    onCreateUpdTransferWildcard(data) {
        if (data.action === 'create_upd_transfer_wildcard') {
            this.emitter.emit('onCreateUpdTransferWildcard', data)
        }
    }
    didReceiveDocumentInfoShort(data) {
        if (data.action === 'get_document_info_short') {
            this.emitter.emit('didReceiveDocumentInfoShort', data)
        }
    }
    didReceiveLeaveOrganisation(data) {
        if (data.action === 'leave_organisation') {
            this.emitter.emit('didReceiveLeaveOrganisation', data)
        }
    }
    onDeleteDocument(data) {
        if (data.action === 'delete_document') {
            this.emitter.emit('onDeleteDocument', data)
        }
    }
    onRestoreDocument(data) {
        if (data.action ==='restore_document') {
            this.emitter.emit('onRestoreDocument', data)
        }
    }
    onCreateTxtFromPayement(data) { 
        if (data.action === 'create_txt_from_payment') {
            this.emitter.emit('onCreateTxtFromPayement', data)
        }
    }
    onGenerateReportBalance(data) {
        if (data.action === 'generate_report_balance') {
            this.emitter.emit('onGenerateReportBalance', data)
        }
    }
    onGenerateReportFinRez(data) {
        if (data.action === 'generate_report_fin_rez') {
            this.emitter.emit('onGenerateReportFinRez', data)
        }
    }
    onGenerateReportMoneyUsing(data) {
        if (data.action === 'generate_report_money_using') {
            this.emitter.emit('onGenerateReportMoneyUsing', data)
        }
    }
    onGenerateReportMoneyMove(data) {
        if (data.action === 'generate_report_money_move') {
            this.emitter.emit('onGenerateReportMoneyMove', data)
        }
    }
    onGenerateReportCapChange(data) {
        if (data.action === 'generate_report_cap_change') {
            this.emitter.emit('onGenerateReportCapChange', data)
        }
    }
    onBillingDetails(data) {
        if (data.action === 'get_billing_details') {
            this.emitter.emit('onBillingDetails', data)
        }
    }
    onSaveBillingDetails(data) {
        if (data.action ==='save_billing_details') {
            this.emitter.emit('onSaveBillingDetails', data)
        }
    }
    onGetPaymentsList(data) {
        if (data.action === 'get_payments_list') {
            this.emitter.emit('onGetPaymentsList', data)
        }
    }
    onCreatePayment(data) {
        if (data.action === 'create_payment') {
            this.emitter.emit('onCreatePayment', data)
        }
    }
    onCreateInitialTransfersWildcard(data) {
        if (data.action === 'create_initial_transfer_wildcard') {
            this.emitter.emit('onCreateInitialTransfersWildcard', data)
        }
    }
    onListInitialTransfers(data) {
        if (data.action === 'list_initial_transfers') {
            this.emitter.emit('onListInitialTransfers', data)
        }
    }
    onEditInitialTransfer(data) {
        if (data.action === 'edit_initial_transfer') {
            this.emitter.emit('onEditInitialTransfer', data)
        }
    }
    onDeleteTransfer(data) {
        if (data.action === 'delete_transfer') {
            this.emitter.emit('onDeleteTransfer', data)
        }
    }
    onCreateActSver(data) {
        if (data.action === 'create_act_sver') {
            this.emitter.emit('onCreateActSver', data)
        }
    }
    onDeleteTransferTemplate(data) {
        if (data.action === 'delete_transfer_template') {
            this.emitter.emit('onDeleteTransferTemplate', data)
        }
    }
    onGetTransferTemplate(data) {
        if (data.action === 'get_transfer_template') {
            this.emitter.emit('onGetTransferTemplate', data)
        }
    }
    onUpdateTransferTemplate(data) {
        if (data.action === 'update_transfer_template') {
            this.emitter.emit('onUpdateTransferTemplate', data)
        }
    }
    onGetNDSReport(data) {
        if (data.action === 'get_nds_report') {
            this.emitter.emit('onGetNDSReport', data)
        }
    }
    onGetSellBook(data) {
        if (data.action === 'get_sell_book') {
            this.emitter.emit('onGetSellBook', data)
        }
    }
    onGetBuysBook(data) {
        if (data.action === 'get_buys_book') {
            this.emitter.emit('onGetBuysBook', data)
        }
    }
    onGetMyInvitesList(data) { 
        if (data.action === 'get_my_invites_list') {
            this.emitter.emit('onGetMyInvitesList', data)
        }
    }
    onUpdateDocumentNdsState(data) {
        if (data.action === 'update_document_nds_state') {
            this.emitter.emit('onUpdateDocumentNdsState', data)
        }
    }
    onAcceptAvanceFromFactura(data) {
        if (data.action === 'accept_avance_from_factura') {
            this.emitter.emit('onAcceptAvanceFromFactura', data)
        }
    }
    onGetNDSReportRawData(data) {
        if (data.action === 'get_nds_report_raw_data') {
            this.emitter.emit('onGetNDSReportRawData', data)
        }
    }
    notifyRoleUpdated(data) {
        if (data.type == 'notify' && data.action === 'role_updated') {
            this.emitter.emit('notifyRoleUpdated', data)
        }
    }
}

export default Connection