<template>
	<router-view />
</template>

<script>

export default {
	name: "report_create_root",
}
</script>

<style scoped></style>