<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="row">
		<div class="col">
			<div class="q-ma-md text-h5 row">
				<div class="col">
					{{ page_title }}
				</div>
				<div class="col" style="text-align: right;">
					<q-btn-dropdown color="primary" label="Создать" icon="add">
						<q-list>
							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_balance', params: {} })">
								<q-item-section>
									<q-item-label>Бухгалтерский баланс</q-item-label>
								</q-item-section>
							</q-item>

							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_fin_rez', params: {} })">
								<q-item-section>
									<q-item-label>Отчет о финансовых результатах</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_cap_change', params: {} })">
								<q-item-section>
									<q-item-label>Отчет об изменениях капитала</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_money_move', params: {} })">
								<q-item-section>
									<q-item-label>Отчет о движении денежных средств</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_money_using', params: {} })">
								<q-item-section>
									<q-item-label>Отчет о целевом использовании средств</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_nds', params: {} })">
								<q-item-section>
									<q-item-label>Отчет по НДС</q-item-label>
								</q-item-section>
							</q-item>
							<!-- <q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_sells_book', params: {} })">
								<q-item-section>
									<q-item-label>Книга продаж</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup
								@click="$router.push({ name: 'reports_create_buys_book', params: {} })">
								<q-item-section>
									<q-item-label>Книга покупок</q-item-label>
								</q-item-section>
							</q-item> -->
						</q-list>
					</q-btn-dropdown>
				</div>

			</div>
			<q-table :rows="table_rows" :columns="table_columns" row-key="name" flat separator="cell"
				:pagination="tablePagination" @row-click="onRowClick" v-model="table_rows">

			</q-table>
		</div>
		<div class="col" style="max-width: 320px; padding-left: 16px;">
			<q-select v-model="report_type_filter" :options="reports_options" label="Тип отчета"
				@update:model-value="(newValue) => { onFilterChange(newValue) }" />
			<q-date v-model="period_range.from" flat minimal
				@update:model-value="(newValue) => { onPeriodChange({ from: newValue, to: period_range.to }) }" />
			<q-date v-model="period_range.to" flat minimal
				@update:model-value="(newValue) => { onPeriodChange({ from: period_range.from, to: newValue }) }" />
			<div v-if="period_range == null">
				Период не установлен
			</div>
			<div v-else>
				Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const document_direction_options = [
	{
		label: 'Любое',
		value: 'all'
	},
	{
		label: 'Входящий',
		value: 'in'
	},
	{
		label: 'Исходящий',
		value: 'out'
	},
	{
		label: 'Внутренний',
		value: 'internal'
	}
]

const table_columns = [
	{ name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
	{ name: 'name', align: 'left', label: 'Название', field: 'name' },
	{ name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
	{ name: 'report_type', align: 'left', label: 'Тип отчета', field: 'report_type_display', sortable: true },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
]
const reports_options = [
	{ label: 'Все отчеты', value: 'any' },
	{ label: 'Бухгалтерский баланс', value: 'balance' },
	{ label: 'Отчет о финансовых резульататх', value: 'fin_rez' },
	{ label: 'Отчет об изменениях капитала', value: 'cap_change' },
	{ label: 'Отчет о движении денежных средств', value: 'money_move' },
	{ label: 'Отчет о целевом использовании средств', value: 'money_using' }
];
export default {
	name: "reports_list",
	components: {
	},
	data() {
		this.emitter.on('didReceiveDocumentsList', (data) => {
			console.log(data)
			this.table_rows = data.data;
			this.isUpdatingData = false;
		})
		const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
		const period_end = moment().endOf('day');
		const period_start_unix = period_start.unix();
		const period_end_unix = period_end.unix();
		var range = this.$store.state.filter_period;
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentsList(
				'1',
				moment(range.from).startOf('day').unix(),
				moment(range.to).endOf('day').unix(),
				'all',
				'report',
				true,
				'any'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentsList(
					'1',
					moment(range.from).startOf('day').unix(),
					moment(range.to).endOf('day').unix(),
					'all',
					'report',
					true,
					'any'
				);
			})
		}

		return {
			isUpdatingData: ref(true),
			table_rows: ref([]),
			period_start_unix: ref(period_start_unix),
			period_end_unix: ref(period_end_unix),
			incoming: ref('all'),
			template_id: ref({ label: 'Все', value: -1 }),
			templates_list_options: ref([]),
			document_direction: ref(document_direction_options[0]),
			document_direction_options: document_direction_options,
			period_range: ref(range),
			reports_options: ref(reports_options),
			report_type_filter: ref(reports_options[0]),
			page_title: ref('Список отчетов')
		}
	},
	setup() {
		moment.locale('ru');
		return {
			table_columns,

			tablePagination: {
				rowsPerPage: 0
			},
		}
	},
	methods: {
		onRowClick(event, row) {
			console.log(row.document_type)
			if (row.report_type === 'balance') {
				this.$router.push({ name: 'reports_info_balance', params: { id: row.id, history_id: 'last' } })
			} else if (row.report_type === 'fin_rez') {
				this.$router.push({ name: 'reports_info_fin_rez', params: { id: row.id, history_id: 'last' } })
            }

		},
		onSubRowClick(event, row) {
			console.log(row);
		},
		onFilterChange(filter_value) {
			this.isUpdatingData = true;
			if (this.connection.isAuthenticated) {
				this.connection.getDocumentsList(
					'1',
					moment(this.period_range.from).startOf('day').unix(),
					moment(this.period_range.to).endOf('day').unix(),
					'all',
					'report',
					true,
					filter_value.value
				);
			} else {
				this.emitter.on('connectionReady', () => {
					this.connection.getDocumentsList(
						'1',
						moment(this.period_range.from).startOf('day').unix(),
						moment(this.period_range.to).endOf('day').unix(),
						'all',
						'report',
						true,
						filter_value.value
					);
				})
			}
		},
		onPeriodChange(model_value) {
			console.log(model_value);
			if (model_value == null) {
				return;
			}
			this.$store.commit('updateFilterPeriod', model_value);
			this.isUpdatingData = true;
			if (this.connection.isAuthenticated) {
				this.connection.getDocumentsList(
					'1',
					moment(model_value.from).endOf('day').unix(),
					moment(model_value.to).endOf('day').unix(),
					'all',
					'report',
					this.report_type_filter.value
				);
			} else {
				this.emitter.on('connectionReady', () => {
					this.connection.getDocumentsList(
						'1',
						moment(model_value.from).endOf('day').unix(),
						moment(model_value.to).endOf('day').unix(),
						'all',
						'report',
						this.report_type_filter.value
					);
				})
			}
		},
		prettyDatePrint(date) {
			return moment(date).format('DD MMMM YYYY');
		}
	},
}
</script>

<style scoped></style>