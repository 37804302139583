<template>
	<div class="container">
		<h6 class="header">Отчет об изменениях капитала <q-input class="inline" dense flat v-model="document_date">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy cover transition-show="scale" transition-hide="scale">
							<q-date mask="DD.MM.YYYY" v-model="document_date"
								@update:model-value="(newValue) => onDateChanged(newValue)">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Закрыть" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input></h6>
		<div class="row" style="width: 180mm;">
			<div class="col-8">
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организация" class="inline" style="width: 100%;" dense flat
						v-model="organisation_name" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Идентификационный номер налогоплательщика" class="inline" style="width: 100%;" dense
						flat v-model="organisation_inn" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Вид экономической деятельности" class="inline" style="width: 100%;" dense flat
						v-model="organisation_econ" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организационно-правовая форма/форма собственности" class="inline"
						style="width: 100%;" dense flat v-model="organisation_form" />
				</p>
				<p style="width: 100%; padding-right: 10mm">Единица измерения: <q-select v-model="currency_measure"
						:options="currency_measure_options" class="inline" dense flat />
				</p>
			</div>
			<div class="col-4">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
				</table>
			</div>
		</div>

		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				1. Движение капитала
			</div>

			<table class="table-cap" style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th style="width: 60mm;">Наименование показателя</th>
						<th style="width: 20mm;">Уставной капитад</th>
						<th style="width: 20mm;">Собственные акции, выкупленные у акционеров</th>
						<th style="width: 20mm;">Добавочный капитал</th>
						<th style="width: 20mm;">Резервный капитал</th>
						<th style="width: 20mm;">Нераспределенная прибыль (непокрытый убыток)</th>
						<th style="width: 20mm;">Итого</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Величина капитала на 31 декабря 2024г.</td>
						<td><q-input class="inline" v-model="i_cap_val.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_val.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_val.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_val.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_val.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_val.total" dense flat /></td>
					</tr>
					<tr>
						<td>За 20г.<br />Увеличение капитала всего</td>
						<td><q-input class="inline" v-model="i_cap_increase.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_increase.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_increase.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_increase.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_increase.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_increase.total" dense flat /></td>
					</tr>
					<tr>
						<td>В том числе:<br />чистая прибыль</td>
						<td>Х</td>
						<td>Х</td>
						<td>Х</td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_clear_profit.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_clear_profit.total" dense flat /></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>Х</td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_reval_goods.add_cap" dense flat /></td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_reval_goods.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_reval_goods.total" dense flat /></td>
					</tr>
					<tr>
						<td>доходы, относящиеся непосредственно на увеличение капитала</td>
						<td>Х</td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_income_cap_increase.add_cap" dense flat /></td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_income_cap_increase.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_income_cap_increase.total" dense flat /></td>
					</tr>
					<tr>
						<td>Дополнительный выпуск акций</td>
						<td><q-input class="inline" v-model="i_add_papres_create.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_add_papres_create.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_add_papres_create.add_cap" dense flat /></td>
						<td>Х</td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_add_papres_create.total" dense flat /></td>
					</tr>
					<tr>
						<td>Увеличение номинальной стоимости акций</td>
						<td><q-input class="inline" v-model="i_papres_val_increase.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_papres_val_increase.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_papres_val_increase.add_cap" dense flat /></td>
						<td>Х</td>
						<td><q-input class="inline" v-model="i_papres_val_increase.dry_profit" dense flat /></td>
						<td>Х</td>
					</tr>
					<tr>
						<td>реорганизация номинальной стоимости акции</td>
						<td><q-input class="inline" v-model="i_nom_papers_price_reval.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_nom_papers_price_reval.redeemed_papers" dense flat />
						</td>
						<td><q-input class="inline" v-model="i_nom_papers_price_reval.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_nom_papers_price_reval.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_nom_papers_price_reval.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_nom_papers_price_reval.total" dense flat /></td>
					</tr>
					<tr>
						<td>Уменьшение капитала - всего</td>
						<td><q-input class="inline" v-model="i_cap_decrease.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decrease.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decrease.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decrease.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decrease.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decrease.total" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе:<br />убыток</td>
						<td><q-input class="inline" v-model="i_cap_decr_loss.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_loss.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_loss.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_loss.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_loss.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_loss.total" dense flat /></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td><q-input class="inline" v-model="i_cap_decr_reval.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reval.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reval.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reval.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reval.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reval.total" dense flat /></td>
					</tr>
					<tr>
						<td>расходы, относящиеся непосредственно на уменьшение капитала</td>
						<td><q-input class="inline" v-model="i_cap_decr_cost.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_cost.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_cost.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_cost.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_cost.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_cost.total" dense flat /></td>
					</tr>
					<tr>
						<td>уменьшение номинальной стоимости акций</td>
						<td><q-input class="inline" v-model="i_cap_decr_papers_price_decr.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papers_price_decr.redeemed_papers" dense flat />
						</td>
						<td><q-input class="inline" v-model="i_cap_decr_papers_price_decr.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papers_price_decr.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papers_price_decr.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papers_price_decr.total" dense flat /></td>
					</tr>
					<tr>
						<td>уменьшение количества акций</td>
						<td><q-input class="inline" v-model="i_cap_decr_papres_count.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papres_count.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papres_count.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papres_count.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papres_count.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_papres_count.total" dense flat /></td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td><q-input class="inline" v-model="i_cap_decr_reorg.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reorg.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reorg.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reorg.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reorg.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_reorg.total" dense flat /></td>
					</tr>
					<tr>
						<td>дивиденды</td>
						<td>x</td>
						<td>x</td>
						<td>x</td>
						<td>x</td>
						<td><q-input class="inline" v-model="i_cap_decr_divid.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_divid.total" dense flat /></td>
					</tr>
					<tr>
						<td>Изменение добавочного капитала</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_cap_decr_add_cap_decr.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_add_cap_decr.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_cap_decr_add_cap_decr.dry_profit" dense flat /></td>
						<td>X</td>
					</tr>
					<tr>
						<td>Изменение резервного капитала</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_change.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_change.dry_profit" dense flat /></td>
						<td>X</td>
					</tr>
					<tr>
						<td>Величина капитала на 31 декабря 2024</td>
						<td><q-input class="inline" v-model="i_rez_cap_value.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_value.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_value.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_value.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_value.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_value.total" dense flat /></td>
					</tr>
					<tr>
						<td>За 20 г <br />Увеличение капитала - всего:</td>
						<td><q-input class="inline" v-model="i_total_cap_incr.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_total_cap_incr.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_total_cap_incr.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_total_cap_incr.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_total_cap_incr.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_total_cap_incr.total" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе:<br /> чистая прибыль</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_clear_profit.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_clear_profit.total" dense flat /></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_tax_operations.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_tax_operations.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_tax_operations.total" dense flat /></td>
					</tr>
					<tr>
						<td>доходы, относящиеся непосредственно на увеличение капитала</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_income_cap_incr.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_income_cap_incr.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_income_cap_incr.total" dense flat /></td>
					</tr>
					<tr>
						<td>дополнительный выпуск акций</td>
						<td><q-input class="inline" v-model="i_rez_cap_add_papers_create.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_add_papers_create.redeemed_papers" dense flat />
						</td>
						<td><q-input class="inline" v-model="i_rez_cap_add_papers_create.add_cap" dense flat /></td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_add_papers_create.total" dense flat /></td>
					</tr>
					<tr>
						<td>увеличение номинальной стоимости акций</td>
						<td><q-input class="inline" v-model="i_rez_cap_add_nom_price_papers.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_add_nom_price_papers.redeemed_papers" dense
								flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_add_nom_price_papers.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_add_nom_price_papers.dry_profit" dense flat />
						</td>
						<td>X</td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td><q-input class="inline" v-model="i_rez_cap_reorg.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_reorg.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_reorg.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_reorg.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_reorg.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_reorg.total" dense flat /></td>
					</tr>
					<tr>
						<td>Уменьшение капитала - всего:</td>
						<td><q-input class="inline" v-model="i_rez_cap_total_cap_decr.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_total_cap_decr.redeemed_papers" dense flat />
						</td>
						<td><q-input class="inline" v-model="i_rez_cap_total_cap_decr.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_total_cap_decr.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_total_cap_decr.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_total_cap_decr.total" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе: <br /> убыток</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_loss.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_loss.total" dense flat /></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_reval.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_reval.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_reval.total" dense flat /></td>
					</tr>
					<tr>
						<td>расходы, относящиеся непосредственно на уменьшение капитала</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_cap_decr.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_cap_decr.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_cap_decr.total" dense flat /></td>
					</tr>
					<tr>
						<td>уменьшение номинальной стоимости акций</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_price_papers.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_price_papers.redeemed_papers" dense flat />
						</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_price_papers.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_price_papers.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_price_papers.total" dense flat /></td>
					</tr>
					<tr>
						<td>уменьшение количества акций</td>
						<td><q-input class="inline" v-model="i_rez_cap_papers_decr.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_papers_decr.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_papers_decr.add_cap" dense flat /></td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_papers_decr.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_papers_decr.total" dense flat /></td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_reorg.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_reorg.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_reorg.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_reorg.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_reorg.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_reorg.total" dense flat /></td>
					</tr>
					<tr>
						<td>дивиденды</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_divid.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_divid.total" dense flat /></td>
					</tr>
					<tr>
						<td>Изменение добавочного капитала</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_cap_change.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_cap_change.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_cap_change.dry_profit" dense flat /></td>
						<td>X</td>
					</tr>
					<tr>
						<td>Изменение резервного капитала</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_change.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_change.dry_profit" dense flat /></td>
						<td>X</td>
					</tr>
					<tr>
						<td>Величина капитала на 31 декабря 2024 г</td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_value.ust_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_value.redeemed_papers" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_value.add_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_value.rez_cap" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_value.dry_profit" dense flat /></td>
						<td><q-input class="inline" v-model="i_rez_cap_decr_value.total" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				2. Корректировки в связи с изменением учетной политики и исправлением ошибок
			</div>

			<table class="table-cap" style="table-layout: fixed; max-width: 180mm; ">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th rowspan="2" style="width: 60mm;">Наименование показателя</th>
						<th rowspan="2" style="width: 30mm;">На 31 декабря 2024 г. </th>
						<th rowspan="1" colspan="2" style="width: 60mm;">Изменения капитала за 2024 г.</th>
						<th rowspan="2" style="width: 30mm;">На 31 декабря 2024 г.</th>
					</tr>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th rowspan="1" style="width: 30mm;">за счет чистой прибыли (убытка)</th>
						<th rowspan="1" style="width: 30mm;">за счет иных факторов</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Капитал - всего
							<br />до корректировок
						</td>
						<td><q-input class="inline" v-model="ii_cap_total_before_corr.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_before_corr.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_before_corr.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_before_corr.previous" dense flat /></td>
					</tr>
					<tr>
						<td>корректировка в связи с:
							<br />изменением учетной политики
						</td>
						<td><q-input class="inline" v-model="ii_cap_total_corr_polit.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_corr_polit.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_corr_polit.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_corr_polit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td><q-input class="inline" v-model="ii_cap_total_debug.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_debug.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_debug.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_debug.previous" dense flat /></td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td><q-input class="inline" v-model="ii_cap_total_after_corr.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_after_corr.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_after_corr.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_cap_total_after_corr.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе:<br />нераспределенная прибыль (непокрытый убыток):<br />до корректировок</td>
						<td><q-input class="inline" v-model="ii_income_undef_profit.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_undef_profit.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_undef_profit.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_undef_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>корректировка в связи с:<br />изменением учетной политики</td>
						<td><q-input class="inline" v-model="ii_income_corr_polit.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_corr_polit.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_corr_polit.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_corr_polit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td><q-input class="inline" v-model="ii_income_debug.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_debug.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_debug.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_debug.previous" dense flat /></td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td><q-input class="inline" v-model="ii_income_after_corr.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_after_corr.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_after_corr.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_income_after_corr.previous" dense flat /></td>
					</tr>
					<tr>
						<td>другие статьи капитала, по которым осуществлены корректировки:<br />до корректировок</td>
						<td><q-input class="inline" v-model="ii_other_before_corr.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_before_corr.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_before_corr.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_before_corr.previous" dense flat /></td>
					</tr>
					<tr>
						<td>корректировка в связи с:<br />изменением учетной политики</td>
						<td><q-input class="inline" v-model="ii_other_corr_polit.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_corr_polit.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_corr_polit.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_corr_polit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td><q-input class="inline" v-model="ii_other_debug.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_debug.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_debug.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_debug.previous" dense flat /></td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td><q-input class="inline" v-model="ii_other_after_corr.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_after_corr.previous_clear" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_after_corr.previous_other" dense flat /></td>
						<td><q-input class="inline" v-model="ii_other_after_corr.previous" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				3. Чистые активы
			</div>

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th style="width: 60mm;">Наименование показателя</th>
						<th style="width: 40mm;">На none 2024г.</th>
						<th style="width: 40mm;">На none 2024г.</th>
						<th style="width: 40mm;">На none 2024г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Чистые активы
						</td>
						<td><q-input class="inline" v-model="iii_clear_act.preprevious" dense flat /></td>
						<td><q-input class="inline" v-model="iii_clear_act.previous" dense flat /></td>
						<td><q-input class="inline" v-model="iii_clear_act.current" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div style="margin-top: 10mm;">
		<q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { nanoid } from "nanoid";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];

export default {
	name: "edit_document_report_cap_change",
	data() {
		this.emitter.on("onSaveDocument", (data) => {
			console.log(data);
			this.$q.loading.hide();
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Документ не был сохранен',
					cancel: false,
					persistent: true
				}).onOk(() => {
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				this.$q.notify('Документ был изменен');
				this.$router.back();
			}
		})
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			const body = response.data.body;
			this.organisation_name = body.organisation_name;
			this.organisation_inn = body.organisation_inn;
			this.organisation_econ = body.organisation_econ;
			this.organisation_form = body.organisation_form;
			this.document_date_unix = body.document_date_unix;
			this.document_date = body.document_date;
			this.currency_measure = body.currency_measure;
			this.i_cap_val = body.i_cap_val;
			this.i_cap_increase = body.i_cap_increase;
			this.i_clear_profit = body.i_clear_profit;
			this.i_reval_goods = body.i_reval_goods;
			this.i_income_cap_increase = body.i_income_cap_increase;
			this.i_add_papres_create = body.i_add_papres_create;
			this.i_papres_val_increase = body.i_papres_val_increase;
			this.i_nom_papers_price_reval = body.i_nom_papers_price_reval;
			this.i_cap_decrease = body.i_cap_decrease;
			this.i_cap_decr_loss = body.i_cap_decr_loss;
			this.i_cap_decr_reval = body.i_cap_decr_reval;
			this.i_cap_decr_cost = body.i_cap_decr_cost;
			this.i_cap_decr_papers_price_decr = body.i_cap_decr_papers_price_decr;
			this.i_cap_decr_papres_count = body.i_cap_decr_papres_count;
			this.i_cap_decr_reorg = body.i_cap_decr_reorg;
			this.i_cap_decr_divid = body.i_cap_decr_divid;
			this.i_cap_decr_add_cap_decr = body.i_cap_decr_add_cap_decr;
			this.i_rez_cap_change = body.i_rez_cap_change;
			this.i_rez_cap_value = body.i_rez_cap_value;
			this.i_total_cap_incr = body.i_total_cap_incr;
			this.i_rez_cap_clear_profit = body.i_rez_cap_clear_profit;
			this.i_tax_operations = body.i_tax_operations;
			this.i_rez_cap_income_cap_incr = body.i_rez_cap_income_cap_incr;
			this.i_rez_cap_add_papers_create = body.i_rez_cap_add_papers_create;
			this.i_rez_cap_add_nom_price_papers = body.i_rez_cap_add_nom_price_papers;
			this.i_rez_cap_reorg = body.i_rez_cap_reorg;
			this.i_rez_cap_total_cap_decr = body.i_rez_cap_total_cap_decr;
			this.i_rez_cap_loss = body.i_rez_cap_loss;
			this.i_rez_cap_reval = body.i_rez_cap_reval;
			this.i_rez_cap_cap_decr = body.i_rez_cap_cap_decr;
			this.i_rez_cap_decr_price_papers = body.i_rez_cap_decr_price_papers;
			this.i_rez_cap_papers_decr = body.i_rez_cap_papers_decr;
			this.i_rez_cap_decr_reorg = body.i_rez_cap_decr_reorg;
			this.i_rez_cap_decr_divid = body.i_rez_cap_decr_divid;
			this.i_rez_cap_decr_cap_change = body.i_rez_cap_decr_cap_change;
			this.i_rez_cap_decr_change = body.i_rez_cap_decr_change;
			this.i_rez_cap_decr_valuethis = body.i_rez_cap_decr_value;

			this.ii_cap_total_before_corr = body.ii_cap_total_before_corr;
			this.ii_cap_total_corr_polit = body.ii_cap_total_corr_polit;
			this.ii_cap_total_debug = body.ii_cap_total_debug;
			this.ii_cap_total_after_corr = body.ii_cap_total_after_corr;
			this.ii_income_undef_profit = body.ii_income_undef_profit;
			this.ii_income_corr_polit = body.ii_income_corr_polit;
			this.ii_income_debug = body.ii_income_debug;
			this.ii_income_after_corr = body.ii_income_after_corr;
			this.ii_other_before_corr = body.ii_other_before_corr;
			this.ii_other_corr_polit = body.ii_other_corr_polit;
			this.ii_other_debug = body.ii_other_debug;
			this.ii_other_after_corrthis = body.ii_other_after_corr;

			this.iii_clear_act = body.iii_clear_act;

			this.in_org_number = body.in_org_number;
			this.document_date = moment.unix(body.document_date_unix).format('DD.MM.YYYY');
			this.document_date_unix = body.document_date_unix;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data, 'agent')
			if (data.request_id === 'rec_inn') {
				this.rec = data.info.name.short_with_opf;
				this.rec_kpp = data.info.kpp;
			}
			if (data.request_id === 'plat_inn') {
				this.plat = data.info.name.short_with_opf;
				this.plat_kpp = data.info.kpp;
			}
		})
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				nanoid(),
				this.$route.params.id
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					nanoid(),
					this.$route.params.id
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			organisation_name: ref(null),
			organisation_inn: ref(null),
			organisation_econ: ref(null),
			organisation_form: ref(null),
			document_date_unix: ref(0),
			document_date: ref(null),
			currency_measure_options: ref(currency_measure_options),
			currency_measure: ref(currency_measure_options[0]),
			i_cap_val: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_increase: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_clear_profit: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_reval_goods: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_income_cap_increase: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_add_papres_create: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_papres_val_increase: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_nom_papers_price_reval: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decrease: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_loss: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_reval: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_cost: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_papers_price_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_papres_count: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_reorg: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_divid: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_cap_decr_add_cap_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_change: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_value: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_total_cap_incr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_clear_profit: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_tax_operations: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_income_cap_incr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_add_papers_create: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_add_nom_price_papers: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_reorg: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_total_cap_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_loss: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_reval: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_cap_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_decr_price_papers: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_papers_decr: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_decr_reorg: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_decr_divid: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_decr_cap_change: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_decr_change: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),
			i_rez_cap_decr_value: ref({ ust_cap: 0, redeemed_papers: 0, add_cap: 0, rez_cap: 0, dry_profit: 0, total: 0 }),

			ii_cap_total_before_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_cap_total_corr_polit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_cap_total_debug: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_cap_total_after_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_income_undef_profit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_income_corr_polit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_income_debug: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_income_after_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_other_before_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_other_corr_polit: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_other_debug: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),
			ii_other_after_corr: ref({ preprevious: 0, previous_clear: 0, previous_other: 0, previous: 0 }),

			iii_clear_act: ref({ current: 0, previous: 0, desc: '' }),
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		checkAgentBy(bik_val, field_id) {
			const bik = bik_val.replace(/\s/g, "");
			console.log(bik, field_id)
			if (bik.length < 9) {
				return;
			}
			this.connection.getAgentInfoByBik(
				field_id,
				bik
			);
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		checkAgentByInn(inn_val, field_id) {
			const inn = inn_val.replace(/\s/g, "");
			console.log(inn, field_id)
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				field_id,
				inn
			);
		},
		onSave() {
			const body = {
				organisation_name: this.organisation_name,
				organisation_inn: this.organisation_inn,
				organisation_econ: this.organisation_econ,
				organisation_form: this.organisation_form,
				currency_measure: this.currency_measure,
				i_cap_val: this.i_cap_val,
				i_cap_increase: this.i_cap_increase,
				i_clear_profit: this.i_clear_profit,
				i_reval_goods: this.i_reval_goods,
				i_income_cap_increase: this.i_income_cap_increase,
				i_add_papres_create: this.i_add_papres_create,
				i_papres_val_increase: this.i_papres_val_increase,
				i_nom_papers_price_reval: this.i_nom_papers_price_reval,
				i_cap_decrease: this.i_cap_decrease,
				i_cap_decr_loss: this.i_cap_decr_loss,
				i_cap_decr_reval: this.i_cap_decr_reval,
				i_cap_decr_cost: this.i_cap_decr_cost,
				i_cap_decr_papers_price_decr: this.i_cap_decr_papers_price_decr,
				i_cap_decr_papres_count: this.i_cap_decr_papres_count,
				i_cap_decr_reorg: this.i_cap_decr_reorg,
				i_cap_decr_divid: this.i_cap_decr_divid,
				i_cap_decr_add_cap_decr: this.i_cap_decr_add_cap_decr,
				i_rez_cap_change: this.i_rez_cap_change,
				i_rez_cap_value: this.i_rez_cap_value,
				i_total_cap_incr: this.i_total_cap_incr,
				i_rez_cap_clear_profit: this.i_rez_cap_clear_profit,
				i_tax_operations: this.i_tax_operations,
				i_rez_cap_income_cap_incr: this.i_rez_cap_income_cap_incr,
				i_rez_cap_add_papers_create: this.i_rez_cap_add_papers_create,
				i_rez_cap_add_nom_price_papers: this.i_rez_cap_add_nom_price_papers,
				i_rez_cap_reorg: this.i_rez_cap_reorg,
				i_rez_cap_total_cap_decr: this.i_rez_cap_total_cap_decr,
				i_rez_cap_loss: this.i_rez_cap_loss,
				i_rez_cap_reval: this.i_rez_cap_reval,
				i_rez_cap_cap_decr: this.i_rez_cap_cap_decr,
				i_rez_cap_decr_price_papers: this.i_rez_cap_decr_price_papers,
				i_rez_cap_papers_decr: this.i_rez_cap_papers_decr,
				i_rez_cap_decr_reorg: this.i_rez_cap_decr_reorg,
				i_rez_cap_decr_divid: this.i_rez_cap_decr_divid,
				i_rez_cap_decr_cap_change: this.i_rez_cap_decr_cap_change,
				i_rez_cap_decr_change: this.i_rez_cap_decr_change,
				i_rez_cap_decr_value: this.i_rez_cap_decr_value,

				ii_cap_total_before_corr: this.ii_cap_total_before_corr,
				ii_cap_total_corr_polit: this.ii_cap_total_corr_polit,
				ii_cap_total_debug: this.ii_cap_total_debug,
				ii_cap_total_after_corr: this.ii_cap_total_after_corr,
				ii_income_undef_profit: this.ii_income_undef_profit,
				ii_income_corr_polit: this.ii_income_corr_polit,
				ii_income_debug: this.ii_income_debug,
				ii_income_after_corr: this.ii_income_after_corr,
				ii_other_before_corr: this.ii_other_before_corr,
				ii_other_corr_polit: this.ii_other_corr_polit,
				ii_other_debug: this.ii_other_debug,
				ii_other_after_corr: this.ii_other_after_corr,

				iii_clear_act: this.iii_clear_act,

				document_date: this.document_date_unix,
				in_org_number: this.in_org_number,
				document_type: 'report',
				report_type: 'cap_change'
			};
			this.$q.loading.show();
			this.connection.createNewDocument(
				'1',
				body
			);

		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>
