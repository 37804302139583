<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="row">
    <div class="col">
      <div class="q-ma-md text-h5 row">
        <div class="col">
          {{ page_title }}
        </div>
        <div class="col" style="text-align: right;">
          <q-btn-dropdown color="primary" label="Создать" icon="add">
            <q-list>
              <q-item clickable v-close-popup @click="create_new_bill = true">
                <q-item-section>
                  <q-item-label>Счет</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </div>
      <q-table :rows="table_rows" :columns="table_columns" row-key="name" wrap-cells flat
        separator="cell" :pagination="tablePagination" @row-click="onRowClick" v-model="table_rows"
        :loading="isUpdatingData" loading-label="Обновление данных">
        <template v-slot:header="props">
          <q-tr>
            <q-th colspan="3" />
            <q-th colspan="2">
              Сальдо на начало периода
            </q-th>
            <q-th colspan="2">
              Обороты за период
            </q-th>
            <q-th colspan="2">
              Сальдо на конец периода
            </q-th>
          </q-tr>
          <q-tr :props="props">
            <q-th></q-th>
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
            <q-th />
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn size="sm" color="white" text-color="primary" :disable="props.row.subbils.length == 0" round flat
                @click="props.expand = !props.expand"
                :icon="props.expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" />
            </q-td>
            <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="onRowClick('', props.row, props)">
              <!-- <div > -->
              {{ col.value }}
              <!-- </div> -->

            </q-td>
            <q-td>
              <q-btn-dropdown flat dense icon="info" text-color="primary">
                <q-list>
                  <q-item clickable v-close-popup
                    @click="$router.push({ name: 'bill_card', params: { id: props.row.id } })">
                    <q-item-section>
                      <q-item-label>Карточка счета</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup
                    @click="$router.push({ name: 'bill_saldo', params: { id: props.row.id, bill_id: props.row.id, period_start: period_start_unix, period_end: period_end_unix } })">
                    <q-item-section>
                      <q-item-label>Оборотно-сальдовая ведомость</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" transition-show="jump-down" transition-hide="jump-up"
            v-for="subrow in props.row.subbils" :key="subrow.name" :props="props">
            <q-td></q-td>
            <q-td>{{ subrow.name }}</q-td>
            <q-td style="max-width: 340px; overflow-wrap: break-word;">{{ subrow.description }}</q-td>
            <q-td>{{ subrow.debet_start }}</q-td>
            <q-td>{{ subrow.credit_start }}</q-td>
            <q-td>{{ subrow.debet_period }}</q-td>
            <q-td>{{ subrow.credit_period }}</q-td>
            <q-td>{{ subrow.debet_end }}</q-td>
            <q-td>{{ subrow.credit_end }}</q-td>
            <q-td>
              <q-btn-dropdown flat dense icon="info" text-color="primary">
                <q-list>
                  <q-item clickable v-close-popup
                    @click="$router.push({ name: 'bill_card', params: { id: subrow.id } })">
                    <q-item-section>
                      <q-item-label>Карточка счета</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup
                    @click="$router.push({ name: 'bill_saldo', params: { id: subrow.id, bill_id: subrow.id, period_start: period_start_unix, period_end: period_end_unix } })">
                    <q-item-section>
                      <q-item-label>Оборотно-сальдовая ведомость</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </q-td>
          </q-tr>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-th colspan="2" />
            <q-th colspan="1" class="text-left text-bold">
              Итого
            </q-th>
            <q-th colspan="1" class="text-left text-bold">{{ summary.debet_start }}</q-th>
            <q-th colspan="1" class="text-left text-bold">{{ summary.credit_start }}</q-th>
            <q-th colspan="1" class="text-left text-bold">{{ summary.debet_period }}</q-th>
            <q-th colspan="1" class="text-left text-bold">{{ summary.credit_period }}</q-th>
            <q-th colspan="1" class="text-left text-bold">{{ summary.debet_end }}</q-th>
            <q-th colspan="1" class="text-left text-bold">{{ summary.credit_end }}</q-th>
          </q-tr>
          <q-tr>
            <q-th colspan="9" style="border: 2px black" />
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="col" style="max-width: 320px; padding-left: 16px;">
      <q-date v-model="period_range.from" flat minimal
        @update:model-value="(newValue) => { onPeriodChange(newValue) }" />
      <q-date v-model="period_range.to" flat minimal @update:model-value="(newValue) => { onPeriodChange(newValue) }" />
      <div v-if="period_range == null">
        Период не установлен
      </div>
      <div v-else>
        Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
      </div>
    </div>
  </div>
  <q-dialog v-model="create_new_bill" persistent>
    <create-new-bill :period_start="period_start_unix" :period_end="period_end_unix"></create-new-bill>
  </q-dialog>
</template>

<script>
import {ref} from "vue";
import CreateNewBill from "../../components/bills/create_new_bill"
import moment from "moment";

const table_columns = [
  { name: 'name', align: 'left', label: 'Счет', field: 'name', sortable: true},
  { name: 'description', align: 'left', label: 'Описание', field: 'description', style: 'max-width: 340px;', },
  { name: 'debet_start', align: 'left', label: 'Дебет', field: 'debet_start'},
  { name: 'credit_start', align: 'left', label: 'Кредит', field: 'credit_start'},
  { name: 'debet_period', align: 'left', label: 'Дебет', field: 'debet_period'},
  { name: 'credit_period', align: 'left', label: 'Кредит', field: 'credit_period'},
  { name: 'debet_end', align: 'left', label: 'Дебет', field: 'debet_end'},
  { name: 'credit_end', align: 'left', label: 'Кредит', field: 'credit_end'},
]

export default {
  name: "bills_plan",

  components: {
    CreateNewBill,
  },
  data() {
    const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
    const period_end = moment().endOf('day');
    const period_start_unix = period_start.unix();
    const period_end_unix = period_end.unix();
    this.emitter.on('didReceiveBillsList', (data) => {
      console.log(data.bills);
      this.table_rows = data.bills;
      this.bills_list = data.bills;
      this.summary = data.summary;
      this.isUpdatingData = false;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getBillsList(
          '1',
          period_start_unix,
          period_end_unix
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getBillsList(
            '1',
            period_start_unix,
            period_end_unix
        );
      })
    }
    const summary = {
        credit_start: 0,
        debet_start: 0,
        credit_period: 0,
        debet_period: 0,
        credit_end: 0,
        debet_end: 0
    };
    return {
      isUpdatingData: ref(true),
      table_rows: ref([]),
      bills_list: ref([]),
      summary: ref(summary),
      summ_bills: this.$store.state.base_summ_bills,
      filterBillsList: [],
      period_start_unix: ref(period_start_unix),
      period_end_unix: ref(period_end_unix),
      period_range: ref(this.$store.state.filter_period),
      page_title: ref('План счетов')
    }
  },
  mounted() {
    
  },
  created() {

  },
  setup() {
    moment.locale('ru');
    const filterBill = ref('Все');
    const activeBillsFilter = ref(false);

    const period_start_proxy = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end_proxy = ref(moment().endOf('day').format("YYYY/MM/DD"));
    const period_start = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end = ref(moment().endOf('day').format("YYYY/MM/DD"));
    console.log(period_start, period_end);
    return {
      filterBill,
      activeBillsFilter,
      create_new_bill: ref(false),
      create_new_transfer: ref(false),
      table_columns,

      tablePagination: {
        rowsPerPage: 0
      },
      period_start_proxy,
      period_end_proxy,
      period_start,
      period_end,

      tableRowFilter(item) {
        const summ = (parseInt(item.debet_start) || 0) +
            (parseInt(item.credit_start) || 0) +
            (parseInt(item.debet_period) || 0) +
            (parseInt(item.credit_period) || 0) +
            (parseInt(item.debet_end) || 0) +
            (parseInt(item.credit_end) || 0);
        console.log(summ || 0, 'lol2', activeBillsFilter.value)
        if (activeBillsFilter.value) {
          return (summ || 0) > 0
        } else {
          return true
        }
      },
    }
  },
  methods: {
    updateFilterStartProxy () {
      this.period_start_proxy = this.period_start
    },
    updateFilterEndProxy () {
      this.period_start_proxy = this.period_start
    },
    filterSaveStartProxy() {
      this.period_start = this.period_start_proxy;
      this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
      this.filterSave();
    },
    filterSaveEndProxy() {
      this.period_end = this.period_end_proxy;
      this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
      this.filterSave();
    },
    onRowClick(event, row) {
      console.log(row);
      // this.$router.push({ name: 'bill_info', params: row })
    },
    onSubRowClick(event, row) {
      console.log(row);
      // this.$router.push({ name: 'bill_info', params: row })
    },
    updateTableFilter() {
      this.table_rows = this.bills_list.filter(this.tableRowFilter)
    },
    filterSave () {
      this.isUpdatingData = true;
      if (this.connection.isAuthenticated) {
        this.connection.getBillsList(
            '1',
            moment(this.period_start).endOf('day').unix(),
            moment(this.period_end).endOf('day').unix()
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getBillsList(
              '1',
              moment(this.period_start).endOf('day').unix(),
              moment(this.period_end).endOf('day').unix()
          );
        })
      }
    },
    onPeriodChange(model_value) {
      console.log(model_value);
      if (model_value == null) {
        return;
      }
      this.$store.commit('updateFilterPeriod', model_value);
      this.isUpdatingData = true;
      if (this.connection.isAuthenticated) {
        this.connection.getBillsList(
          '1',
          moment(model_value.from).endOf('day').unix(),
          moment(model_value.to).endOf('day').unix(),
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getBillsList(
            '1',
            moment(model_value.from).endOf('day').unix(),
            moment(model_value.to).endOf('day').unix(),
          );
        })
      }
    },
    prettyDatePrint(date) {
      return moment(date).format('DD MMMM YYYY');
    }

  },
}
</script>

<style scoped>

</style>