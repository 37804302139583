<template>
  <q-page-container>
    <q-page class="flex flex-center bg-grey-2">
      <q-card class="register-card q-pa-lg">
        <q-card-section class="text-center">
          <div class="text-h4 text-weight-bold text-primary q-mb-md">SID ERP</div>
          <div class="text-subtitle1 text-grey-7">Создайте свой аккаунт</div>
        </q-card-section>

        <q-card-section>
          <q-form @submit="onSubmit" class="q-gutter-md">
            <q-input outlined v-model="email" label="Email" type="email" :rules="[
              val => !!val || 'Email обязателен',
              val => validateEmail(val) || 'Неверный формат email'
            ]">
              <template v-slot:prepend>
                <q-icon name="email" />
              </template>
            </q-input>

            <q-input outlined v-model="password" label="Пароль" :type="isPwd ? 'password' : 'text'" :rules="[
              val => !!val || 'Пароль должен быть больше 6 символов',
              val => val.length >= 6 || 'Пароль должен быть больше 6 символов'
            ]">
              <template v-slot:prepend>
                <q-icon name="lock" />
              </template>
              <template v-slot:append>
                <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                  @click="isPwd = !isPwd" />
              </template>
            </q-input>

            <q-input outlined v-model="confirmPassword" label="Подтвердите пароль" :type="isPwd ? 'password' : 'text'"
              :rules="[
                val => !!val || 'Пароли должны совпадать',
                val => val === password || 'Пароли должны совпадать'
              ]">
              <template v-slot:prepend>
                <q-icon name="lock" />
              </template>
            </q-input>

            <q-checkbox v-model="agreeTerms">
              <template v-slot:default>
                Я соглашаюсь с <a href="#" class="text-primary">правилами пользования системой</a>
              </template>
            </q-checkbox>

            <q-btn unelevated color="primary" size="lg" class="full-width q-mt-lg" label="Создать аккаунт" type="submit"
              :disable="!agreeTerms" />
          </q-form>
        </q-card-section>

        <q-card-section class="text-center q-pa-sm">
          <p class="text-grey-7">
            Уже есть аккаунт?
            <q-btn flat color="primary" label="Войти" size="sm" @click="$router.push({ name: 'signin', params: {} })" />
          </p>
        </q-card-section>

        <!-- <q-card-section class="text-center q-pa-none">
          <q-separator class="q-my-md" />
          <div class="text-grey-7 q-mb-sm">Or sign up with</div>
          <div class="row q-mt-sm justify-center">
            <q-btn flat round color="primary" icon="fab fa-google" class="q-mx-sm" />
            <q-btn flat round color="primary" icon="fab fa-facebook" class="q-mx-sm" />
            <q-btn flat round color="primary" icon="fab fa-twitter" class="q-mx-sm" />
          </div>
        </q-card-section> -->
      </q-card>
    </q-page>
  </q-page-container>
</template>

<script>

import { ref } from 'vue'
import { apiUrl } from "./../../config";
import axios from 'axios'

export default {
  name: 'register',
  data() {

    return {
      password: ref(null),
      showPassword: ref(false),
      email: ref(''),
      confirmPassword: ref(''),
      isPwd: ref(true),
      agreeTerms: ref(false)
    }
  },
  methods: {
    signin() {
      this.$router.push({ name: 'login', params: {} })
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    requestToken() {
      axios
        .post(apiUrl + '/user/token/request/', {}, {
          auth: {
            username: this.email,
            password: this.password
          }
        })
        .then((response) => {
          this.$q.loading.hide();
          if (response.data.token !== undefined) {
            this.connection.updateCredentials(this.$q, response.data.token, response.data.token_uuid);
            this.connection.makeConnection(this.$q)
            this.$router.push({ name: 'profile', params: {} });

            if (response.data.user_info) {
              this.emitter.emit('receiveUserInfo', response.data.user_info);
            }

          } else {
            this.password = null;
            this.$q.notify({
              progress: true,
              message: "Неизвестная ошибка",
              icon: 'error',
              color: 'white',
              textColor: 'negative'
            });
          }
        })
        .catch((error) => {
          this.$q.loading.hide();
          console.log(error);
          this.error_message_text = "Неверный email или пароль";
          this.$q.notify({
            color: 'negative',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Неверный email или пароль'
          });
        })
    },
    onSubmit() {
      this.$q.loading.show();
      axios
        .post(apiUrl + '/register/', {
          email: this.email,
          password: this.password
        })
        .then((response) => {
          if (response.data.success === 1) {
            this.requestToken();
          } else {
            this.$q.loading.hide();
            const error = response.data.error;
            var registerError = "Неизвестная ошибка";
            console.log(error);
            if (error == 'user-exist') {
              registerError = 'Такой email уже используется';
            } else if (error == 'invalid-email') {
              registerError = 'Такой email не существует';
            }
            this.password = null;
            this.error_message = true;
            this.error_message_text = registerError;
            this.$q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'cloud_done',
              message: registerError
            })
          }
        })
        .catch((error) => {
          this.$q.loading.hide();
          console.log(error)
          this.error_message = true;
          this.error_message_text = "Сервер не отвечает, повторите попытку позже";
        })

      

    }
  }
}

</script>

<style lang="scss" scoped>
.register-card {
  width: 100%;
  max-width: 450px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.q-field {
  &::v-deep(.q-field__control) {
    border-radius: 8px;
  }
}

.q-btn {
  border-radius: 8px;
}
</style>