<template>
  <q-page-container>

    <q-page class="flex flex-center bg-grey-2">
      <q-card class="login-card q-pa-lg">
        <q-card-section class="text-center">
          <div class="text-h4 text-weight-bold text-primary q-mb-md">SID ERP</div>
          <div class="text-subtitle1 text-grey-7">Войдите в свой аккаунт для продолжения.</div>
        </q-card-section>

        <q-card-section>
          <q-form @submit="onSubmit" class="q-gutter-md">
            <q-input outlined v-model="email" label="Email" type="email" :rules="[
              val => !!val || 'Введите email',
              val => validateEmail(val) || 'Неверный формат email'
            ]">
              <template v-slot:prepend>
                <q-icon name="email" />
              </template>
            </q-input>

            <q-input outlined v-model="password" label="Пароль" :type="isPwd ? 'password' : 'text'"
              :rules="[val => !!val || 'Введите пароль']">
              <template v-slot:prepend>
                <q-icon name="lock" />
              </template>
              <template v-slot:append>
                <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                  @click="isPwd = !isPwd" />
              </template>
            </q-input>

            <div class="row items-center justify-between q-mt-md">
              <q-checkbox v-model="rememberMe" label="Запомнить меня" />
              <q-btn flat color="primary" label="Забыли пароль?" size="sm" />
            </div>

            <q-btn unelevated color="primary" size="lg" class="full-width q-mt-lg" label="Войти" type="submit" />
          </q-form>
        </q-card-section>

        <q-card-section class="text-center q-pa-sm">
          <p class="text-grey-7">
            Нет аккаунта?
            <q-btn flat color="primary" label="Зарегистрироваться" size="sm" @click="$router.push({ name: 'signup', params: {} })" />
          </p>
        </q-card-section>

        <!-- <q-card-section class="text-center q-pa-none">
          <q-separator class="q-my-md" />
          <div class="text-grey-7 q-mb-sm">Or login with</div>
          <div class="row q-mt-sm justify-center">
            <q-btn flat round color="primary" icon="fab fa-google" class="q-mx-sm" />
            <q-btn flat round color="primary" icon="fab fa-facebook" class="q-mx-sm" />
            <q-btn flat round color="primary" icon="fab fa-twitter" class="q-mx-sm" />
          </div>
        </q-card-section> -->
      </q-card>
    </q-page>
  </q-page-container>
</template>

<script>
import { ref } from 'vue'
import { apiUrl } from "./../../config";
import axios from 'axios'

export default {
  name: 'login',
  data() {

    return {
      email: ref(null),
      password: ref(null),
      showPassword: ref(false),
      error_message: ref(false),
      error_message_text: ref(null),
      isPwd: ref(true),
      rememberMe: ref(false)
    }
  },
  methods: {
    signup() {
      this.$router.push({ name: 'register', params: {} })
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    onSubmit() {
      this.$q.loading.show();
      axios
        .post(apiUrl + '/user/token/request/', {}, {
          auth: {
            username: this.email,
            password: this.password
          }
        })
        .then((response) => {
          this.$q.loading.hide();
          if (response.data.token !== undefined) {
            this.connection.updateCredentials(this.$q, response.data.token, response.data.token_uuid);
            this.connection.makeConnection(this.$q)
            this.$router.push({ name: 'profile', params: {} });
            this.$q.notify('Вы успешно вошли в систему')
            if (response.data.user_info) {
              this.emitter.emit('receiveUserInfo', response.data.user_info);
            }
            
          } else {
            this.password = null;
            this.$q.notify({
              progress: true,
              message: "Неизвестная ошибка",
              icon: 'error',
              color: 'white',
              textColor: 'negative'
            });
          }
        })
        .catch((error) => {
          this.$q.loading.hide();
          console.log(error);
          this.error_message_text = "Неверный email или пароль";
          this.$q.notify({
            color: 'negative',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Неверный email или пароль'
          });
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-card {
  width: 100%;
  max-width: 450px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.q-field {
  &::v-deep(.q-field__control) {
    border-radius: 8px;
  }
}

.q-btn {
  border-radius: 8px;
}
</style>