<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="q-ma-md text-h5">
    {{ page_title }}
  </div>
  <div class="row">
    <div class="col-4">
      <div class="text-h6">
        Счет
      </div>
      <div class="q-pa-sm">
        <q-select style="width: 200px" flat v-model="selected_bill" :options="bills_list_options" />
      </div>
    </div>
    <div class="col-4">
      <div class="text-h6">
        Период
      </div>
      <div class="inline-block">
        <q-input style="width: 200px" label="Начало периода" flat v-model="period_start" mask="date"
          :rules="['date']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date v-model="period_start">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="inline-block">
        <q-input style="width: 200px" label="Конец периода" flat v-model="period_end" mask="date" :rules="['date']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date v-model="period_end">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>

    <div class="col-4">
      <div class="text-h6">
        Группировка
      </div>
      <div>
        <q-select label="Первый уровень" style="width: 200px" v-model="filter_1" :options="filter_options" />
      </div>

      <div style="margin-top: 18px">
        <q-select label="Второй уровень" style="width: 200px" v-model="filter_2" :options="filter2_options" />
      </div>
    </div>
  </div>
  <div>
    <div class="inline-block q-pa-sm">
      <q-btn @click="onRequestBillSaldo" unelevated color="primary" icon-right="code" text-color="white"
        label="Сформировать" />
    </div>
    <div class="inline-block q-pa-sm">
      <q-btn @click="onMakePdf" unelevated color="white" icon-right="download" text-color="primary" label="Печать" />
    </div>
  </div>


  <div id="print_content">
    <q-table :title="'Оборотно-сальдовая ведомость по счету ' + bill_name" :rows="table_rows" :columns="table_columns"
      row-key="name" flat separator="cell" :pagination="[0]" v-model="table_rows" :loading="isUpdatingData"
      loading-label="Обновление данных" no-data-label="Данные не загружены. Измените период или номер счета">
      <template v-slot:header="props">
        <q-tr>
          <q-th colspan="1" class="text-left">
            <div>{{ filter_1.label }}</div>
            <div class="q-pa-sm">
              <q-select style="width: 150px" flat dense @update:model-value="onFirstLevelFilterChanged"
                v-model="first_level_filter" :options="first_level_filter_values" />
            </div>
          </q-th>
          <q-th colspan="2">
            Сальдо на начало периода
          </q-th>
          <q-th colspan="2">
            Обороты за период
          </q-th>
          <q-th colspan="2">
            Сальдо на конец периода
          </q-th>
        </q-tr>
        <q-tr :props="props">
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" style="vertical-align: top">
          <q-td v-for="col in props.cols" :key="col.name" :props="props">
            <div v-if="col.name == 'name'">
              <div class="vertical-top">
                <div class="vertical-top">
                  {{ props.row.label }}
                </div>
                <div class="vertical-bottom text-grey">
                  Кол-во
                </div>
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="vertical-top">
                  {{ level1.label }}
                </div>
                <div class="vertical-bottom text-grey">
                  Кол-во
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">

                  <div class="vertical-top">
                    {{ level2.label }}
                  </div>
                  <div class="vertical-bottom text-grey">
                    Кол-во
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">

                    <div class="vertical-top">
                      {{ level3.label }}
                    </div>
                    <div class="vertical-bottom text-grey">
                      Кол-во
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="col.name == 'debet_start'" style="vertical-align: top;">
              <div class="text-right vertical-top">
                {{ props.row.debet_start }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ props.row.debet_start_count }}
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="text-right vertical-top">
                  {{ level1.debet_start === '' ? '&nbsp;' : level1.debet_start }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ level1.debet_start_count === '' ? '&nbsp;' : level1.debet_start_count }}
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">
                  <div class="text-right vertical-top">
                    {{ level2.debet_start === '' ? '&nbsp;' : level2.debet_start }}
                  </div>
                  <div class="text-right vertical-bottom text-grey">
                    {{ level2.debet_start_count === '' ? '&nbsp;' : level2.debet_start_count }}
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">
                    <div class="text-right vertical-top">
                      {{ level3.debet_start === '' ? '&nbsp;' : level3.debet_start }}
                    </div>
                    <div class="text-right vertical-bottom text-grey">
                      {{ level3.debet_start_count === '' ? '&nbsp;' : level3.debet_start_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="col.name == 'credit_start'">
              <div class="text-right vertical-top">
                {{ props.row.credit_start }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ props.row.credit_start_count }}
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="text-right vertical-top">
                  {{ level1.credit_start === '' ? '&nbsp;' : level1.credit_start }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ level1.credit_start_count === '' ? '&nbsp;' : level1.credit_start_count }}
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">
                  <div class="text-right vertical-top">
                    {{ level2.credit_start === '' ? '&nbsp;' : level2.credit_start }}
                  </div>
                  <div class="text-right vertical-bottom text-grey">
                    {{ level2.credit_start_count === '' ? '&nbsp;' : level2.credit_start_count }}
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">
                    <div class="text-right vertical-top">
                      {{ level3.credit_start === '' ? '&nbsp;' : level3.credit_start }}
                    </div>
                    <div class="text-right vertical-bottom text-grey">
                      {{ level3.credit_start_count === '' ? '&nbsp;' : level3.credit_start_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="col.name == 'debet_period'">
              <div class="text-right vertical-top">
                {{ props.row.debet_period }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ props.row.debet_period_count }}
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="text-right vertical-top">
                  {{ level1.debet_period === '' ? '&nbsp;' : level1.debet_period }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ level1.debet_period_count === '' ? '&nbsp;' : level1.debet_period_count }}
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">
                  <div class="text-right vertical-top">
                    {{ level2.debet_period === '' ? '&nbsp;' : level2.debet_period }}
                  </div>
                  <div class="text-right vertical-bottom text-grey">
                    {{ level2.debet_period_count === '' ? '&nbsp;' : level2.debet_period_count }}
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">
                    <div class="text-right vertical-top">
                      {{ level3.debet_period === '' ? '&nbsp;' : level3.debet_period }}
                    </div>
                    <div class="text-right vertical-bottom text-grey">
                      {{ level3.debet_period_count === '' ? '&nbsp;' : level3.debet_period_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="col.name == 'credit_period'">
              <div class="text-right vertical-top">
                {{ props.row.credit_period }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ props.row.credit_period_count }}
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="text-right vertical-top">
                  {{ level1.credit_period === '' ? '&nbsp;' : level1.credit_period }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ level1.credit_period_count === '' ? '&nbsp;' : level1.credit_period_count }}
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">
                  <div class="text-right vertical-top">
                    {{ level2.credit_period === '' ? '&nbsp;' : level2.credit_period }}
                  </div>
                  <div class="text-right vertical-bottom text-grey">
                    {{ level2.credit_period_count === '' ? '&nbsp;' : level2.credit_period_count }}
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">
                    <div class="text-right vertical-top">
                      {{ level3.credit_period === '' ? '&nbsp;' : level3.credit_period }}
                    </div>
                    <div class="text-right vertical-bottom text-grey">
                      {{ level3.credit_period_count === '' ? '&nbsp;' : level3.credit_period_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="col.name == 'debet_end'">
              <div class="text-right vertical-top">
                {{ props.row.debet_end }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ props.row.debet_end_count }}
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="text-right vertical-top">
                  {{ level1.debet_end === '' ? '&nbsp;' : level1.debet_end }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ level1.debet_end_count === '' ? '&nbsp;' : level1.debet_end_count }}
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">
                  <div class="text-right vertical-top">
                    {{ level2.debet_end === '' ? '&nbsp;' : level2.debet_end }}
                  </div>
                  <div class="text-right vertical-bottom text-grey">
                    {{ level2.debet_end_count === '' ? '&nbsp;' : level2.debet_end_count }}
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">
                    <div class="text-right vertical-top">
                      {{ level3.debet_end === '' ? '&nbsp;' : level3.debet_end }}
                    </div>
                    <div class="text-right vertical-bottom text-grey">
                      {{ level3.debet_end_count === '' ? '&nbsp;' : level3.debet_end_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="col.name == 'credit_end'">
              <div class="text-right vertical-top">
                {{ props.row.credit_end }}
              </div>
              <div class="text-right vertical-bottom text-grey">
                {{ props.row.credit_end_count }}
              </div>
              <div style="padding-left: 12px" v-for="level1 in props.row.childs" v-bind:key="level1.label">
                <div class="text-right vertical-top">
                  {{ level1.credit_end === '' ? '&nbsp;' : level1.credit_end }}
                </div>
                <div class="text-right vertical-bottom text-grey">
                  {{ level1.credit_end_count === '' ? '&nbsp;' : level1.credit_end_count }}
                </div>
                <div style="padding-left: 12px" v-for="level2 in level1.childs" v-bind:key="level2.id">
                  <div class="text-right vertical-top">
                    {{ level2.credit_end === '' ? '&nbsp;' : level2.credit_end }}
                  </div>
                  <div class="text-right vertical-bottom text-grey">
                    {{ level2.credit_end_count === '' ? '&nbsp;' : level2.credit_end_count }}
                  </div>
                  <div style="padding-left: 12px" v-for="level3 in level2.childs" v-bind:key="level3.id">
                    <div class="text-right vertical-top">
                      {{ level3.credit_end === '' ? '&nbsp;' : level3.credit_end }}
                    </div>
                    <div class="text-right vertical-bottom text-grey">
                      {{ level3.credit_end_count === '' ? '&nbsp;' : level3.credit_end_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// import FinanceBreadcrumb from "@/components/bills/finance_breadcrumb";
import {ref} from "vue";
import moment from "moment";
// import jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'

const table_columns = [
  { name: 'name', align: 'left', label: 'Субконто', field: 'name'},
  { name: 'debet_start', align: 'left', label: 'Дебет', field: 'debet_start'},
  { name: 'credit_start', align: 'left', label: 'Кредит', field: 'credit_start'},
  { name: 'debet_period', align: 'left', label: 'Дебет', field: 'debet_period'},
  { name: 'credit_period', align: 'left', label: 'Кредит', field: 'credit_period'},
  { name: 'debet_end', align: 'left', label: 'Дебет', field: 'debet_end'},
  { name: 'credit_end', align: 'left', label: 'Кредит', field: 'credit_end'},
]

const filters = [
  { label: 'Продавец', value: 'seller_inn' },
  { label: 'Покупатель', value: 'cust_inn' },
  { label: 'Товар', value: 'name_id'}
];

const filters2 = [
  { label: 'Документ', value: 'document' },
  { label: 'Нет', value: 'none' },
];

const summary = {
  debet_end: 0,
  credit_end: 0,
  saldo_debet_end: 0,
  saldo_debet_end_count: 0,
  saldo_credit_end: 0,
  saldo_credit_end_count: 0
}

export default {
  name: "bill_saldo_master",
  components: {
    // FinanceBreadcrumb
  },
  data() {
    this.emitter.on('didReceiveBillListFlatCompact', (data) => {
      this.bills_list_options = data.bills.map((element) => {
        return {label: element.full_name, value: element.id}
      });
      let bill = this.bill_id;
      if (bill) {
        let bill_selected_index = this.bills_list_options.findIndex(function (x) {
          return parseInt(x.value) === parseInt(bill);
        })
        this.selected_bill = this.bills_list_options[bill_selected_index];
      } else {
        this.selected_bill = this.bills_list_options[0];
      }

    });
    this.emitter.on('didReceiveBillSaldoList', (data) => {
      this.table_rows = data.data;
      this.summary = data.summary;
      this.isUpdatingData = false;
      this.bill_name = data.bill_name;
      this.first_level_filter_values = [{value: '__all', label: 'Без фильтрации'}].concat(
          data.first_level_filter_values.map((element => {
            return {
              label: element,
              value: element
            }
          }))
      );
    });
    if (this.connection.isAuthenticated) {
      this.connection.gitBillsListFlatCompact(
          '1'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.gitBillsListFlatCompact(
          '1'
        );
      })
    }
    return {
      step: ref(1),
      filter_1: ref(filters[0]),
      filter_2: ref(filters2[0]),
      filter_options: ref(filters),
      filter2_options: ref(filters2),
      period_start_proxy: ref(this.$store.state.filter_period.from),
      period_end_proxy: ref(this.$store.state.filter_period.to),
      period_start: ref(this.$store.state.filter_period.from),
      period_end: ref(this.$store.state.filter_period.to),
      isUpdatingData: ref(false),
      table_columns: ref(table_columns),
      table_rows: ref([]),
      summary: ref(summary),
      bill_name: ref(''),
      first_level_filter_values: ref(['__all']),
      first_level_filter: ref({value: '__all', label: 'Без фильтрации'}),
      bill_id: ref(this.$route.params.id),
      selected_bill: ref(null),
      bills_list_options: ref([]),
      page_title: ref('Оборотно-сальдовая ведомость')
    }
  },
  methods: {
    onFirstLevelFilterChanged(value) {
      this.connection.getBillSaldoList(
          '1',
          moment(this.period_start).startOf('day').unix(),
          moment(this.period_end).endOf('day').unix(),
          this.selected_bill.value,
          this.filter_1.value,
          this.filter_2.value,
          value.value
      );
    },
    safeValue(value) {
      return value === '' ? '&nbsp;' : value
    },
    updateFilterStartProxy() {
      this.period_start_proxy = this.period_start
    },
    updateFilterEndProxy() {
      this.period_start_proxy = this.period_start
    },
    filterSaveStartProxy() {
      this.period_start = this.period_start_proxy;
      this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
    },
    filterSaveEndProxy() {
      this.period_end = this.period_end_proxy;
      this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
    },
    onRequestBillSaldo() {
      this.step = 2;
      this.connection.getBillSaldoList(
          '1',
          moment(this.period_start).startOf('day').unix(),
          moment(this.period_end).endOf('day').unix(),
          this.selected_bill.value,
          this.filter_1.value,
          this.filter_2.value,
          this.first_level_filter.value
      );
    },
    onMakePdf() {
      let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

      mywindow.document.write(`<html><head><title></title>`);
      mywindow.document.write('</head><body >');
      mywindow.document.write(document.getElementById('print_content').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    }
  }
}
</script>

<style scoped>

</style>