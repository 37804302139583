import moment from "moment";

const store = {
    state: {
        department_structure_widget: {
            is_hidden: false,
            horizontal: false,
        },
        filter_period: JSON.parse(localStorage.getItem('filter_period')) || {
            from: moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"),
            to: moment().endOf('day').format("YYYY/MM/DD")
        },
    },
    mutations: {
        clearStore(state) {
            state.filter_period = {};
            state.department_structure_widget = {};
            localStorage.removeItem('filter_period');
            localStorage.removeItem('department_structure_widget');
        },
        updateFilterPeriod(state, period) {
            state.filter_period = period;
            localStorage.setItem('filter_period', JSON.stringify(period));
        },
        getOrCreateDepartmentWidgetSettings(state) {
            try {
                state.department_structure_widget = JSON.parse(localStorage.getItem('department_structure_widget'));
                if (Object.keys(state.department_structure_widget).length === 0) {
                    throw 'not-found'
                }
            } catch {
                state.department_structure_widget = {
                    is_hidden: false,
                    horizontal: false,
                };
                localStorage.setItem('department_structure_widget', JSON.stringify(state.department_structure_widget));
            }
        },
        updateDepartmentWidgetSettings(state, data) {
            try {
                state.department_structure_widget.is_hidden = data.is_hidden;
                state.department_structure_widget.horizontal = data.horizontal;
                localStorage.setItem('department_structure_widget', JSON.stringify(state.department_structure_widget));
            } catch {
                console.log('undefined keys for action updateDepartmentWidgetSettings')
            }
        },
    },
    modules: {}

}

export default store