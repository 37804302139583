<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col" style="max-width: 500px;">
			<q-list padding>
				<q-item>
					<q-item-section>
						<q-item-label>Шаблоны проводок</q-item-label>
						<q-item-label caption>Настройка автоматических проводок для документов</q-item-label>
					</q-item-section>

					<q-item-section side top>
						<q-item-label caption>
							<q-btn label="Настроить" flat dense
								@click="$router.push({ name: 'transfer_templates_list', params: {} })" />
						</q-item-label>
					</q-item-section>
				</q-item>
				<q-item>
					<q-item-section>
						<q-item-label>Перенос данных</q-item-label>
						<q-item-label caption>Внесение остатков с других систем бухучета</q-item-label>
					</q-item-section>

					<q-item-section side top>
						<q-item-label caption>
							<q-btn label="Перенести" flat dense
								@click="$router.push({ name: 'initial_transfers_master', params: {} })" />
						</q-item-label>
					</q-item-section>
				</q-item>

				<!-- <q-separator spaced />
				<q-item-label header>Доступные интеграции</q-item-label>

				<q-item>
					<q-item-section>
						<q-item-label>СберБизнес</q-item-label>
						<q-item-label caption>Интеграция с API Sber не настроена</q-item-label>
					</q-item-section>

					<q-item-section side top>
						<q-item-label caption>
							<q-btn label="Настроить" flat dense @click="sber_api_key_dialog = true" />
						</q-item-label>
					</q-item-section>
				</q-item> -->
			</q-list>
		</div>
	</div>

	<q-dialog v-model="sber_api_key_dialog" persistent>
		<q-card>
			<q-card-section style="min-width: 350px; min-height: 160px;">
				<q-input v-model="sber_api_key" label="СберБизнес API ключ"
					hint="Используется для передачи запросов по каналу API на получение или отправку документов партнера напрямую из его учетной системы без необходимости заходить в клиент-банк, что ускоряет и упрощает процесс ведения бизнеса и минимизирует ошибки при работе с документами." />

			</q-card-section>
			<q-card-actions align="right" class="text-primary">
				<q-btn flat label="Отмена" v-close-popup />
				<q-btn flat label="Сохранить" v-close-popup @click="saveApiKey()" />
			</q-card-actions>
		</q-card>
	</q-dialog>

</template>

<script>
import { ref } from 'vue'
import SberApiKeyWidget from '../../components/widgets/sber_id_api_key_settings.vue';

export default {
	name: "org_main",
	components: [
		SberApiKeyWidget
	],
	data() {
		
		return {
			sber_api_key: ref(null),
			sber_api_key_dialog: ref(false),
			page_title: ref('Настройки бухгалтерии'),
		}
	},
	methods: {

	}
}
</script>

<style></style>