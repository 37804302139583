<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col-4">
			<div class="text-h6">
				Контрагент
			</div>
			<div class="q-pa-sm">
				<q-input style="max-width: 200px;" dense mask="## ## ###### ##" v-model="selected_inn" label="ИНН / КПП"
					@update:model-value="checkAgentBy(selected_inn)" />
			</div>
		</div>
		<div class="col-4">
			<div class="text-h6">
				Период
			</div>
			<div class="inline-block">
				<q-input style="width: 200px" label="Начало периода" flat v-model="period_start" mask="date"
					:rules="['date']">
					<template v-slot:append>
						<q-icon name="event" class="cursor-pointer">
							<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
								<q-date v-model="period_start">
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Закрыть" color="primary" flat />
									</div>
								</q-date>
							</q-popup-proxy>
						</q-icon>
					</template>
				</q-input>
			</div>
			<div class="inline-block">
				<q-input style="width: 200px" label="Конец периода" flat v-model="period_end" mask="date"
					:rules="['date']">
					<template v-slot:append>
						<q-icon name="event" class="cursor-pointer">
							<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
								<q-date v-model="period_end">
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Закрыть" color="primary" flat />
									</div>
								</q-date>
							</q-popup-proxy>
						</q-icon>
					</template>
				</q-input>
			</div>
		</div>

		<div class="col-4">

		</div>
	</div>
	<div>
		<div class="inline-block q-pa-sm">
			<q-btn :disable="!can_request_act" @click="requestAct" unelevated color="primary" icon-right="code"
				text-color="white" label="Сформировать" />
		</div>
		<!-- <div class="inline-block q-pa-sm">
			<q-btn @click="onMakePdf" unelevated color="white" icon-right="download" text-color="primary"
				label="Печать" />
		</div> -->
	</div>


	<div id="print_content">
		<q-table v-show="show_table" :title="'Акт сверки взаиморасчетов'" :rows="table_rows" :columns="table_columns"
			row-key="name" flat separator="cell" :pagination="[0]" v-model="table_rows" :loading="isUpdatingData"
			loading-label="Обновление данных" no-data-label="Данные не загружены. Измените период или ИНН контрагента">
			<template v-slot:header="props">
				<q-tr>

					<q-th colspan="2">
						По данным {{ my_org_name }}
					</q-th>
					<q-th colspan="2">
						Российский рубль
					</q-th>
					<q-th colspan="2">
						По данным {{ contr_org_name }}
					</q-th>
					<q-th colspan="2">
						Российский рубль
					</q-th>
				</q-tr>
				<q-tr :props="props">
					<q-th v-for="col in props.cols" :key="col.name" :props="props">
						{{ col.label }}
					</q-th>
				</q-tr>
				<q-tr>

					<q-th colspan="2">
						Сальдо начальное
					</q-th>
					<q-th colspan="1">
						{{ total_before.my_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ total_before.my_total_credit }}
					</q-th>
					<q-th colspan="2">
						Сальдо начальное
					</q-th>
					<q-th colspan="1">
						{{ total_before.contr_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ total_before.contr_total_credit }}
					</q-th>
				</q-tr>
			</template>
			<template v-slot:bottom-row>
				<q-tr>
					<q-th colspan="2">
						Сальдо конечное
					</q-th>
					<q-th colspan="1">
						{{ total.my_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ total.my_total_credit }}
					</q-th>
					<q-th colspan="2">
						Сальдо конечное
					</q-th>
					<q-th colspan="1">
						{{ total.contr_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ total.contr_total_credit }}
					</q-th>
				</q-tr>
			</template>
		</q-table>
	</div>
	<!-- <div class="row">
		<div class="col">
			<q-table wrap-cells flat borderless :rows="doc_table_rows" :columns="doc_table_columns" row-key="name"
				separator="cell" :pagination="doc_tablePagination" @row-click="doc_onRowClick" v-model="doc_table_rows"
				:loading="doc_isUpdatingData">
				
				<template v-slot:body-cell-nds_period="props">
					<q-td :props="props">
						<q-chip color="info" text-color="white">
							{{ prettyPrintNdsPeriod(props.value) }}
						</q-chip>
					</q-td>
				</template>

			</q-table>
		</div>
		<div class="col" style="max-width: 320px; padding-left: 16px;">
			<q-date v-model="period_range.from" flat minimal
				@update:model-value="(newValue) => { onPeriodChange({ from: newValue, to: period_range.to }) }" />
			<q-date v-model="period_range.to" flat minimal
				@update:model-value="(newValue) => { onPeriodChange({ from: period_range.from, to: newValue }) }" />
			<div v-if="period_range == null">
				Период не установлен
			</div>
			<div v-else>
				Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
			</div>
		</div>
	</div> -->
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const table_columns = [
	{ name: 'my_date', align: 'left', label: 'Дата', field: 'my_date' },
	{ name: 'my_name', align: 'left', label: 'Документ', field: 'my_name' },
	{ name: 'my_debet', align: 'left', label: 'Дебет', field: 'my_debet' },
	{ name: 'my_credit', align: 'left', label: 'Кредит', field: 'my_credit' },
	{ name: 'contr_date', align: 'left', label: 'Дата', field: 'contr_date' },
	{ name: 'contr_name', align: 'left', label: 'Документ', field: 'contr_name' },
	{ name: 'contr_debet', align: 'left', label: 'Дебет', field: 'contr_debet' },
	{ name: 'contr_credit', align: 'left', label: 'Кредит', field: 'contr_credit' },
]

const doc_table_columns = [
	{ name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
	{ name: 'name', align: 'left', label: 'Название', field: 'name' },
	{ name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
	{ name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
	{ name: 'nds_period', align: 'left', label: 'Период НДС', field: 'nds_period', sortable: true }
]

const summary = {
	debet_end: 0,
	credit_end: 0,
	saldo_debet_end: 0,
	saldo_debet_end_count: 0,
	saldo_credit_end: 0,
	saldo_credit_end_count: 0
}
const year_options = [
	{ value: '2025', label: '2025' },
	{ value: '2024', label: '2024' },
	{ value: '2023', label: '2023' },
	{ value: '2022', label: '2022' },
];
const quarter_options = [
	{ value: 1, label: '1 квартал' },
	{ value: 2, label: '2 квартал' },
	{ value: 3, label: '3 квартал' },
	{ value: 4, label: '4 квартал' },
];
export default {
	name: "act_check_master",
	data() {
		this.emitter.on('didReceoveUserInfoAcrossOrg', (data) => {
			const index = data.user.organisations.findIndex((x) => x.id == this.connection.selectedOrgId(this.$q));
			this.my_org_name = data.user.organisations[index].name;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data);
			if (data.error === undefined) {
				this.contr_org_name = data.info.name.short_with_opf;
				this.can_request_act = true;
			} else {
				this.can_request_act = false;
			}
			
		})
		this.emitter.on('onCreateActSver', (response) => {
			const data = response.data;
            if (response.type === 'result') {
                this.show_table = true;
                this.table_rows = data.rows;
                this.total = data.total;
				this.total_before = data.total_before;
            } else {
				this.show_table = false;
			}
		});
		var range = this.$store.state.filter_period;

		if (this.connection.isAuthenticated) {
			this.connection.getUserInfoAcrossOrg('1');
			this.connection.getDocumentsList(
				'1',
				moment(range.from).startOf('day').unix(),
				moment(range.to).endOf('day').unix(),
				'all',
				'act_sver',
				true
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getUserInfoAcrossOrg('1');
				this.connection.getDocumentsList(
					'1',
					moment(range.from).startOf('day').unix(),
					moment(range.to).endOf('day').unix(),
					'all',
					'act_sver',
					true
				);
			});
		}
		this.emitter.on('didReceiveDocumentsList', (data) => {
			// console.log(data)
			// this.updateQuarterYearsOptions();
			this.doc_table_rows = data.data;
			this.doc_isUpdatingData = false;
		})
		
		return {
			step: ref(1),
			period_start_proxy: ref(this.$store.state.filter_period.from),
			period_end_proxy: ref(this.$store.state.filter_period.to),
			period_start: ref(this.$store.state.filter_period.from),
			period_end: ref(this.$store.state.filter_period.to),
			isUpdatingData: ref(false),
			table_columns: ref(table_columns),
			table_rows: ref([]),
			summary: ref(summary),
			bill_name: ref(''),
			first_level_filter_values: ref(['__all']),
			first_level_filter: ref({ value: '__all', label: 'Без фильтрации' }),
			bill_id: ref(this.$route.params.id),
			selected_bill: ref(null),
			bills_list_options: ref([]),
			my_org_name: ref(null),
			contr_org_name: ref('не выбрано'),
			selected_inn: ref(null),
			show_table: ref(false),
			can_request_act: ref(false),
			total: ref({
				'my_total_debet': 0,
				'my_total_credit': 0,
				'contr_total_debet': 0,
				'contr_total_credit': 0,
			}),
			total_before: ref({
				'my_total_debet': 0,
				'my_total_credit': 0,
				'contr_total_debet': 0,
				'contr_total_credit': 0,
			}),
			period_range: ref(range),
			tablePagination: {
				sortBy: 'date',
				descending: true,
				page: 1,
				rowsPerPage: 50
			},
			page_title: ref('Акт сверки'),
			doc_table_rows: ref([]),
			doc_table_columns: ref(doc_table_columns),
			doc_tablePagination: {
				sortBy: 'date',
				descending: true,
				page: 1,
				rowsPerPage: 50
			},
			doc_isUpdatingData: ref(true),
			quarter_options: quarter_options,
			year_options: year_options,
			quarter_years_options: ref([])
		}
	},
	methods: {
		prettyPrintNdsPeriod(value) {
			const nds_period_index = this.quarter_years_options.findIndex(x => x.value == value);
			if (nds_period_index >= 0) {
				return this.quarter_years_options[nds_period_index].label;
			} else {
				return "Неизвестно";
			}
		},
		updateQuarterYearsOptions() {
			const years = [
				{ value: '2025', label: '2025 года' },
				{ value: '2024', label: '2024 года' },
				{ value: '2023', label: '2023 года' },
				{ value: '2022', label: '2022 года' },
			];
			const quarters = [
				{ value: 1, label: '1 квартал' },
				{ value: 2, label: '2 квартал' },
				{ value: 3, label: '3 квартал' },
				{ value: 4, label: '4 квартал' },
			]
			var options = [{ value: null, label: 'Не учтен' }];
			const current_year = String(moment().year());
			const current_quarter = moment().quarter();
			years.forEach(year => {
				quarters.forEach(quarter => {
					if (year.value === current_year) {
						if (quarter.value <= current_quarter) {
							options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
						}
					} else {
						options.push({ value: `${quarter.value}${year.value}`, label: `${quarter.label} ${year.label}` });
					}
				});
			});
			this.quarter_years_options = options;
		},
		safeValue(value) {
			return value === '' ? '&nbsp;' : value
		},
		updateFilterStartProxy() {
			this.period_start_proxy = this.period_start
		},
		updateFilterEndProxy() {
			this.period_start_proxy = this.period_start
		},
		filterSaveStartProxy() {
			this.period_start = this.period_start_proxy;
			this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
		},
		filterSaveEndProxy() {
			this.period_end = this.period_end_proxy;
			this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
		},
		requestAct() {
			const inn = this.selected_inn.replace(/\s/g, "");
			if (inn.length < 9) {
				return;
			}
			const additional_data = {
				my_org_name: this.my_org_name,
				contr_org_name: this.contr_org_name,
			};
			this.connection.createActSver(
				'1',
				inn,
				moment(this.period_start).startOf('day').unix(),
				moment(this.period_end).endOf('day').unix(),
				additional_data
			)
		},
		onMakePdf() {
			return true;
		},
		checkAgentBy(inn_val) {
			const inn = inn_val.replace(/\s/g, "");
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				'org_inn',
				inn
			);
		},
		doc_onRowClick(event, row) {
			if (row.document_type === 'upd') {
				this.$router.push({ name: 'document_info_upd', params: { id: row.id, history_id: 'last' } })
			} else if (row.document_type === 'avance') {
				this.$router.push({ name: 'document_info_factura_avance', params: { id: row.id, history_id: 'last' } })
			} else if (row.document_type === 'accept_factura_avance') {
				this.$router.push({ name: 'document_info_factura_avance', params: { id: row.id, history_id: 'last' } })
			} else if (row.document_type === 'bill_to_pay') {
				this.$router.push({ name: 'document_info_bill_to_pay', params: { id: row.id, history_id: 'last' } })
			} else if (row.document_type === 'act_sver') {
				this.$router.push({ name: 'document_info_act_sver', params: { id: row.id, history_id: 'last' } })
			}

		},
		onPeriodChange(model_value) {
			console.log(model_value);
			if (model_value == null) {
				return;
			}
			this.$store.commit('updateFilterPeriod', model_value);
			this.isUpdatingData = true;
			if (this.connection.isAuthenticated) {
				this.connection.getDocumentsList(
					'1',
					moment(model_value.from).endOf('day').unix(),
					moment(model_value.to).endOf('day').unix(),
					'all',
					'act_sver',
					true
				);
			} else {
				this.emitter.on('connectionReady', () => {
					this.connection.getDocumentsList(
						'1',
						moment(model_value.from).endOf('day').unix(),
						moment(model_value.to).endOf('day').unix(),
						'all',
						'act_sver',
						true
					);
				})
			}
		},
		prettyDatePrint(date) {
			return moment(date).format('DD MMMM YYYY');
		}
	}
}
</script>

<style scoped></style>