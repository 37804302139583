<template>

	<div class="row">
		<div class="col-9">
			<dir class="q-ma-sm">
				<router-view />
			</dir>
		</div>
		<div class="col-3">
			<q-list>
				<q-item-label header>История исправлений</q-item-label>

				<q-item v-for="item in changes" v-bind:key="item.id">
					<q-item-section>
						<q-item-label>{{ item.user }}</q-item-label>
						<q-item-label caption>{{ item.message }}</q-item-label>
					</q-item-section>
					<q-item-section side top>
						<q-item-label caption>{{ item.date }}</q-item-label>
					</q-item-section>
					<q-item-section side>
						<q-btn icon="save" dense flat />
					</q-item-section>
				</q-item>
			</q-list>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

export default {
	name: "report_edit_root",
	data() {
		this.emitter.on('didReceiveDocumentInfoShort', (data) => {
			// const changes = response.data.changes;
			console.log(data.body);
			this.name = data.data.name;
			this.changes = data.data.changes;
			this.transfers = data.data.transfers;
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data);
			this.transfers = data.data.transfers;
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfoShort(
				'1',
				this.$route.params.id
			);
			this.connection.gitBillsListFlatCompact(
				'1'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfoShort(
					'1',
					this.$route.params.id
				);
				this.connection.gitBillsListFlatCompact(
					'1'
				);
			})
		}
		return {
			name: ref(''),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null)
		}
	},
	methods: {
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				item_id,
				value,
				direction
			)
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
	}
}
</script>

<style scoped></style>