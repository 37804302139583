<template>
  <div>
    <q-breadcrumbs>
      <q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
      <q-breadcrumbs-el label="Структура организации" @click="$router.push({ name: 'org_main', params: {} })" />
      <q-breadcrumbs-el :label="page_title" />
    </q-breadcrumbs>
  </div>
  <div class="q-ma-md text-h5">
    {{ page_title }}
  </div>
  <div class="row">
    <div class="col" style="max-width: 500px;">
      <q-list>
        <q-item-label>Настройки</q-item-label>
        <q-item>
          <q-item-section>
            <q-select :disable="!can_save_role" v-model="parent" :options="parents" label="Руководитель" />
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-input :disable="!can_save_role" v-model="role_title" label="Название должности" />
          </q-item-section>
        </q-item>
        <q-item-label>Права доступа</q-item-label>
        <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox v-model="edit_org" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Структура организации</q-item-label>
          </q-item-section>
        </q-item>
        <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox v-model="change_billing" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Тарифный план</q-item-label>
          </q-item-section>
        </q-item>
        <!-- <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox v-model="open_fin_documents" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Документы</q-item-label>
          </q-item-section>
        </q-item> -->
        <q-item tag="label" v-ripple>
          <q-item-section avatar top>
            <q-checkbox v-model="edit_fin_documents" color="primary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Бухгалтерия</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-ripple>
          <q-item-section>
            <q-item-label>
              <q-btn v-if="can_save_role" label="Сохранить" @click="addRole" color="primary" />
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import { useQuasar } from 'quasar'

export default {
  name: "new_settings",
  mounted() {

  },
  data() {
    
    if (this.connection.isAuthenticated) {
      this.connection.getOrgStructureFlat(
        '1'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getOrgStructureFlat(
          '1'
        );
      })
    }
    this.emitter.on("didReceiveOrgStructureFlat", (data) => {
      console.log(data);
      this.organisation_flat = data;
      this.parents = this.organisation_flat.roles.map(function (x) { return {label: x.label, id: x.id} } );
      this.options = this.organisation_flat.roles.map(function (x) { return x.label } )
      // this.parent = this.parents[0].label
    });
    const $q = useQuasar();
    this.emitter.on("didCreateNewRole", (data) => {
      console.log(data);
      this.role_id = data.role_id;
      $q.notify('Новая роль успешно создана');
      this.$router.push({ name: 'org_role_settings', params: { id: data.role_id } })
    });
    this.emitter.on("didInviteUserToOrg", (data) => {
      console.log(data);
      this.can_invite_user = false;
      $q.notify('Приглашение было успешно отправлено');
    });
    return {
      can_save_role: ref(true),
      can_invite_user: ref(false),
      prompt: ref(false),
      organisation_flat: ref({roles: []}),
      parents: [],
      options: ref([]),
      parent: ref(''),
      role_title: ref(''),
      edit_org: ref(false),
      change_billing: ref(false),
      open_fin_documents: ref(false),
      edit_fin_documents: ref(false),
      role_id: ref(null),
      page_title: ref('Новое рабочее место')
    }
  },
  methods: {
    inviteUserToOrganisation() {
      const data = {
        email: this.email_invite,
        role_id: this.role_id
      }
      this.connection.inviteUserToOrg('1', data)
      this.email_invite = null
    },
    addRole() {
      const data = {
        role_title: this.role_title,
        parent_role_id: this.parent.id,
        edit_org: this.edit_org,
        change_billing: this.change_billing,
        open_fin_documents: this.edit_fin_documents,
        edit_fin_documents: this.edit_fin_documents,
      }
      console.log(data)
      this.connection.createNewRole('1', data);
    }
  }
}
</script>

<style scoped>

</style>