<template>
  <div class="container">
    <div class="row">
      <div class="col"> <q-input dense float borderless v-model="postup_bank_plat" label="Назначение платежа" /></div>
      <div class="col"> <q-input dense float borderless v-model="spis_sch_plat" label="Отметки банка" /></div>
    </div>

    <div class="row">
      <div class="col-6 text-subtitle1 " style="padding-top: 16px;">ПЛАТЕЖНОЕ ПОРУЧЕНИЕ № <q-input class="inline"
          style="width: 60px;" dense flat v-model="in_org_number" /></div>
      <div class="col-3 q-pa-md">
        <q-input dense flat label="Дата" v-model="document_date">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date mask="DD.MM.YYYY" v-model="document_date"
                  @update:model-value="(newValue) => onDateChanged(newValue)">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Закрыть" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-pa-md">
        <q-input dense flat v-model="plat_kind" label="Вид платежа" />
      </div>
      <!-- <div class="rectangle">0401060</div> -->
    </div>
    <table>
      <tbody>

        <tr>
          <td class="no-left-border" colspan="2" rowspan="1">Сумма прописью</td>
          <td class="no-right-border" colspan="10" rowspan="1">
            {{ summ_letter }}
          </td>
        </tr>
        <tr>
          <td class="no-left-border" colspan="3"><q-input dense float borderless mask="## ## ###### ##"
              v-model="plat_inn" label="ИНН" @update:model-value="checkAgentByInn(plat_inn, 'plat_inn')" /></td>
          <td colspan="2"><q-input dense float borderless v-model="plat_kpp" label="КПП" /></td>
          <td colspan="2" rowspan="2">Сумма</td>
          <td class="no-right-border" colspan="5" rowspan="2"><q-input dense float borderless
              @update:model-value="(value) => onUpdateSummLetter(value)" v-model="summ" label="Сумма" />
          </td>
        </tr>
        <tr>
          <td class="no-left-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="plat"
              label="Плательщик" /></td>
        </tr>
        <tr>
          <td colspan="2" rowspan="1">Сч. №</td>
          <td class="no-right-border" colspan="5" rowspan="3">
            <div>
              <q-input dense float borderless v-model="plat_bill_num" label="Сч. №" />
            </div>
            <div>
              <q-input dense float borderless v-model="plat_bik" mask="## ## ## ###" label="БИК"
                @update:model-value="checkAgentBy(plat_bik, 'plat_bik')" />
            </div>
            <div>
              <q-input dense float borderless v-model="plat_bill_num_ext" label="Сч. №" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="no-left-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="plat_bank"
              label="Банк плательщика" /></td>
          <td colspan="2">БИК</td>
        </tr>
        <tr>
          <td colspan="2">Сч. №</td>
        </tr>
        <tr>
          <td class="no-left-border" colspan="5" rowspan="2"><q-input dense float borderless v-model="rec_bank"
              label="Банк получателя" /></td>
          <td colspan="2">БИК</td>

          <td class="no-right-border" colspan="5" rowspan="4">
            <div>
              <q-input dense float borderless v-model="rec_bik" label="БИК" mask="## ## ## ###"
                @update:model-value="checkAgentBy(rec_bik, 'rec_bik')" />
            </div>
            <div>
              <q-input dense float borderless v-model="rec_bill_num" label="Сч. №" />
            </div>
            <div>
              <q-input dense float borderless v-model="rec_bill_num_ext" label="Сч. №" />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">Сч. №</td>
        </tr>
        <tr>
          <td class="no-left-border" colspan="3"><q-input dense float borderless mask="## ## ###### ##"
              v-model="rec_inn" label="ИНН" @update:model-value="checkAgentByInn(rec_inn, 'rec_inn')" /></td>
          <td colspan="2"><q-input dense float borderless v-model="rec_kpp" label="КПП" /></td>
          <td colspan="2" rowspan="2">Сч. №</td>
        </tr>
        <tr>
          <td class="no-left-border" colspan="5" rowspan="4"><q-input dense float borderless v-model="rec"
              label="Получатель" /></td>
        </tr>
        <tr>
          <td colspan="2">Вид. оп</td>
          <td colspan="2" rowspan="3">
            <q-input dense float borderless v-model="vid_op" label="Вид. оп" />
            <q-input dense float borderless v-model="naz_pl" label="Наз. пл." />
            <q-input dense float borderless v-model="code" label="Код" />
          </td>
          <td colspan="2">Срок плат.</td>
          <td class="no-right-border" colspan="" rowspan="3">
            <q-input dense float borderless v-model="ts_plat" label="Срок плат." />
            <q-input dense float borderless v-model="naz_pl_ext" label="Очер. плат." />
            <q-input dense float borderless v-model="rez_field" label="Рез. поле" />
          </td>
        </tr>
        <tr>
          <td colspan="2">Наз. пл.</td>
          <!-- <td colspan="2"><q-input /></td> -->
          <td colspan="2">Очер. плат.</td>
          <!-- <td colspan=""><q-input /></td> -->
        </tr>
        <tr>
          <td colspan="2">Код</td>
          <!-- <td colspan="2"><q-input /></td> -->
          <td colspan="2">Рез. поле</td>
          <!-- <td colspan="1"><q-input /></td> -->
        </tr>
        <tr>
          <td class="no-left-border" colspan="2"><q-input dense float borderless v-model="ext_field_1" /></td>
          <td><q-input dense float borderless v-model="ext_field_2" /></td>
          <td><q-input dense float borderless v-model="ext_field_3" /></td>
          <td colspan="2"><q-input dense float borderless v-model="ext_field_4" /></td>
          <td colspan="2"><q-input dense float borderless v-model="ext_field_5" /></td>
          <td colspan="2"><q-input dense float borderless v-model="ext_field_6" /></td>
          <td colspan="2" class="no-right-border"><q-input dense float borderless v-model="ext_field_7" /></td>
        </tr>
      </tbody>
    </table>
    <div class="row" style="width: 100%; margin: 0px auto; border-bottom: 0.6mm black solid;">
      <q-input dense float borderless v-model="naz_pl_full" label="Назначение платежа" />
    </div>
    <div class="row">
      {{ nds_displayed }}
    </div>
    <div class="row" style="width: 100%;">
      <div class="col"><q-select v-model="with_nds" :options="nds_percentage_options" label="Ставка НДС"
          @update:model-value="(value) => onNDSChange(value)" /></div>
      <div class="col"> <q-input dense float borderless v-model="bank_comment" label="Отметки банка" /></div>
    </div>
    <div class="row">
      <div class="col">
        <q-toggle v-model="maked" label="Исполнено" />
      </div>
    </div>
  </div>
  <div style="margin-top: 10mm;">
    <q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { convert } from "number-to-words-ru";


const nds_percentage_options = [
  {
    label: 'НДС 20%',
    value: 0.2,
  },
  {
    label: 'НДС 10%',
    value: 0.1,
  },
  {
    label: 'НДС 0%',
    value: 0,
  },
  {
    label: 'Без НДС',
    value: 0,
  }
];


export default {
  name: "create_document_payment",
  data() {
    this.emitter.on('didCreateNewDocument', (data) => {
      if (data.error !== undefined) {
        if (data.error === 'access_denied') {
          this.$q.notify('Недостаточно прав');
          return;
        }
      }
      this.$q.loading.hide();
      if (data.error) {
        this.$q.dialog({
          title: 'Ошибка',
          message: 'Документ не был создан',
          cancel: false,
          persistent: true
        }).onOk(() => {
        }).onCancel(() => {
          console.log('cancel')
        }).onDismiss(() => {
          console.log('dismiss')
        })
      } else {
        this.$q.notify('Документ был создан');
        this.$router.push({ name: 'payments_list', params: {} });
      }
    })
    this.emitter.on('didReceiveNewDocumentNumber', (data) => {
      this.in_org_number = data.number;
      this.step = 2;
    });
    this.emitter.on('didReceiveAgentInfoByBik', (data) => {
      if (data.request_id === 'rec_bik') {
        this.rec_bank = data.info.name.payment;
        this.rec_bill_num = data.info.correspondent_account;
        this.rec_bank_city = data.info.payment_city;
      }
      if (data.request_id === 'plat_bik') {
        this.plat_bank = data.info.name.payment;
        this.plat_bill_num_ext = data.info.correspondent_account;
        this.plat_bank_city = data.info.payment_city;
      }
    })
    this.emitter.on('didReceiveAgentInfoByInn', (data) => {
      console.log(data, 'agent')
      if (data.request_id === 'rec_inn') {
        this.rec = data.info.name.short_with_opf;
        this.rec_kpp = data.info.kpp;
      }
      if (data.request_id === 'plat_inn') {
        this.plat = data.info.name.short_with_opf;
        this.plat_kpp = data.info.kpp;
      }
    })
    if (this.connection.isAuthenticated) {
      this.connection.getNewDocumentNumber(
        '1',
        'payment'
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getNewDocumentNumber(
          '1',
          'payment'
        );
      })
    }
    return {
      step: ref(1),
      document_type: ref(null),
      in_org_number: ref(null),
      bank_comment: ref(null),
      naz_pl_full: ref(null),
      plat_bank_city: ref(null),
      rec_bank_city: ref(null),
      ext_field_1: ref(null),
      ext_field_2: ref(null),
      ext_field_3: ref(null),
      ext_field_4: ref(null),
      ext_field_5: ref(null),
      ext_field_6: ref(null),
      ext_field_7: ref(null),
      maked: ref(false),
      rez_field: ref(null),
      naz_pl_ext: ref(null),
      ts_plat: ref(null),
      code: ref(null),
      naz_pl: ref(null),
      vid_op: ref(null),
      rec: ref(null),
      rec_kpp: ref(null),
      rec_inn: ref(null),
      rec_bill_num_ext: ref(null),
      rec_bill_num: ref(null),
      rec_bik: ref(null),
      rec_bank: ref(null),
      plat_bank: ref(null),
      plat_bill_num_ext: ref(null),
      plat_bill_num: ref(null),
      plat_bik: ref(null),
      plat: ref(null),
      summ: ref(0),
      plat_kpp: ref(null),
      plat_inn: ref(null),
      summ_letter: ref(null),
      plat_kind: ref(null),
      document_date: ref(null),
      spis_sch_plat: ref(null),
      postup_bank_plat: ref(null),
      with_nds: ref({ value: 0, label: 'Без НДС' }),
      nds_displayed: ref('Без НДС'),
      nds_summ: ref(0),
      nds_percentage_options: ref(nds_percentage_options),
      document_date_unix: ref(0)
    }
  },
  methods: {
    onDateChanged(newValue) {
      this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
    },
    checkAgentBy(bik_val, field_id) {
      const bik = bik_val.replace(/\s/g, "");
      console.log(bik, field_id)
      if (bik.length < 9) {
        return;
      }
      this.connection.getAgentInfoByBik(
        field_id,
        bik
      );
    },
    checkAgentByInn(inn_val, field_id) {
      const inn = inn_val.replace(/\s/g, "");
      console.log(inn, field_id)
      if (inn.length < 9) {
        return;
      }
      this.connection.getAgentInfoByInn(
        field_id,
        inn
      );
    },
    onUpdateSummLetter(value) {
      this.onSummChange(value);
      this.summ_letter = convert(value, {
        currency: 'rub',
        declension: 'nominative',
        roundNumber: -1,
        convertMinusSignToWord: true,
        showNumberParts: {
          integer: true,
          fractional: true,
        },
        convertNumberToWords: {
          integer: true,
          fractional: false,
        },
        showCurrency: {
          integer: true,
          fractional: true,
        },
      });
    },
    onNDSChange(value) {
      if (value.value == 0) {
        this.nds_summ = 0;
        this.nds_displayed = 'Без НДС';
      } else {
        this.nds_summ = parseFloat(this.summ) * value.value;
        if (value.value == 0.1) {
          this.nds_displayed = 'В т.ч. НДС (10%) ' + this.nds_summ.toFixed(2).toString().replace('.', '-');
        }
        if (value.value == 0.2) {
          this.nds_displayed = 'В т.ч. НДС (20%) ' + this.nds_summ.toFixed(2).toString().replace('.', '-');
        }
      }
    },
    onSummChange(value) {
      if (this.with_nds.value == 0) {
        this.nds_summ = 0;
        this.nds_displayed = 'Без НДС';
      } else {
        this.nds_summ = parseFloat(value) * this.with_nds.value;
        if (this.with_nds.value == 0.1) {
          this.nds_displayed = 'В т.ч. НДС(10%) ' + this.nds_summ.toFixed(2).toString().replace('.', '-');
        }
        if (this.with_nds.value == 0.2) {
          this.nds_displayed = 'В т.ч. НДС(20%) ' + this.nds_summ.toFixed(2).toString().replace('.', '-');
        }
      }
    },
    onSave() {
      const body = {
        bank_comment: this.bank_comment,
        naz_pl_full: this.naz_pl_full,
        ext_field_1: this.ext_field_1,
        ext_field_2: this.ext_field_2,
        ext_field_3: this.ext_field_3,
        ext_field_4: this.ext_field_4,
        ext_field_5: this.ext_field_5,
        ext_field_6: this.ext_field_6,
        ext_field_7: this.ext_field_7,
        plat_bank_city: this.plat_bank_city,
        rec_bank_city: this.rec_bank_city,
        maked: this.maked,
        rez_field: this.rez_field,
        naz_pl_ext: this.naz_pl_ext,
        ts_plat: this.ts_plat,
        code: this.code,
        naz_pl: this.naz_pl,
        vid_op: this.vid_op,
        rec: this.rec,
        rec_kpp: this.rec_kpp,
        rec_inn: this.rec_inn,
        rec_bill_num_ext: this.rec_bill_num_ext,
        rec_bill_num: this.rec_bill_num,
        rec_bik: this.rec_bik,
        rec_bank: this.rec_bank,
        plat_bank: this.plat_bank,
        plat_bill_num_ext: this.plat_bill_num_ext,
        plat_bill_num: this.plat_bill_num,
        plat_bik: this.plat_bik,
        plat: this.plat,
        summ: this.summ,
        plat_kpp: this.plat_kpp,
        plat_inn: this.plat_inn,
        summ_letter: this.summ_letter,
        plat_kind: this.plat_kind,
        document_date: this.document_date_unix,
        spis_sch_plat: this.spis_sch_plat,
        postup_bank_plat: this.postup_bank_plat,
        with_nds: this.with_nds,
        nds_displayed: this.nds_displayed,
        nds_summ: this.nds_summ,
        in_org_number: this.in_org_number,
        document_type: 'payment'
      };
      console.log(body);
      this.$q.loading.show();
      this.connection.createNewDocument(
        '1',
        body
      );

    }

  }
}
</script>

<style scoped>
@page {
  size: A4;
  margin: 0;
}

.container {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  background: white;
  color: black;
  width: 210mm;
  height: 297mm;
  padding: 20mm;
  box-sizing: border-box;
  display: block;
  margin: auto;
  position: relative;
}

.header-section {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5mm;
}

.title {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5mm;
}

.sub-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5mm;
}

.top-right {
  position: absolute;
  top: 15mm;
  right: 15mm;
  text-align: right;
  font-size: 12px;
}

.checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  vertical-align: middle;
  margin-left: 5px;
}

.rectangle {
  border: 1px solid black;
  padding: 2px 5px;
  margin-top: 5px;
  display: inline-block;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 10px;
}

th,
td {
  border: 1px solid black;
  padding: 5px;
  text-align: left;
  vertical-align: top;
}

.signature-area {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.signature-box {
  border-top: 1px solid black;
  padding-top: 3px;
  text-align: center;
  width: 45%;
}

.footer {
  margin-top: 20px;
  font-size: 12px;
}

.footer-row {
  display: flex;
  justify-content: space-between;
}

.footer-cell {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
  flex: 1;
}

.footer-cell:not(:last-child) {
  margin-right: 5px;
}

.footer-signatures {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.footer-signature-box {
  width: 45%;
  border-top: 1px solid black;
  text-align: center;
  padding-top: 3px;
}

.no-right-border {
  border-right: transparent !important;
}

.no-left-border {
  border-left: transparent !important;
}
</style>