<template>
	<div>
		<q-breadcrumbs>
			<q-breadcrumbs-el label="Главная" @click="$router.push({ name: 'home', params: {} })" />
			<q-breadcrumbs-el :label="page_title" />
		</q-breadcrumbs>
	</div>
	<div class="q-ma-md text-h5">
		{{ page_title }}
	</div>
	<div class="row">
		<div class="col">
			<q-card flat class="user-card">
				<q-card-section>
					<div class="row items-center q-col-gutter-md">
						<div class="col-12 col-sm-auto">
							<q-avatar size="128px" class="cursor-pointer" color="primary" text-color="white"
								@click="triggerAvatarUpload">
								<img v-if="user.avatar !== null" :src="user.avatar">
								<span v-else>{{ user.email.substring(0, 2).toUpperCase() }}</span>
								<q-file v-model="newAvatar" accept="image/*" style="display: none"
									@update:model-value="onAvatarSelected" ref="avatarInput" />
								<!-- <q-btn round color="primary" size="sm" icon="edit" class="absolute-bottom-right"
										@click.stop="triggerAvatarUpload" /> -->
							</q-avatar>
						</div>
						<div class="col">
							<div class="text-h6">{{ fullName }}</div>
							<div class="text-subtitle2">{{ user.email }}</div>
						</div>

					</div>
				</q-card-section>

				<q-card-section>
					<q-list>
						<!-- <q-item>
							<q-item-section>
								<q-item-label overline>Никнейм</q-item-label>
								<q-input v-model="editedUser.username" dense :readonly="!editMode" />
							</q-item-section>
						</q-item> -->
						<q-item>
							<q-item-section>
								<q-item-label overline>Фамилия</q-item-label>
								<div v-if="editMode">
									<q-input v-model="editedUser.surname" dense />
								</div>
								<div v-else>
									<q-item-label>{{ editedUser.surname }}</q-item-label>
								</div>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label overline>Имя</q-item-label>
								<div v-if="editMode">
									<q-input v-model="editedUser.name" dense />
								</div>
								<div v-else>
									<q-item-label>{{ editedUser.name }}</q-item-label>
								</div>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label overline>Отчество</q-item-label>
								<div v-if="editMode">
									<q-input v-model="editedUser.familyName" dense />
								</div>
								<div v-else>
									<q-item-label>{{ editedUser.familyName }}</q-item-label>
								</div>
							</q-item-section>
						</q-item>
						<!-- <q-item>
							<q-item-section>
								<q-item-label overline>Email</q-item-label>
								<q-input v-model="editedUser.email" dense :readonly="!editMode" type="email" />
							</q-item-section>
						</q-item> -->
					</q-list>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn flat icon="shield" color="primary" label="Изменить пароль"
						@click="changePasswordDialog = true" />
					<q-btn :icon="editMode ? 'save' : 'edit'" :label="editMode ? 'Сохранить' : 'Редактировать'"
						color="primary" @click="toggleEditMode" />
				</q-card-actions>

				<q-dialog v-model="changePasswordDialog">
					<q-card style="min-width: 350px">
						<q-card-section>
							<div class="text-h6">Изменить пароль</div>
						</q-card-section>

						<q-card-section class="q-pt-none">
							<q-input v-model="currentPassword" type="password" label="Текущий пароль" dense />
							<q-input v-model="newPassword" type="password" label="Новый пароль" dense class="q-mt-md" />
							<q-input v-model="confirmPassword" type="password" label="Подтвердите новый пароль" dense
								class="q-mt-md" />
						</q-card-section>

						<q-card-actions align="right" class="text-primary">
							<q-btn flat label="Отмена" v-close-popup />
							<q-btn flat label="Сменить пароль" @click="changePassword" />
						</q-card-actions>
					</q-card>
				</q-dialog>
			</q-card>
		</div>

		<div class="col q-pa-sm">
			<div v-if="!is_confirmed">
				<div class="text-h6">
					Подтверждение регистрации
				</div>
				<div class="text-grey text-justify">
					Ваш профиль не подтвержден. Для того, что бы принимать приглашения или создать организацию
					необходимо подтвердить адрес электронной почты. Код подтверждения был отправлен на указанный
					при регистрации адрес электронной почты.
				</div>
				<div>
					<div class="q-ma-md">
						<q-input mask="# # # # # #" :loading="code_request_sended" v-model="confirmation_code"
							label="Код подтверждения" style="width: 180px;" />
					</div>
					<div class="q-pa-sm">
						<q-btn text-color="white" color="primary" @click="sendConfirmationCode" label="Подвтердить" />
						<q-btn text-color="primary" flat color="white" @click="resendConfirmationCode"
							label="Повторно отправить код" />
					</div>
					<div class="q-ma-sm">
						<q-separator />
					</div>
				</div>
			</div>
			<div v-else>
				<div class="text-h6">
					Организации
				</div>
				<div>
					<q-list>
						<q-item v-for="item in organisations" v-bind:key="item.id" clickable v-ripple>
							<q-item-section avatar>
								<q-avatar color="primary" text-color="white">
									{{ item.name.charAt(0) }}
								</q-avatar>
							</q-item-section>
							<q-item-section>{{ item.display_name }}</q-item-section>
							<q-item-section>
								<div>
									{{ user_info.role.role }}
								</div>
								<div v-if="item.admin.id == user_info.id">
									Администратор
								</div>
							</q-item-section>
							<q-item-section>
								<div v-if="isCurrentOrg(item.id)">
									Выбрано сейчас
								</div>
								<div v-else>
									<q-btn dense flat @click="selectOrg(item.id)" label="Переключиться" />
								</div>
								<div>
									<q-btn dense flat @click="leaveOrg(item.id)" label="Покинуть" />
								</div>
							</q-item-section>
						</q-item>
					</q-list>
					<div class="q-ma-sm">
						<q-separator />
					</div>
				</div>
				<div class="q-ma-lg">
					<div v-show="organisations.length === 0" class="q-pa-md">
						Вы пока не состоите ни в одной организации. Вы можете дождаться приглашения, либо создать
						новую организацию.
					</div>
					<div v-show="organisations.length === 0" class="q-pa-md">
						<q-btn flat @click="$router.push({ name: 'org_new', params: {} })"
							label="Создать организацию" />
					</div>

					<div v-show="organisations.length > 0" class="q-pa-md">
						<q-btn icon="add" flat @click="$router.push({ name: 'org_new', params: {} })"
							label="Добавить организацию" />
					</div>
				</div>
				<div v-show="organisations.length === 0">
					<div class="text-h6" style="margin-top: 64px">
						Входящие приглашения
					</div>
					<div v-if="incoming_invites.length > 0" class="q-ma-lg">
						<q-list>
							<q-item v-for="item in incoming_invites" v-bind:key="item.id" clickable v-ripple>
								<q-item-section avatar>
									<q-avatar color="primary" text-color="white">
										{{ item.organisation.name.charAt(0) }}
									</q-avatar>
								</q-item-section>
								<q-item-section>
									<q-item-label>{{ item.organisation.display_name }}</q-item-label>
									<q-item-label caption>Роль: {{ item.role.role }}</q-item-label>
									<q-item-label caption>Пригласил: {{ item.invited_user.email }}</q-item-label>
								</q-item-section>
								<q-item-section>
									<q-btn icon="add" text-color="white" color="primary" @click="acceptInvite(item.id)"
										label="Принять" />
								</q-item-section>
							</q-item>
						</q-list>
						<div class="q-ma-sm">
							<q-separator />
						</div>
					</div>
					<div v-else class="q-ma-lg">
						<div class="q-pa-sm">
							Вас пока не пригласили ни в одну организацию. Свяжитесь с реководством вашей организации
							для получения приглашения.
						</div>
						<div class="q-ma-sm">
							<q-separator />
						</div>
					</div>
				</div>

				<div class="text-h6" style="margin-top: 64px">
					Исходящие приглашения
				</div>
				<div v-if="outgoing_invites.length > 0" class="q-ma-lg">
					<q-list>
						<q-item v-for="item in outgoing_invites" v-bind:key="item.id" clickable v-ripple>
							<q-item-section avatar>
								<q-avatar color="primary" text-color="white">
									{{ item.organisation.name.charAt(0) }}
								</q-avatar>
							</q-item-section>
							<q-item-section>
								<q-item-label>{{ item.organisation.display_name }}</q-item-label>
								<q-item-label caption>Роль: {{ item.role.role }}</q-item-label>
								<q-item-label caption>Пользователь: {{ item.email }}</q-item-label>
							</q-item-section>
							<q-item-section>
								<q-btn text-color="white" color="primary" @click="declineInvite(item.id)"
									label="Отозвать" />
							</q-item-section>
						</q-item>
					</q-list>
					<div class="q-ma-sm">
						<q-separator />
					</div>
				</div>
				<div v-else class="q-ma-lg">
					<div class="q-pa-sm">
						Вы пока не пригласили никого в организацию.
					</div>
					<div class="q-ma-sm">
						<q-separator />
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
	name: "my_org",
	data() {
		this.emitter.on('didAcceptInvite', (data) => {
			this.connection.updateOrgId(this.$q, data.org_id)
			this.connection.getOrganisationInfo('1');
			this.connection.getMyInvitesList('1');
			this.$q.loading.hide();
			this.$router.push({ name: 'home', params: {} });
		});
		// this.emitter.on('*', (type, e) => console.log(type, e))
		this.emitter.on('didReceiveLeaveOrganisation', (data) => {
			console.log(data);
			this.connection.getUserInfo();
		});
		this.emitter.on('onGetMyInvitesList', (data) => {
			console.log(data);
			this.incoming_invites = data.incoming_invites;
			this.outgoing_invites = data.outgoing_invites;
		});
		this.emitter.on("didReceiveUserInfo", (data) => {
			this.user_info = data;
			this.organisations = data.organisations;
			this.last_name = data.last_name;
			this.first_name = data.first_name;
			this.surname = data.surname;
			this.is_confirmed = data.is_confirmed;
			this.user_info_loaded = true;
			this.email = data.email;
			this.user.username = data.email.split('@')[0]
			this.user.name = data.first_name;
			this.user.surname = data.last_name;
			this.user.familyName = data.surname;
			this.user.email = data.email
			this.editedUser = { ...this.user }
		});
		this.emitter.on('onResendConfirmationCode', (data) => {
			console.log("resend", data);
			setTimeout(() => {
				this.code_request_sended = false;
			}, 1000);
		});
		this.emitter.on('didEditUserInfo', (data) => {
			console.log(data);
			setTimeout(() => {
				this.isRequestSended = false
			}, 1000);
			// this.isRequestSended = false;
		});
		this.emitter.on('didConfirmUser', (data) => {
			setTimeout(() => {
				this.code_request_sended = false;
			}, 1000)
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Неверный код подтверждения',
					cancel: false,
					persistent: true
				}).onOk(() => {
					this.confirmation_code = null;
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				console.log(data);
				this.confirmation_code = null;
				this.is_confirmed = true;
				this.$q.notify('Аккаунт подтвержден')
			}
		});

		if (this.connection.isAuthenticated) {
			this.connection.getUserInfo()
			this.connection.getMyInvitesList('1');
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getUserInfo();
				this.connection.getMyInvitesList('1');
			})
		}
		return {
			isRequestSended: ref(false),
			last_name: ref(''),
			first_name: ref(''),
			surname: ref(''),
			organisations: ref([]),
			incoming_invites: ref([]),
			outgoing_invites: ref([]),
			user_info: ref(null),
			confirmation_code: ref(null),
			user_info_loaded: ref(true),
			is_confirmed: ref(true),
			code_request_sended: ref(false),
			selected_avatar: ref(null),
			email: ref(''),
			user: ref({
				username: 'username',
				name: '',
				surname: '',
				familyName: '',
				email: '',
				avatar: null//'/img/logo_transp.c70cde75.png'//'https://cdn.quasar.dev/img/boy-avatar.png'
			}),
			fullName: computed(() => {
				return `${this.user.name || this.user.username || ''} ${this.user.surname || ''} ${this.user.familyName || ''}`.trim()
			}),
			editedUser: ref({ ...this.user }),
			editMode: ref(false),
			changePasswordDialog: ref(false),
			currentPassword: ref(''),
			newPassword: ref(''),
			confirmPassword: ref(''),
			newAvatar: ref(null),
			avatarInput: ref(null),
			page_title: ref('Мой профиль')
		}
	},
	mounted() {

	},
	methods: {
		changePassword() {
			if (this.newPassword !== this.confirmPassword) {
				this.$q.notify({
					color: 'negative',
					message: 'Пароли не совпадают',
					icon: 'warning'
				})
				return
			}

			// Here you would typically call an API to change the password

			this.$q.notify({
				color: 'positive',
				message: 'Password changed successfully',
				icon: 'check'
			})

			this.changePasswordDialog = false
			this.currentPassword = ''
			this.newPassword = ''
			this.confirmPassword = ''
		},
		toggleEditMode() {
			if (this.editMode) {
				// Save changes
				Object.assign(this.user, this.editedUser)
				this.changeUser();
				this.$q.notify({
					color: 'positive',
					message: 'Изменения сохранены',
					icon: 'check'
				})
			} else {
				// Enter edit mode
				this.editedUser = { ...this.user }
			}
			this.editMode = !this.editMode
		},
		onAvatarSelected(file) {
			if (file) {
				const reader = new FileReader()
				reader.onload = (e) => {
					this.user.avatar = e.target.result
					this.$q.notify({
						color: 'positive',
						message: 'Изображение было загружено',
						icon: 'check'
					})
				}
				reader.readAsDataURL(file)
			}
		},
		triggerAvatarUpload() {
			this.avatarInput.pickFiles()
		},
		changeUser() {
			this.isRequestSended = true
			const data = {
				last_name: this.user.surname,
				first_name: this.user.name,
				surname: this.user.familyName
			};
			this.connection.editUserInfo(data);
		},
		sendConfirmationCode() {
			// this.$q.loading.show();
			this.connection.confirmUser('1', parseInt(this.confirmation_code.replace(/\s/g, '')));
			this.code_request_sended = true;
		},
		resendConfirmationCode() {
			this.connection.resendConfirmationCode('1');
			this.code_request_sended = true;
		},
		acceptInvite(invite_id) {
			this.$q.loading.show();
			this.connection.acceptInvite('1', invite_id);
		},
		declineInvite(invite_id) {
			this.connection.declineInvite('1', invite_id);
		},
		isCurrentOrg(org_id) {
			return this.connection.selectedOrgId(this.$q) == org_id;
		},
		selectOrg(org_id) {
			this.connection.updateOrgId(this.$q, org_id)
			this.connection.getOrganisationInfo('1');
			this.connection.getUserInfoAcrossOrg('1');
			// this.$router.push({ name: 'home', params: {} });
		},
		leaveOrg(org_id) {
			if (this.isCurrentOrg(org_id)) {
				const new_org_list = this.organisations.map((x) => {
					return x.id;
				}).filter((x) => x.id != org_id);
				if (new_org_list.length > 0) {
					const new_org_id = new_org_list[0];
					this.connection.updateOrgId(this.$q, new_org_id)
					this.connection.getOrganisationInfo('1');
					this.connection.getUserInfoAcrossOrg('1');
				} else {
					const new_org_id = -1;
					this.connection.updateOrgId(this.$q, new_org_id)
				}
			}
			this.connection.leaveOrganisation('1', org_id);
		}
	}
}
</script>

<style scoped>
.user-card {
	max-width: 500px;
	margin: 0 auto;
}
</style>